import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';

import { FormControl } from '@angular/forms';
import { PdfMakeWrapper } from 'pdfmake-wrapper/lib/pdfmake-wrapper';
import {
  Canvas,
  Cell,
  Columns,
  Ellipse,
  Img,
  Line,
  SVG,
  Stack,
  Table,
  Txt,
} from 'pdfmake-wrapper';
import { checkEmptyPipe } from '../pipes/checkEmpty.pipe';
import { VesselStatusTypeDatePipe } from '../pipes/vessel-status-type-date.pipe';
import { urlImgPipe } from '../pipes/imaganes.pipe';
import { DomSanitizer } from '@angular/platform-browser';
import { formatDate } from '@angular/common';
import { Observable } from 'rxjs';
import { checkFixedPipe } from '../pipes/checkFixed.pipe';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { PDFDocument } from 'pdf-lib';
import { VesselStatusViewDatePipe } from '../pipes/vessel-status-view-date.pipe';

import pdfFonts from '../../assets/pdf/fonts/custon-file.js'; // custom fonts
import { UsuarioService } from './usuario.service';

@Injectable({
  providedIn: 'root',
})

export class GlobalesService {

  fixADM =2;
  fix = 3;
  fixCal=3;


  // localhost|
  // correoContacto = 'info@mydaspot.com';
  //dominioApi = 'https://localhost:44310/api';
  // dominioContainer = 'https://container.mydaspot.com';

  //deve
  // correoContacto = 'info@mydaspot.com';
  // dominioContainer = 'https://container.mydaspot.com';
  // dominioApi = 'https://api-deve.mydaspot.com/api';
 
  // produccion
  correoContacto = 'info@mydaspot.com';
  dominioApi = 'https://apioceanica.mydaspot.com/api';
  // // dominioContainer = 'https://mydaspotContainer.oceanicaint.com';
  dominioContainer = 'https://container.mydaspot.com';

  listApi=[{name:'Production',value:'https://apioceanica.mydaspot.com/api'},{name:'Deve',value:'https://api-deve.mydaspot.com/api'},{name:'Localhost',value:'https://localhost:44310/api'}]

  superAdmin=false;


  //Status Invoices
  //0 Review
  //1 Draft
  //2 Await
  //3 Sent
  //4 Adjustment
  //5 file 
  //6 file Adjustmen

  carpetaAvatarLogos = '/avatarLogos/';
  carpetaFdaFile = '/invoiceFile/';
  carpetaSuplementaryFile = '/cuentaSuplementary/';

  patternEmail = '^[^@]+@[^@]+.[a-zA-Z]{2,}$';

  patternPassword =
    '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\\w\\s]).{8,}$';

  maskTelefono = '+0* (000) 000 00 00';

  formatosImagenes = ['.jpg', '.jpeg', '.png'];

  maxSizeLogo = 2097152;

  formatosFile = ['.pdf'];

  formatosFileAdjuntoPDA = ['.pdf','.doc','docx','.xlsx','.xls','.jpg','.jpeg','.png'];

  // formatosFile = ['.jpg', '.jpeg', '.png','.pdf'];

  maxSizeFile = 2097152;
  maxSizeFileAdjunto = 5097152;

  optTime = [
    {
      code:'AM'
    },
    {
      code:'PM'
    }
  ]

  infoLogOp = [
    {
      tipo: 1,
      nombre: 'create pda',
      link: 'da-port/vesselpda/create'
    },
    {
      tipo: 2,
      nombre: 'update pda',
      link: 'da-port/vesselpda/create'
    },
    {
      tipo: 3,
      nombre: 'delete pda'
    },
    {
      tipo: 4,
      nombre: 'appointment accepted',
      link: 'da-port/vesselpda/toAccept'
    },
    {
      tipo: 5,
      nombre: 'appointment rejeted',
      link: 'da-port/vesselpda/toAccept'
    },
    {
      tipo: 6,
      nombre: 'create appointment',
      link: 'da-port/vesselpda/toAccept'
    },
    {
      tipo: 7,
      nombre: 'send pda',
      link: 'da-port/vesselpda/create'
    },
    {
      tipo: 8,
      nombre: 'new coments',
      link: 'historyComentsPDA'
    },
    {
      tipo: 9,
      nombre: 'create voyage',
      link: 'da-port/voyage/create/'
    },
    {
      tipo: 10,
      nombre: 'update voyage',
      link: 'da-port/voyage/create/'
    },
    {
      tipo: 11,
      nombre: 'appointment canceled',
      link: 'da-port/vesselpda/toAccept'
    }
  ]

  tiposPago=[
    {
      name:'Advance',
      code:'adv'
    },
    {
      name:'Final Balance',
      code:'fnl'
    }
  ]

  relacionadosA=[
    {
      name:'PDA',
      code:'pda'
    },
    {
      name:'FDA',
      code:'fda'
    },
    {
      name:'Suplementary',
      code:'spl'
    }
  ]

  categoriasCuentas = [
    {
      nombreCategoria: 'AGENCY - REMUNERATION',
      codigoCategoria: ['A'],
      verCategoria: true,
      totalCategoria: 0,
    },
    {
      nombreCategoria: 'PILOTAGE - PILOTAJE',
      codigoCategoria: ['B'],
      verCategoria: true,
      totalCategoria: 0,
    },
    {
      nombreCategoria: 'BERTH - MUELLE',
      codigoCategoria: ['C'],
      verCategoria: true,
      totalCategoria: 0,
    },
    {
      nombreCategoria: 'OWNER EXPENSES',
      codigoCategoria: ['D'],
      verCategoria: false,
      totalCategoria: 0,
    },
    {
      nombreCategoria: 'OTHER EXPENSES',
      codigoCategoria: ['E'],
      verCategoria: false,
      totalCategoria: 0,
    },
  ];

  codeUniversal = [
    {
      nombre: 'Lenth over all',
      code: 'LOA',
      tipo: 'insert',
      icon:'fa-ruler'
    },
    {
      nombre: 'Gross tonnage',
      code: 'GRT',
      tipo: 'insert',
      icon:' fa-weight'
    },
    {
      nombre: 'Quantity',
      code: 'CARGO',
      tipo: 'insert',
      icon:'fa-ball-pile'
    },{
      nombre: 'Port day',
      code: 'DAYS',
      tipo: 'insert',
      icon:'fa-calendar-edit'
    },
    {
      nombre: 'Port stay',
      code: 'HOURS',
      tipo: 'insert',
      icon:'fa-clock'
    },
    {
      nombre: 'Habour Due',
      code: 'HARBOUR',
      tipo: 'harbour',
      icon:'fa-anchor'
    },
    {
      nombre: 'Dockage',
      code: 'DOCKAGE',
      tipo: 'dockage',
      icon:'fa-anchor'
    },
    {
      nombre: 'Range Tabulator (pilotage)',
      code: 'PILOTAGE',
      tipo: 'tabulador',
      formula: 'TABULATOR (TC) * QTTY ',
      urlArchivo: './assets/datosExcel/pilotaje.xlsx',
      opciones: ['Low', 'Medium', 'High'],
      qtty: true,
      nombreQtty: 'movements',
      extraInfo: true,
      icon:'fa-drafting-compass'
    },
    {
      nombre: 'Range Tabulator (boat)',
      code: 'BOAT',
      tipo: 'tabulador',
      formula: 'TABULATOR (TC) * QTTY ',
      urlArchivo: './assets/datosExcel/lancha.xlsx',
      opciones: ['Low', 'Medium', 'High'],
      qtty: true,
      nombreQtty: 'boats',
      extraInfo: true,
      icon:'fa-water'
    },
    {
      nombre: 'Range Tabulator (tugs)',
      code: 'TUGS',
      tipo: 'tabulador',
      urlArchivo: './assets/datosExcel/remolcador.xlsx',
      opciones: ['Low', 'Medium', 'High'],
      formula: 'TABULATOR (TC) * QTTY ',
      extraInfo: true,
      qtty: true,
      nombreQtty: 'tugs',
      icon:'fa-ship'
    },
    {
      nombre: 'Dolar',
      code: 'USD',
      tipo: 'currency',
      icon:'fa-usd-square'
      
    },
    {
      nombre: 'Euro',
      code: 'EUR',
      tipo: 'currency',
      icon:'fa-euro-sign'
    },
  ];

  opcionAdvance = [
    {
      nombre: 'Prior arrival',
      code: 'PA',
    },
    {
      nombre: 'Upon arrived',
      code: 'UA',
    },
    {
      nombre: 'Prior departure',
      code: 'PD',
    },
    {
      nombre: 'As per Agreement',
      code: 'AA',
    },
  ];

  //Appointment Type
  agencyType = [
    {
      codigo: 'fa',
      nombre: 'Full agents',
    },
    {
      codigo: 'pa',
      nombre: 'Protecting agents',
    },
    {
      codigo: 'cha',
      nombre: 'Charter agents',
    },
    {
      codigo: 'sa',
      nombre: 'Sub agents',
    },
  ];

  headerPDF = new Columns([
    new Txt('https://mydaspot.com.ve').alignment('right').fontSize(9).margin(10).color('#6c757d')
      .end,
  ]).end;


  // headerPDF = new Img('assets/img/headerFiles.png').build();



  vesselType = [
    {
      code: 'CS',
      name: 'Container Ships'
    },
    {
      code: 'BC',
      name: 'Bulk Carrier'
    },
    {
      code: 'TS',
      name: 'Tanker Ships'
    },
    {
      code: 'PS',
      name: 'Passenger Ships'
    },
    {
      code: 'NS',
      name: 'Naval Ships'
    },
    {
      code: 'OS',
      name: 'Offshore Ships'
    },
    {
      code: 'SPS',
      name: 'Special Purpose Ships'
    },
    {
      code:'LC',
      name:'Landing Craft'
    }
  ]

  statusVessel = [
    {
      code: '0',
      name: 'IN ROUTE',
      optVessel:['eta','etb','ets','etc','etd']
    },
    {
      code: '1',
      name: 'ANCHORAGE',
      optVessel:['ata','etb','ets','ats','etc','atc','etd','atd']
    },
    {
      code: '2',
      name: 'DRIFTING',
      optVessel:['eta','ata','etb','ets','etc','etd']
    },
    {
      code: '3',
      name: 'IN PORT',
      optVessel:['ata','atb','ets','ats','etc','atc','etd']
    },
    {
      code: '4',
      name: 'DISCHARGING',
      optVessel:['ata','atb','ets','ats','etc','etd']
    },
    {
      code: '5',
      name: 'LOADING',
      optVessel:['eta','ata','etb','atb','ets','ats','etc','etd']
    },
    {
      code: '6',
      name: 'DEPARTURED',
      optVessel:['ata','atb','ats','atc','atd']
    }
   
  ]

  calcPorcentaje(total,parcial){

    let p =((Number(parcial)*100)/Number(total));
    let p1 = ((Number(parcial)*100)/Number(total)).toFixed(3);



    return Number(p1)===0.00?this.redondear(p):Number(p1);

   }

  restarDias(fecha,dias){
    fecha.setDate(fecha.getDate() + dias);
    return new Date(fecha);
  }
  
  calcularHoras(desde,hasta){
    var diff = hasta.getTime() - desde.getTime();
    let times = diff/(3600000); 
    return times;
  }

  calcularDiferenciaTime(desde, hasta,lastEvent,sitio='otro') {



      // Calcular la diferencia en milisegundos entre las dos fechas
      var diff = hasta.getTime() - desde.getTime();

      // Convertir la diferencia de tiempo a horas, minutos y segundos
      var segundos = Math.floor(diff / 1000);
      var minutos = Math.floor(segundos / 60);
      var horas = Math.floor(minutos / 60);

      // Ajustar los minutos y segundos si exceden 60
      minutos = minutos % 60;
      segundos = segundos % 60;

      
      if(lastEvent && lastEvent.includes('Bad')){


      }

      // Retornar el resultado final
      return {
          horas: horas,
          minutos: minutos,
          segundos: segundos
      };
  }

  sumarDiferenciasTime(resultado1, resultado2) {
      // Sumar las horas, minutos y segundos de ambos resultados
      var totalHoras = resultado1.horas + resultado2.horas;
      var totalMinutos = resultado1.minutos + resultado2.minutos;
      var totalSegundos = resultado1.segundos + resultado2.segundos;

      // Ajustar los minutos y segundos si exceden 60
      totalMinutos += Math.floor(totalSegundos / 60);
      totalSegundos = totalSegundos % 60;
      totalHoras += Math.floor(totalMinutos / 60);
      totalMinutos = totalMinutos % 60;


      // Retornar el resultado final
      return {
          horas: totalHoras,
          minutos: totalMinutos,
          segundos: totalSegundos
      };
  }


  createDateTime(date,time){
    if(time){

      return new Date(`${formatDate(date,'MM/dd/yyyy','en-US')} ${formatDate(time,'HH:mm','en-US')}`);
    }else{
      return'';
    }
  }

  isEmptyOrNull(dato:any){

    return !dato || dato.toString().trim()==='';
  }

  sortBL(BLs){
    BLs.sort((a,b)=>{

      let abl = !isNaN(a.bl) ? Number(a.bl):a.bl;
      let bbl = !isNaN(b.bl) ? Number(b.bl):b.bl;

      return(abl > bbl) ? 1 : ((bbl > abl) ? -1 : 0)
    
    })
  }

  sortHold(holds){
    holds.sort((a,b)=>{

      let abl = !isNaN(a.hold) ? Number(a.hold):a.hold;
      let bbl = !isNaN(b.hold) ? Number(b.hold):b.hold;

      return(abl > bbl) ? 1 : ((bbl > abl) ? -1 : 0)
    
    })
  }
  

  //es menor? 
  firtLessDateTime(fechaA,fechaB){

    return fechaA <= fechaB

  }


  getPorcentage(total,porcentage,fix=2){
    total = Number(total);
    porcentage = Number(porcentage);

    return this.toFixedNumber(porcentage * total / 100,fix)

  }

  myRound(num, dec=this.fixCal) {
    let exp = Math.pow(10, dec); // 2 decimales por defecto
    return parseInt((num * exp).toString(), 10) / exp;
  }

  toFixedNumber(num, digits=2, base=null){
    const pow = Math.pow(base ?? 10, digits);
    return (Math.round(num*pow) / pow).toFixed(digits);
  }

  twoDecimal(num) {
    var with2Decimals = num.toString().match(/^-?\d*(?:\.\d{0,2})?/)[0]

    let b:any = Number(with2Decimals);

    if (b % 1 === 0)
    {
        b = b + ".00";
    }

    return b;
  }

  redondear(p){
    var DIG_SIG=1;
    var MIN_DIG=0;

    return (p-0).toFixed(parseInt((Math.max(MIN_DIG,DIG_SIG-Math.log(p)/Math.log(10)).toString())));
  }

//getValorFda(cuenta,currencyBase){

//     let resp = 0;

//     if(cuenta.currency!==currencyBase){

//        resp= this.myRound(Number(cuenta.valorFda) * Number(cuenta.roe||1))
//        cuenta.difference= this.myRound(Number(cuenta.valor)  - (Number(cuenta.valorFda) * Number(cuenta.roe||1)));


//     }else{

//       resp=Number(cuenta.valorFda);
//       cuenta.difference= this.myRound(Number(cuenta.valor)  - Number(cuenta.valorFda));
//     }


//     return resp;
//}

  validarEmails(control: FormControl):{ [s: string]: boolean } {
    // const promesa = new Promise((resolve, rejet) => {

      if(control.value){

        let invalid = false;
        let EmailArray= control.value.split(',');
        let patternEmail = '^[^@]+@[^@]+.[a-zA-Z]{2,}$';
        for (const iterator of EmailArray) {
          if (!RegExp(patternEmail).test(iterator.trim())) {
            
            invalid = true;
          }
        }

        
        if (invalid) {
          
         return {
            pattern: true,
          };
        } else {
         
          return null;
        }

      }
    //}
  // );
    
    // return promesa;
  }

  

  checkDiffAppoPda(pda: any, appo: any) {

    let errores ={
      isDif:false,
      item:[]
    }
   
    for (const key in appo) {
      if (Object.prototype.hasOwnProperty.call(appo, key)) {
        const xAppo = appo[key];
        
        if (pda.callData && pda.callData[key] && typeof (pda.callData[key]) === 'string') {
          if (pda.callData[key] != xAppo) {

            
            errores.isDif= true;
            errores.item.push(key);
          }
        }

      }

    }
    return errores;

  }

  footerPDF = (pagenumber: number, pagecount: number) => {
    return {
      fontSize: 9,
      alignment: 'center',
      color: '#6c757d',
      text: 'Page ' + pagenumber + ' of ' + pagecount,
    };
  };

  CombineDateHour(getDate, getTime) {
    // let date = new Date(getDate);
    // let dateTime = new Date(`${getDate} ${getTime}`);
    // return date.setHours(dateTime.getHours(), dateTime.getMinutes())
    return new Date(`${getDate} ${getTime}`);
  }

  checkPermisos(usuario, tipo, opciones) {
    if (usuario.administrador) {
      return true;
    }

    if (tipo === 'subMenu') {
      var index = usuario.menu.findIndex(
        (x) =>
          x.subMenus.find((y) => y.idSubMenu.includes(opciones)) != undefined
      );

      if (index !== -1) {
        return true;
      }
    }

    return false;
  }

  addPrimerComentarioPDA(cuentas: any[]) {
    cuentas.forEach((x) => {
      if ((x.comments === undefined || x.comments.length === 0)) {
        if (x.formulaCuenta && x.formulaCuenta !== '' && x.edit!==true) {
          let now = new Date();
          let newComent = {
            mensaje:
              x.formulaCuenta + (x.formulaPre ? ' = ' + x.formulaPre : ''),
            from: 'agency',
            fecha: formatDate(now, 'yyyy-MM-dd hh:mm:ssZZZ', 'en-US'),
            accion: 1,
          };

          x.comments = [];
          x.comments.push(newComent);
        } 
      }
    });
    return cuentas;
  }

  // sortVoyagesBoard(list,isAsc=true,tipo='ata'){
  //   return list.sort((a:any,b:any)=>{
  //     let salida = 1;
  
  //     let dateA:any =new Date (a.portHub.vesselStatus[tipo].date || a.eta);
  //     let dateB:any=new Date (b.portHub.vesselStatus[tipo].date || b.eta);

  //     if(dateA<dateB){
  //       salida= isAsc?-1:1;
  //     }else if(dateA>dateB){
  //       salida= salida= isAsc?1:-1;
  //     }else{

  //       let timeA='';
  //       let timeB ='';

  //       if(){

  //       }else{

  //       }

  //       if((a.portHub.vesselStatus[tipo] && a.portHub.vesselStatus[tipo].horaMilitar==="AM")){
  //         timeA= formatDate('01/01/2020 11:59:59','HH:mm:ss','en-US')
  //       }else{
  //         timeA= formatDate('01/01/2020 23:59:59','HH:mm:ss','en-US')
  //       }



  //       if(a.portHub.vesselStatus[tipo].horaMilitar !== b.portHub.vesselStatus[tipo].horaMilitar){
  //         if(!a.portHub.vesselStatus[tipo].horaMilitar){
  //           salida=isAsc?1:-1;
  //         }else{
  //           salida=isAsc?-1:1;
  //         }
  //       }else{

  //         if(!a.portHub.vesselStatus[tipo].horaMilitar){

  //           if(a.portHub.vesselStatus[tipo].time12.code==="AM"){
  //             salida=isAsc?-1:1;
  //           }else{
  //             salida=isAsc?1:-1;
  //           }
  //         }else{
  //           let timeA = formatDate(a.portHub.vesselStatus[tipo].time24,'HH:mm:ss','en-US');
  //           let timeB =formatDate(b.portHub.vesselStatus[tipo].time24,'HH:mm:ss','en-US');

  //           if(timeA<timeB){
  //             salida= isAsc?-1:1;
  //           }else if(timeA>timeB){
  //             salida= isAsc?1:-1;
  //           }else{
  //             salida= isAsc?1:-1;
  //           }

  //         }

  //       }
  //     }

  //     return salida;
  //   })
  // }

  sortVoyageWhithVesselStatus(onlyVoyageWhithVesselStatus,isAsc=true,tipo='ata'){
   return onlyVoyageWhithVesselStatus.sort((a:any,b:any)=>{

    
      let salida = 1;

      let dateA =new Date (a.portHub.vesselStatus[tipo].date);
      let dateB =new Date (b.portHub.vesselStatus[tipo].date);




      if(dateA<dateB){
          salida= isAsc?-1:1;
        }else if(dateA>dateB){
          salida= isAsc?1:-1;
        }else{
          

          if(a.portHub.vesselStatus[tipo].horaMilitar !== b.portHub.vesselStatus[tipo].horaMilitar){
            if(!a.portHub.vesselStatus[tipo].horaMilitar){
              salida=isAsc?1:-1;
            }else{
              salida=isAsc?-1:1;
            }
          }else{

            if(!a.portHub.vesselStatus[tipo].horaMilitar){

              
              if(a.portHub.vesselStatus[tipo].time12.code==="AM"){
                salida=isAsc?-1:1;
              }else{
                salida=isAsc?1:-1;
              }


            }else{
              let timeA = formatDate(a.portHub.vesselStatus[tipo].time24,'HH:mm:ss','en-US');
              let timeB =formatDate(b.portHub.vesselStatus[tipo].time24,'HH:mm:ss','en-US');

              if(timeA<timeB){
                salida= isAsc?-1:1;
              }else if(timeA>timeB){
                salida= isAsc?1:-1;
              }else{
                salida= isAsc?1:-1;
              }

            }

          }

        }

        return salida;
   })
  }

  sortVoyagewithoutVesselStatus(onlyVoyagewithoutVesselStatus,isAsc=true){
   return  onlyVoyagewithoutVesselStatus.sort((a:any,b:any)=>{
        let salida = 1;
  
        let dateA:any =new Date (a.eta);
        let dateB:any=new Date (b.eta);
  
        

        if(dateA<dateB){
            salida= isAsc?-1:1;
        }else if(dateA>dateB){
          salida= salida= isAsc?1:-1;
        }
  
          return salida;
   })
  }



  // sortVoyagewithoutVesselStatus(onlyVoyagewithoutVesselStatus,isAsc=true){
  //  return  onlyVoyagewithoutVesselStatus.sort((a:any,b:any)=>{
  //       let salida = 1;
  
  //       let dateA:any;
  //       let dateB:any;
  
  //       if(a.portHub.vesselStatus.ata?.date){

  //         dateA =new Date (a.portHub.vesselStatus.ata.date);
  //       }else{
  //         dateA =new Date (a.eta);
  //       }

  //       if(b.portHub.vesselStatus.ata?.date){
  //         dateB =new Date (b.portHub.vesselStatus.ata.date);
  //       }else{
  //         dateB =new Date (b.eta);
  //       }

  //       if(dateA<dateB){
  //           salida= isAsc?-1:1;
  //       }else if(dateA>dateB){
  //         salida= salida= isAsc?1:-1;
  //       }else{

  //         let codeA:any;
  //         let codeB:any;

  //         if(b.portHub.vesselStatus.ata.horaMilitar){
  //           codeB= formatDate(b.portHub.vesselStatus.ata.time24,"aaaa",'en-US');
  //         }else{
  //           codeB=b.portHub.vesselStatus.ata.time12.code;
  //         }

  //         if(a.portHub.vesselStatus.ata.horaMilitar){
  //           codeA= formatDate(a.portHub.vesselStatus.ata.time24,"aaaa",'en-US');
  //         }else{
  //           codeA=a.portHub.vesselStatus.ata.time12.code;
  //         }

  //         if(a.portHub.vesselStatus.ata.horaMilitar &&  b.portHub.vesselStatus.ata.horaMilitar){
  //             let timeA = formatDate(a.portHub.vesselStatus.ata.time24,'HH:mm:ss','en-US');
  //             let timeB = formatDate(b.portHub.vesselStatus.ata.time24,'HH:mm:ss','en-US');

  //             if(timeA<timeB){
  //               salida= isAsc?-1:1;
  //             }else if(timeA>timeB){
  //               salida= isAsc?1:-1;
  //             }else{
  //               salida= isAsc?1:-1;
  //             }

  //         }else if(b.portHub.vesselStatus.ata.horaMilitar !== a.portHub.vesselStatus.ata.horaMilitar){
            

  //           if(!a.portHub.vesselStatus.ata.horaMilitar){
  //             salida=1;
  //           }else{
  //              salida=isAsc?-1:1;
  //           }
  //         }
  //         else if(!b.portHub.vesselStatus.ata.horaMilitar &&  !a.portHub.vesselStatus.ata.horaMilitar){


  //           if(codeB==="PM" && codeA==="AM"){
  //             salida=isAsc?-1:1;
  //           }

  //           if(codeB==="AM" && codeA==="PM"){
  //             salida=isAsc?1:-1;
  //           }
  //         }

  //       }
  
  //         return salida;
  //  })
  // }

  formatDateMDY(value) {
    let a = formatDate(value, 'MMM d, y', 'en-US');
    return a;
  }

  getValorFda(cuenta,codigoCurrencyB,onlyFDA=false){

    let resp:any = 0;

   if(cuenta.currency!==codigoCurrencyB){

     
     if(cuenta.exchange==='*'){
       resp= this.toFixedNumber(Number(cuenta.valorFda||cuenta.valor) * Number(cuenta.roe||1),this.fixADM);
     }else{
       if(onlyFDA){
        resp= this.toFixedNumber(Number(cuenta.valorFda) / Number(cuenta.roe||1),this.fixADM);
       }else{
         resp= this.toFixedNumber(Number(cuenta.valorFda||cuenta.valor) / Number(cuenta.roe||1),this.fixADM);
       }
     }

   }else{
     resp=Number(cuenta.valorFda||cuenta.valor);

   }

   if(cuenta.valorFda!=''){
     cuenta.difference= this.toFixedNumber((Number(resp) - Number(cuenta.valor) ),this.fixADM);
   }

   return resp;
  }

  applyRoe(monto,roe,exchange){
    var resp =0;
    switch (exchange) {
      case '*':
        resp=Number((monto * roe).toFixed(2))
        break;
        case '/':
          resp=Number((monto / roe).toFixed(2))
      default:
        break;
    }

    return resp;
  }

  imgUrlToBase64 = (url: string) =>
    new Promise((resolve, reject) => {
      const img = new Image();
      img.crossOrigin = 'Anonymous';
      img.src = url;
      img.onload = () => {
        const canvas = document.createElement('canvas'),
          ctx = canvas.getContext('2d');
        canvas.height = img.height;
        canvas.width = img.width;
        ctx.drawImage(img, 0, 0);
        resolve(canvas.toDataURL());
      };
      img.onerror = (error) => reject(error);
  });

  filterAgency(item) {
    return item.grupoCuenta === 'A' && item.template !== false ? true : false;
  }

  filterPortCost(item) {
    return (item.grupoCuenta === 'B' || item.grupoCuenta === 'C') &&
      item.template !== false
      ? true
      : false;
  }

  getTextExchange(currencyBase,currencyActual,opt){

    
    if(opt==='/'){
      return `${currencyActual} - ${currencyBase}`
    }else{
      return `${currencyBase} - ${currencyActual}`
    }
  }

  filterOwner(item) {
    return item.grupoCuenta === 'D' && item.template !== false ? true : false;
  }

  filterOther(item) {
    return item.grupoCuenta === 'E' && item.template !== false ? true : false;
  }

  // cuentas
  // getComents(cuentas) {
  //   const res = [];
  //   for (const iterator of cuentas) {
  //     if (iterator.comments && iterator.comments.length > 0) {
  //       const a = [];
  //       a.push(new Txt(iterator.nombreCuenta).end);
  //       a.push(new Txt(iterator.comments[0].mensaje).alignment('right').end);
  //       const colum = new Table([a]).widths([100, '*']).layout('noBorders').end;
  //       res.push(colum);
  //     }
  //   }
  //   return res;
  // }

  // valor de las cuentas
  createRowBls(list) {
    let res = [];

    let header=[];
    header.push(new Txt('BL').bold().alignment('center').end);
    header.push(new Txt('Manifested').alignment('center').bold().end);
    header.push(new Txt('Type').bold().alignment('center').end);
    header.push(new Txt('Discharged').bold().alignment('center').end);
    header.push(new Txt('ROB').alignment('center').bold().end);
    header.push(new Txt('%').bold().alignment('left').end);

    const row = new Table([header]).widths(['16%','16%', '16%','16%','16%','16%']).layout({
      hLineColor:()=>'#efefef',
      vLineColor:()=>'#efefef',
      hLineWidth:()=>0.1,
      vLineWidth:()=>0.1,
    }).end;

    res.push(row);

    for (const iterator of list) {
      // let valor = iterator.valor ? iterator.valor : 0;
 
      const a = [];
      

     
      a.push(new Txt(`No. ${iterator.bl}`).end);
      a.push(new Txt(`${this.toFixedNumber(iterator.qty,3)}`).end);
      a.push(new Txt(`${iterator.cargo}`).end);
      a.push(new Txt(`${this.toFixedNumber(iterator.descargado,3)}`).end);
      a.push(new Txt(`${this.toFixedNumber(Number(iterator.descargado) - Number(iterator.qty),3)}`).end);
      a.push(new Txt(`Grafico`).end);
      
      
      const row = new Table([a]).widths(['16%','16%', '16%','16%','16%','16%']).layout({
        hLineColor:()=>'#efefef',
        vLineColor:()=>'#efefef',
        hLineWidth:()=>0.1,
        vLineWidth:()=>0.1,
      }).end;



      res.push(row);
    }

    return res;
  }

  createRowCuentas(cuentas) {
    const res = [];

    for (const iterator of cuentas) {
      // let valor = iterator.valor ? iterator.valor : 0;
      let valor = iterator.valorFinal;
      if (valor !== '' && valor !== undefined) {
        valor = Number(valor).toFixed(2);
      } else if (iterator.mensaje) {
        valor = iterator.mensaje;
      } else {
        valor = '0.00';
      }

      
      const a = [];
      a.push(new Txt(iterator.nombreCuenta).end);

      a.push(new Txt(iterator.comments?(iterator.comments.length>0? iterator.comments[iterator.comments.length-1].mensaje : ''):'').fontSize(7).end);
      a.push(new Cell(new Txt(valor).alignment('right').end).fillColor('#ebf8ff').end);
      
      const colum = new Table([a]).widths(['35%','50%', '15%']).layout({
        hLineColor:()=>'#efefef',
        vLineColor:()=>'#efefef',
        hLineWidth:()=>0.1,
        vLineWidth:()=>0.1,
      }).end;
      res.push(colum);
    }

    return res;
  }

  // cuentas
  // getComents(cuentas) {
  //   const res = [];
  //   for (const iterator of cuentas) {
  //     if (iterator.comments && iterator.comments.length > 0) {
  //       const a = [];
  //       a.push(new Txt(iterator.nombreCuenta).end);
  //       a.push(new Txt(iterator.comments[0].mensaje).alignment('right').end);
  //       const colum = new Table([a]).widths([100, '*']).layout('noBorders').end;
  //       res.push(colum);
  //     }
  //   }
  //   return res;
  // }

  // valor de las cuentas
  createRowCuentasFDA(currencyBase,cuentas, receiverSelect=null) {

    let respuesta={
      total:0,
      cuentas:[]
    }

    

    if(receiverSelect && receiverSelect.idLocal!==0){

      
      cuentas = cuentas.filter(x=>x.idCustomer && x.idCustomer === receiverSelect.idCustomer || !x.idCustomer);
    }

    for (const iterator of cuentas) {

      // iterator.valorFda= Number(iterator.valorFda).toFixed(this.fixADM)
      const a = [];
      a.push(new Txt(iterator.codigoCuenta).end);
      if(this.isEmptyOrNull(iterator.adjustment)){
        a.push(new Txt(iterator.nombreCuenta).end);

      }else{
        a.push(new Txt(iterator.nombreCuenta + ' *').bold().end);

      }

      let currency = '-'
      let roe ='-'
      if(iterator.currency!=='' && iterator.currency!==currencyBase){

        currency = this.getTextExchange(currencyBase,iterator.currency,iterator.exchange);
        roe=iterator.roe;

      }else if(iterator.split && iterator.split !=='' && iterator.split !=='[]'){
        
        currency='Multi Currencies';
        roe='See Comments'
      }
       
      a.push(new Txt(currency).alignment('center').end);
      a.push(new Txt(this.toFixedNumber(receiverSelect && !iterator.idCustomer? this.getPorcentage(iterator.valorFda,receiverSelect?.porcentaje || 100) :iterator.valorFda,this.fixADM)).alignment('right').end);
   

      a.push(new Txt(roe).alignment('right').end);

      // a.push(new Cell(new Txt(iterator.roe!==''?this.getValorFda(iterator,currencyBase):iterator.valorFda).alignment('right').end).fillColor('#ebf8ff').end);
      a.push(new Cell(new Txt(this.toFixedNumber(receiverSelect && !iterator.idCustomer? this.getPorcentage(iterator.valorConRoe,receiverSelect?.porcentaje || 100) :iterator.valorConRoe,this.fixADM)).alignment('right').end).fillColor('#ebf8ff').end);
      

      
      const colum = new Table([a]).widths(['5%','30%', '17%','18%','15%','15%']).layout({
        hLineColor:()=>'#efefef',
        vLineColor:()=>'#efefef',
        hLineWidth:()=>0.1,
        vLineWidth:()=>0.1,
      }).end;


      respuesta.cuentas.push(colum);

      if(!iterator.idCustomer){
        iterator.valorConRoe =  this.toFixedNumber(this.getPorcentage(iterator.valorConRoe,receiverSelect?.porcentaje || 100),2);
      }

      respuesta.total= respuesta.total + Number(iterator.valorConRoe);


    }

    return respuesta;
  }

  createRowCuentasSDA(currencyBase,cuentas, receiverSelect=null) {

    let respuesta={
      total:0,
      cuentas:[]
    }

    for (const iterator of cuentas) {

      // iterator.valorFda= Number(iterator.valorFda).toFixed(this.fixADM)
      const a = [];
      a.push(new Txt(iterator.codigoCuenta).end);

      if(this.isEmptyOrNull(iterator.adjustment)){
        a.push(new Txt(iterator.nombreCuenta).end);

      }else{
        a.push(new Txt(iterator.nombreCuenta + ' *').bold().end);

      }

      let currency = '-'
      let roe ='-'
      if(iterator.currency!=='' && iterator.currency!==currencyBase){

        currency = this.getTextExchange(currencyBase,iterator.currency,iterator.exchange);
        roe=iterator.roe;

      }else if(iterator.split && iterator.split !=='' && iterator.split !=='[]'){
        
        currency='Multi Currencies';
        roe='See Comments'
      }
       
      a.push(new Txt(currency).alignment('center').end);
      
      a.push(new Txt(currencyBase !== iterator.currency? this.toFixedNumber(this.fixADM,iterator.valor):'-').alignment('right').end);
   

      a.push(new Txt(roe).alignment('right').end);

      a.push(new Cell(new Txt(this.toFixedNumber(iterator.valorConRoe,this.fixADM)).alignment('right').end).fillColor('#ebf8ff').end);
      

      
      const colum = new Table([a]).widths(['5%','30%', '17%','18%','15%','15%']).layout({
        hLineColor:()=>'#efefef',
        vLineColor:()=>'#efefef',
        hLineWidth:()=>0.1,
        vLineWidth:()=>0.1,
      }).end;


      respuesta.cuentas.push(colum);
    
      iterator.valorConRoe =  this.toFixedNumber(iterator.valorConRoe,2);
      respuesta.total= respuesta.total + Number(iterator.valorConRoe);
    }
    return respuesta;
  }

  // cuentas
  // getComents(cuentas) {
  //   const res = [];
  //   for (const iterator of cuentas) {
  //     if (iterator.comments && iterator.comments.length > 0) {
  //       const a = [];
  //       a.push(new Txt(iterator.nombreCuenta).end);
  //       a.push(new Txt(iterator.comments[0].mensaje).alignment('right').end);
  //       const colum = new Table([a]).widths([100, '*']).layout('noBorders').end;
  //       res.push(colum);
  //     }
  //   }
  //   return res;
  // }

  // valor de las cuentas
  createRowReportBoard(items) {
    const res = [];

   

    for (const iterator of items.voyages) {

      
      const a = [];
      a.push(new Stack([new Txt(`Mv. ${iterator.vessel} `).end, new Txt( `Voy. ${iterator.voyage} | ${iterator.agentVoyage}`).end]).end);
      if(iterator.multiReceiver){

        let companys=iterator.receivers.map(x=>new Txt(`Mv. ${x.company} `).end);

        
        a.push(new Stack(companys).end)
      }else{
        a.push(new Txt(iterator.x_responsable).end);
      }
      
      a.push(new Stack([new Txt(`${iterator.nombrePuerto}`).end, new Txt( `${iterator.nombreZona}`).end]).end);
      
      const pipe = new VesselStatusViewDatePipe();
      let date= pipe.transform(iterator.eta,iterator.portHub.vesselStatus.ata?.date);
      let time = iterator.portHub.vesselStatus.ata? iterator.portHub.vesselStatus.ata.horaMilitar?formatDate(iterator.portHub.vesselStatus.ata.time24,'HH:mm','en-US'):iterator.portHub.vesselStatus.ata.time12:'';
     


      if(!iterator.portHub.vesselStatus.ata || iterator.portHub.vesselStatus.ata.tipo==='e'){
        a.push(new Stack([new Txt(`${formatDate(date,'MMM d, y','en-US')}`).end, new Txt( `${time} ETA`).end]).end);

      }else{
        a.push(new Stack([new Txt(`${formatDate(date,'MMM d, y','en-US')}`).bold().end, new Txt( `${time} ATA`).bold().end]).end);

      }

      a.push(new Stack([new Txt(`${this.toFixedNumber(iterator.CARGO,3)} mts  ${iterator.nombreActivity[0]==='D' || iterator.nombreActivity[0]==='L'?iterator.nombreActivity[0]:iterator.nombreActivity}`).end, new Txt( `${iterator.cargoType}`).end]).end);

      a.push(new Txt(this.toFixedNumber(iterator.dischargered,3)).end);
      a.push(new Txt(this.toFixedNumber(iterator.CARGO -  iterator.dischargered,3)).end);

      let dateAtd= pipe.transform(iterator.etd,iterator.portHub.vesselStatus.atd?.date);
      let timeAtd = iterator.portHub.vesselStatus.atd? iterator.portHub.vesselStatus.atd.horaMilitar?formatDate(iterator.portHub.vesselStatus.atd.time24,'HH:mm','en-US'):iterator.portHub.vesselStatus.atd.time12:'';
     
      if(!iterator.portHub.vesselStatus.atd || iterator.portHub.vesselStatus.atd.tipo==='e'){
        a.push(new Stack([new Txt(`${formatDate(dateAtd,'MMM d, y','en-US')}`).end, new Txt( `${timeAtd} ETD`).end]).end);

      }else{
        a.push(new Stack([new Txt(`${formatDate(dateAtd,'MMM d, y','en-US')}`).bold().end, new Txt( `${timeAtd} ATA`).bold().end]).end);

      }

      const colum = new Table([a]).widths(['18%','17%', '15%','10%','10%','10%','10%','10%']).layout({
        hLineColor:()=>'#efefef',
        vLineColor:()=>'#efefef',
        hLineWidth:()=>0.1,
        vLineWidth:()=>0.1,
      }).end;
      res.push(colum);
    }

    return res;
  }

  createRowReportFda(customer,items,totalGlobales) {
    const res = [];


    customer.totalCustomerUSD=0;
    customer.totalCustomerEUR=0;

    if(this.isEmptyOrNull(totalGlobales.cobrarUSD)){
      totalGlobales.cobrarEUR=0;
      totalGlobales.pagarEUR=0;
      
      totalGlobales.cobrarUSD=0;
      totalGlobales.pagarUSD=0;
    }

    
    for (const iterator of items) {


    let sumTotal=  0;
    let pagosIn=0;
    let pagosOut=0;

     if(iterator.receivers && iterator.receivers!=='[]'){

      // if(iterator.vessel==='UBC HAMBURG'){

      // }

      iterator.receivers=JSON.parse(iterator.receivers);


      const i = iterator.receivers.findIndex(x=>x.company===iterator.x_responsable);

      if(i!==-1){

        sumTotal= Number(iterator.receivers[i].totalDA);
  
        sumTotal = sumTotal + Number(iterator.receivers[i].agencyFees || 0 );
  
        sumTotal = sumTotal - Number(this.getPorcentage(iterator.discount,iterator.receivers[i].porcentaje))
  
        pagosIn= iterator.receivers[i].pagosIn;
        pagosOut= iterator.receivers[i].pagosOut;
      }

     }else{

      // if(iterator.vessel==='UBC HAMBURG'){

      // }

      sumTotal=Number(iterator.totalDA);
      pagosIn=iterator.pagosIn;
      pagosOut=iterator.pagosOut;

     }
      
     let balance = sumTotal-(pagosIn-pagosOut);

     if(balance!==0){

       const a = [];
       a.push(new Txt(iterator.x_responsable).end);
       a.push(new Txt(iterator.vessel).end);
       a.push(new Txt(iterator.agentVoyage).end);
       a.push(new Txt(iterator.atd!='-'? formatDate(iterator.atd,'MMM d, y','en-US') :iterator.atd).end);
 
 
       a.push(new Txt(`${iterator.type.toLowerCase().trim()==='is sda'?'SDA: ':'FDA: '} ${this.isEmptyOrNull(iterator.invoiceDate) ? 'No Register' :  this.toFixedNumber(sumTotal,2)} ${iterator.currency}` ).end);
       a.push(new Txt(`${this.toFixedNumber((pagosIn-pagosOut),2)} ${iterator.currency}`).end);
       a.push(new Txt(`${this.toFixedNumber(balance,2)} ${iterator.currency}`).end);
       a.push(new Txt(this.isEmptyOrNull(iterator.invoiceDate) ? 'No Register' :formatDate(iterator.invoiceDate,'MMM d, y','en-US') || '-').end);
       a.push(new Txt(this.isEmptyOrNull(iterator.overdueDate) ? 'No Register' :formatDate(iterator.overdueDate,'MMM d, y','en-US') || '-').end);
       a.push(new Txt( iterator.collectionDate !==null?formatDate(iterator.collectionDate,'MMM d, y','en-US'): 'No Register').end);
 
       switch (iterator.currency) {
         case 'USD':
           customer.totalCustomerUSD +=Number(balance);
 
           if(Number(balance)>0){
             totalGlobales.cobrarUSD+=Number(balance)
           }else{
             totalGlobales.pagarUSD+=Number(balance);
           }
 
           break;
         case 'EUR':
           totalGlobales.totalCustomerEUR +=Number(balance);
 
           if(Number(balance)>0){
             totalGlobales.cobrarEUR+=Number(balance)
           }else{
             totalGlobales.pagarEUR+=Number(balance);
           }
           break;
         
       }
 
       const colum = new Table([a]).widths(['10%','10%', '8%','10%','12%','10%','10%','10%','10%','10%']).layout({
         hLineColor:()=>'#efefef',
         vLineColor:()=>'#efefef',
         hLineWidth:()=>0.1,
         vLineWidth:()=>0.1,
       }).end;
       res.push(colum);
     }


    }

    return res;
  }

  createRowCuentasComments(cuentas,currencyBase,receiverSelect=null) {
    const res = [];


    if(receiverSelect && receiverSelect.idLocal!==0){

      
      cuentas = cuentas.filter(x=>x.idCustomer && x.idCustomer === receiverSelect.idCustomer || !x.idCustomer);
    }

    let hasItemAdjustment= false;
    
    
    for (const iterator of cuentas) {



      const a = [];
      a.push(new Txt(iterator.codigoCuenta).end);
      a.push(new Txt(iterator.nombreCuenta).end);

      
      let valorPDA = receiverSelect && iterator.idCustomer? this.getPorcentage(iterator.valorFinal,(receiverSelect.porcentaje||100)):Number(iterator.valorFinal);
      
      let valorFDA = iterator.valorConRoe;
      
      a.push(new Txt(this.toFixedNumber(valorPDA,this.fixADM)).alignment('center').end);

      a.push(new Cell(new Txt(this.toFixedNumber(valorFDA,this.fixADM)).alignment('center').end).end);

      let diff = this.toFixedNumber(Number(valorFDA) - Number(valorPDA),this.fixADM)
      a.push(new Txt(diff).alignment('center').end);
      
      
      // a.push(new Cell(new Txt(this.toFixedNumber(iterator.adjustment)).alignment('center').end).end);

      if(iterator.comments.length>0){
        a.push(new Txt(iterator.comments[iterator.comments.length - 1]?.mensaje).alignment('left').end);
      }else{
        a.push(new Txt('-').alignment('center').end);
      }

      let widths = ['5%','25%', '10%','10%','13%','37%']
      // if( !this.isEmptyOrNull(iterator.adjustment) || hasItemAdjustment){
      //   hasItemAdjustment=true;
      //   widths =['5%','25%', '8%','8%','8%','8%','38%']
      // }

      const colum = new Table([a]).widths(widths).layout({
        hLineColor:()=>'#efefef',
        vLineColor:()=>'#efefef',
        hLineWidth:()=>0.1,
        vLineWidth:()=>0.1,
      }).end;

      res.push(colum);
    }

    return res;
  }

  // calcCuentantaWhitRoe(valor,roe){
  //   return this.myRound(valor*roe,2).toFixed(this.fixADM);
  // }

  createFilePolicy(policy, empresa) {
    return new Promise(async (resolve, reject) => {
      const pdf = new PdfMakeWrapper();
      const emptyPipe = new checkEmptyPipe();

      pdf.defaultStyle({
        fontSize: 10,
      });

      pdf.header(this.headerPDF);

      pdf.footer(this.footerPDF);

      const logo = await new Img(
        `${this.dominioContainer + this.carpetaAvatarLogos + empresa.logoEmpresa
        }`
      )
        .fit([150, 50])
        .build();
      pdf.add(logo);

      pdf.add(pdf.ln(2));

      pdf.add(new Txt(empresa.nombreEmpresa).end);
      pdf.add(new Txt(empresa.direccionEmpresa).end);
      pdf.add(new Txt(empresa.ciudadEmpresa).end);
      pdf.add(new Txt(empresa.nombrePais).end);
      pdf.add(new Txt('Phone: ' + empresa.telefonoEmpresa).end);
      pdf.add(new Txt('Email: ' + empresa.correoEmpresa).end);

      pdf.add(pdf.ln(2));

      pdf.add(new Txt('Details policy').fontSize(20).bold().end);

      pdf.add(pdf.ln(2));

      pdf.add(new Txt(policy.nombrePolicy).bold().end);
      pdf.add(pdf.ln(1));

      for (const c of policy.clausulas) {
        pdf.add(new Txt(c.nombreClausula + ':').italics().end);
        pdf.add(pdf.ln(1));
        pdf.add(new Txt(c.descripcionClausula).end);
        pdf.add(pdf.ln(1));
      }

      pdf.create().getDataUrl((data) => {
        resolve(data);
      });
    });
  }

  createFileBanco(banco, empresa) {
    return new Promise(async (resolve, reject) => {
      const pdf = new PdfMakeWrapper();
      const emptyPipe = new checkEmptyPipe();

      pdf.defaultStyle({
        fontSize: 10,
      });

      pdf.header(this.headerPDF);

      pdf.footer(this.footerPDF);

      const logo = await new Img(
        `${this.dominioContainer + this.carpetaAvatarLogos + empresa.logoEmpresa
        }`
      )
        .fit([150, 50])
        .build();
      pdf.add(logo);

      pdf.add(pdf.ln(2));

      pdf.add(new Txt(empresa.nombreEmpresa).end);
      pdf.add(new Txt(empresa.direccionEmpresa).end);
      pdf.add(new Txt(empresa.ciudadEmpresa).end);
      pdf.add(new Txt(empresa.nombrePais).end);
      pdf.add(new Txt('Phone: ' + empresa.telefonoEmpresa).end);
      pdf.add(new Txt('Email: ' + empresa.correoEmpresa).end);

      pdf.add(pdf.ln(2));

      pdf.add(new Txt('Details account bank').fontSize(20).bold().end);

      pdf.add(pdf.ln(2));

      if (banco.codigoCurrencyB === 'BTC') {
        pdf.add(
          new Table([
            [
              new Txt('Account N°').end,
              `: ${emptyPipe.transform(banco.numeroCuentaBanco)}`,
            ],
            [
              new Txt('Email address').end,
              `: ${emptyPipe.transform(banco.correoBanco)}`,
            ],
          ])
            .widths([100, '*'])
            .layout('noBorders').end
        );
      } else {
        pdf.add(
          new Table([
            [
              new Txt('Bank name').end,
              `: ${emptyPipe.transform(banco.nombreBanco)}`,
            ],
            [
              new Txt('Account N°').end,
              `: ${emptyPipe.transform(banco.numeroCuentaBanco)}`,
            ],
            [
              new Txt('Beneficiary name').end,
              `: ${emptyPipe.transform(banco.beneficiarioBanco)}`,
            ],
            [
              new Txt('Taxid').end,
              `: ${emptyPipe.transform(banco.taxIdBanco)}`,
            ],
            [
              new Txt('Email address').end,
              `: ${emptyPipe.transform(banco.correoBanco)}`,
            ],
          ])
            .widths([100, '*'])
            .layout('noBorders').end
        );
        if (['USD', 'EUR'].includes(banco.codigoCurrencyB)) {
          pdf.add(
            new Table([
              [
                new Txt('BIC / SWIFT code').end,
                `: ${emptyPipe.transform(banco.bicBanco)}`,
              ],
              [
                new Txt('IBAN / ABA routing').end,
                `: ${emptyPipe.transform(banco.abaBanco)}`,
              ],
              [
                new Txt('Address 1').end,
                `: ${emptyPipe.transform(banco.direccionBanco)}`,
              ],
              [
                new Txt('Address 2').end,
                `: ${emptyPipe.transform(banco.altDireccionBanco)}`,
              ],
              [
                new Txt('Country').end,
                `: ${emptyPipe.transform(banco.nombrePaisBanco)}`,
              ],
              [
                new Txt('State / Province').end,
                `: ${emptyPipe.transform(banco.nombreEstadoBanco)}`,
              ],
              [
                new Txt('City').end,
                `: ${emptyPipe.transform(banco.ciudadBanco)}`,
              ],
            ])
              .widths([100, '*'])
              .layout('noBorders').end
          );

          pdf.add(pdf.ln(2));

          pdf.add(
            new Table([
              [
                new Txt('Branch').end,
                `: ${emptyPipe.transform(banco.branchBanco)}`,
              ],
              [
                new Txt('Postal code').end,
                `: ${emptyPipe.transform(banco.codigoPostalBanco)}`,
              ],
              [
                new Txt('Address 1').end,
                `: ${emptyPipe.transform(banco.direccionBranchBanco)}`,
              ],
              [
                new Txt('Address 2').end,
                `: ${emptyPipe.transform(banco.altDireccionBranchBanco)}`,
              ],
              [
                new Txt('Country').end,
                `: ${emptyPipe.transform(banco.nombrePaisBranch)}`,
              ],
              [
                new Txt('State / Province').end,
                `: ${emptyPipe.transform(banco.nombreEstadoBranch)}`,
              ],
              [
                new Txt('City').end,
                `: ${emptyPipe.transform(banco.ciudadBranchBanco)}`,
              ],
            ])
              .widths([100, '*'])
              .layout('noBorders').end
          );
          pdf.add(pdf.ln(1));
          if (banco.intermediarios) {
            for (const [i, iterator] of banco.intermediarios.entries()) {
              pdf.add(new Txt(`Intermediary ${i + 1}`).bold().end);
              pdf.add(pdf.ln(1));
              pdf.add(
                new Table([
                  [
                    new Txt('Bank name').end,
                    `: ${emptyPipe.transform(iterator.nombreBancoInt)}`,
                  ],
                  [
                    new Txt('Account N°').end,
                    `: ${emptyPipe.transform(iterator.numeroCuentaInt)}`,
                  ],
                  [
                    new Txt('BIC/SWIFT code').end,
                    `: ${emptyPipe.transform(iterator.bicInt)}`,
                  ],
                  [
                    new Txt('Address 1').end,
                    `: ${emptyPipe.transform(iterator.direccionInt)}`,
                  ],
                  [
                    new Txt('Address 2').end,
                    `: ${emptyPipe.transform(iterator.altDireccionInt)}`,
                  ],
                  [
                    new Txt('Country').end,
                    `: ${emptyPipe.transform(iterator.nombrePaisInt)}`,
                  ],
                  [
                    new Txt('State / Province').end,
                    `: ${emptyPipe.transform(iterator.nombreEstadoInt)}`,
                  ],
                  [
                    new Txt('City').end,
                    `: ${emptyPipe.transform(iterator.ciudadInt)}`,
                  ],
                ])
                  .widths([100, '*'])
                  .layout('noBorders').end
              );
              pdf.add(pdf.ln(1));
            }
          }
        }
      }

      pdf.create().getDataUrl((data) => {
        resolve(data);
      });
    });
  }

  createFilePDA(empresa, info, banco) {
    // pdf.header('This is a header');
    return new Promise(async (resolve, reject) => {

      
     

      const codigoCurrency =  info.callData.currencyPDA?.codigoCurrencyB|| info.callData.currencyPDAviewer || 'No information'
 
      const pdf = new PdfMakeWrapper();
      const emptyPipe = new checkEmptyPipe();
      const agencyItems = info.cuentas.filter((x) => x.grupoCuenta === 'A' && x.accion!==0);
      const portItems = info.cuentas.filter(
        (x) => (x.grupoCuenta === 'B' || x.grupoCuenta === 'C') && x.accion!==0
      );
      const ownerItems = info.cuentas.filter((x) => x.grupoCuenta === 'D' && x.accion!==0);
      const otherItems = info.cuentas.filter((x) => x.grupoCuenta === 'E' && x.accion!==0);

      pdf.defaultStyle({
        fontSize: 8,
      });

      pdf.pageMargins([ 24, 24, 24, 24 ]);

      pdf.header(this.headerPDF);
      pdf.footer(this.footerPDF);

      let imgPipe= new urlImgPipe();
      
      // let url = imgPipe.transform(empresa.logoEmpresa,'avatarLogos');

      // const logo = await new Img(
      //   url
      // )
      //   .fit([150, 50])
      //   .alignment('right')
      //   .build();


      const logo = await new Img(
        `${this.dominioContainer + this.carpetaAvatarLogos + empresa.logoEmpresa
        }`
      )
        .fit([150, 50])
        .alignment('right')
        .build();


      
      
      const textTitulo=  new Txt('PROFORMA DISBURSEMENT ACCOUNT').fontSize(20).bold().end

      

      let canvasRightGruesa = new Canvas([new Line([0, 60], [0, 0]).lineColor('#00284b').lineWidth(15).end]).end
      let canvasRight3 = new Canvas([new Line([0, 47], [0, 0]).lineColor('#00284b').lineWidth(2).end]).end
      let canvasRight4 = new Canvas([new Line([0, 60], [0, 0]).lineColor('#00284b').lineWidth(2).end]).end

      let titulo = new Table([
        [canvasRightGruesa,textTitulo]

      ]).widths([10, 300]).layout('noBorders').end;

      pdf.add(
        new Table([[titulo, logo]]).layout('noBorders').widths(['50%', '50%'])
          .end
      );


      pdf.add(pdf.ln(1));

      const membrete = new Table([
        [new Txt('To').bold().end, `: ${info.callData.multiReceiver?info.callData.receivers?.map(x=>x.company).join(' / ')||'No Information':info.callData.principal}`],
        [
          new Txt('Subjet').bold().end,
          `: ${info.callData.vessel} / ${info.callData.nombrePort||info.callData.port.nombrePuerto} / ${info.usuario ? info.usuario : 'Without Reference'}`,
        ],
        [new Txt('Version').bold().end, `: ${info.version ? info.version : '1'}`],
      ])
        .widths([40, '*'])
        .layout('noBorders').end

      pdf.add(
        new Table([[canvasRight3, membrete]]).widths([4, 300]).layout('noBorders').end
      );

      pdf.add(pdf.ln(1));

      let tituloPortCall = new Txt('Port of Call Details')
        .margin([0, 0, 0, 5])
        .fontSize(10)
        .bold().end;


      let infoPortCall = new Table([
        [new Txt('Vessel').bold().end, `: ${info.callData.vessel}`],
        [new Txt('Port').bold().end, `: ${info.callData.nombreZona || info.callData.zona.nombre} / ${info.callData.nombrePort||info.callData.port.nombrePuerto}`],
        [new Txt('ETA').bold().end, `: ${ this.formatDateMDY(info.callData.eta)}`],
        [
          new Txt('Activity').bold().end,
          `: ${info.callData.nombreActivity || info.callData.activity.nombreActivity} ${ this.toFixedNumber(info.callData.CARGO,3) } TM`,
        ],
      ])
        .widths([40, '*'])
        .layout('noBorders').end;

      const AddBorderInfoPortCall = new Table([[canvasRight4, infoPortCall]]).widths([4, 300]).layout('noBorders').end

      let portCall = new Stack([tituloPortCall, AddBorderInfoPortCall]).end;

      

      let portStay = ': ';
      if (info.callData.daysPS !=='0' || info.callData.hoursPS !=='0') {
          portStay = (info.dias ||  0)  + ' Days ';
          // portStay = (info.callData.daysPS  || info.dias ||  0)  + ' Days ';
        
          portStay += (info.horas ||  0) + ' Hours';
          // portStay += (info.callData.hoursPS || info.horas ||  0) + ' Hours';
       
      } else {
        portStay = 'Add Port Stay';
      }

      let roes = '';

      if (info.roeFilter && info.roeFilter.length > 0) {
      
        info.roeFilter.forEach((x, i) => {
          if (i > 0) {
            roes += `, `;
          }
          roes += `${x.codigoCurrencyB} (${x.value} ${codigoCurrency})`;
        });
      } else {
        roes = 'N/A';
      }

      let tituloOurPDA = new Txt('Our PDA Basis')
        .margin([0, 0, 0, 5])
        .fontSize(10)
        .bold().end;
        
      let infoOurPDA = new Table([
        [new Txt('Days Estimate').bold().end, `: ${portStay}`],
        [
          new Txt('PDA Currency').bold().end,
          `: ${codigoCurrency}`,
        ],
        [new Txt("ROE'S").bold().end, `: ${roes}`],
      ])
        .widths([60, '*'])
        .layout('noBorders').end;

      canvasRight3 = new Canvas([new Line([0, 47], [0, 0]).lineColor('#00284b').lineWidth(2).end]).end

      const AddBorderInfoOurPDA = new Table([[canvasRight3, infoOurPDA]]).widths([4, 300]).layout('noBorders').end


      let OurPDA = new Stack([tituloOurPDA, AddBorderInfoOurPDA]).end;

      pdf.add(
        new Table([[portCall, OurPDA]]).widths(['*', '*']).layout('noBorders')
          .end
      );

      pdf.add(pdf.ln(1));

      const agencys = this.createRowCuentas(agencyItems);
      const portCost = this.createRowCuentas(portItems);
      const owner = this.createRowCuentas(ownerItems);
      const other = this.createRowCuentas(otherItems);

      let cuentas =[];

      let headerTable=[];
      headerTable.push(new Txt('PORT CHARGES').bold().end);
      headerTable.push(new Txt('COMMENTS').bold().end);
      headerTable.push(new Cell(new Txt(`AMOUNT (${codigoCurrency})`).bold().alignment('right').end).fillColor('#ebf8ff').end);
      
      const header = new Table([headerTable]).layout({
        hLineColor:()=>'#efefef',
        vLineColor:()=>'#efefef',
        hLineWidth:()=>0.1,
        vLineWidth:()=>0.1
      }).widths(['35%','50%', '15%']).end;

      cuentas.push(header);


      if (agencys.length > 0) {
         cuentas.push(agencys);
      }

      if (portCost.length > 0) {
        cuentas.push(portCost);
      }

      if (owner.length > 0) {
        
        cuentas.push(owner);
      }

      if (other.length > 0) {
        cuentas.push(other);
      }


      let total=[];

      let canvasRightGruesa2 = new Canvas([new Line([10, 5], [0, 5]).lineColor('#00284b').lineWidth(12).end]).end
      let textTotal =new Txt(`ESTIMATED AMOUNT (${codigoCurrency})`).alignment('right').color('#00284b').fontSize(10).bold().end;

      let textTotalWhitBorder = new Table([
        [canvasRightGruesa2,textTotal]
      ]).widths([10, '*']).layout('noBorders').end;


      total.push(textTotalWhitBorder);
      total.push(new Txt(`${this.toFixedNumber(info.total,2) }`).color('#00284b').fontSize(14).bold().alignment('right').end);
      
      

      
      pdf.add(
          new Table([
             [new Stack(cuentas).end]
          ]).widths(['*']).layout({
            hLineWidth:()=>0.1,
            vLineWidth:()=>0.1,
            hLineColor:()=>'#efefef',
            vLineColor:()=>'#efefef',
            paddingLeft:()=>0,
            paddingRight:()=>0,
            paddingTop:()=>0,
            paddingBottom:()=>0}).end
      );

      
      let _total = new Table([total]).layout({
        fillColor:()=>'#ebf8ff',
        hLineColor:()=>'#efefef',
        vLineColor:()=>'#efefef',
        hLineWidth:()=>0.1,
        vLineWidth:()=>0.1
      }).widths(['85%', '15%']);

      if(info.cuentas.length<=32){
        
        _total.pageBreak('after')
      }

      pdf.add(_total.end);

      pdf.add(pdf.ln(2));

      let canvasRight1 = new Canvas([new Line([0, 20], [0, 0]).lineColor('#00284b').lineWidth(2).end]).end

      let tituloPayment = new Txt('Payment Details')
      .margin([0, 0, 0, 5])
      .fontSize(15)
      .color('#00284b')
      .bold().end;

      let tituloPaymentWitBorder = new Table([
        [canvasRight1,tituloPayment]
      ]).widths([5, '*']).layout('noBorders').end;

      pdf.add(tituloPaymentWitBorder);

      pdf.add(pdf.ln(1));

      
        let infoTopBank= new Table([
          [
            new Txt('Payment').bold().end,
            `: ${info.total } ${codigoCurrency || ''}`,
          ],
          [
            new Txt('Advance Request').bold().end,
            `: ${info.nombreAdvanceReq  || info.advance.advanceReq?.nombre || 'No information'}`,
          ],
          [
            new Txt('Advance Payment').bold().end,
            `: ${info.advance.totalAdvance} ${codigoCurrency || ''}`,
          ],
          [
            new Txt('Advance porcentage').bold().end,
            `: ${info.advance.porcAdvance}%`,
          ],
        ])
          .widths([100, '*'])
          .layout('noBorders').end;
      
        canvasRight4= new Canvas([new Line([0, 60], [0, 0]).lineColor('#00284b').lineWidth(2).end]).end

        const AddBorderinfoTopBank = new Table([[canvasRight4, infoTopBank]]).widths([4, 300]).layout('noBorders').end


          pdf.add(
            AddBorderinfoTopBank
          );

      pdf.add(pdf.ln(2));

      let infoBankbottom;
      let AddBorderinfoBottomBank;

      if (banco) {
        if (banco.codigoCurrencyB === 'BTC') {
          let canvasRight2 = new Canvas([new Line([0, 40], [0, 0]).lineColor('#00284b').lineWidth(2).end]).end


          infoBankbottom=new Table([
            [
              new Txt('Account N°').end,
              `: ${emptyPipe.transform(banco.numeroCuentaBanco)}`,
            ],
            [
              new Txt('Email Address').end,
              `: ${emptyPipe.transform(banco.correoBanco)}`,
            ],
          ])
            .widths([80, '*'])
            .layout('noBorders').end;

            AddBorderinfoBottomBank = new Table([[canvasRight2, infoBankbottom]]).widths([4, 300]).layout('noBorders').end

            pdf.add(AddBorderinfoBottomBank);


        } else if(banco.codigoCurrencyB === 'Cash'){

          canvasRight1 = new Canvas([new Line([0, 20], [0, 0]).lineColor('#00284b').lineWidth(2).end]).end


          infoBankbottom=new Table([
            [
              new Txt('Method').end,
              ': Cash',
            ]
          ])
            .widths([50, '*'])
            .layout('noBorders').end;

            AddBorderinfoBottomBank = new Table([[canvasRight1, infoBankbottom]]).widths([4, 300]).layout('noBorders').end

            pdf.add(AddBorderinfoBottomBank);


        } else {
          
         let canvasRight5a = new Canvas([new Line([0, 75], [0, 0]).lineColor('#00284b').lineWidth(2).end]).end

          infoBankbottom= new Table([
              [
                new Txt('Bank name').end,
                `: ${emptyPipe.transform(banco.nombreBanco)}`,
              ],
              [
                new Txt('Account N°').end,
                `: ${emptyPipe.transform(banco.numeroCuentaBanco)}`,
              ],
              [
                new Txt('Beneficiary Name').end,
                `: ${emptyPipe.transform(banco.beneficiarioBanco)}`,
              ],
              [
                new Txt('Taxid').end,
                `: ${emptyPipe.transform(banco.taxIdBanco)}`,
              ],
              [
                new Txt('Email Address').end,
                `: ${emptyPipe.transform(banco.correoBanco)}`,
              ],
            ])
              .widths([100, '*'])
              .layout('noBorders').end
          
          AddBorderinfoBottomBank = new Table([[canvasRight5a, infoBankbottom]]).widths([4, 300]).layout('noBorders').end

          pdf.add(AddBorderinfoBottomBank);


          if (['USD', 'EUR'].includes(banco.codigoCurrencyB)) {
            
            pdf.add(pdf.ln(2));
            
           let canvasRight5b = new Canvas([new Line([0, 105], [0, 0]).lineColor('#00284b').lineWidth(2).end]).end

            let a =  new Table([
                [
                  new Txt('BIC / SWIFT code').end,
                  `: ${emptyPipe.transform(banco.bicBanco)}`,
                ],
                [
                  new Txt('IBAN / ABA routing').end,
                  `: ${emptyPipe.transform(banco.abaBanco)}`,
                ],
                [
                  new Txt('Address 1').end,
                  `: ${emptyPipe.transform(banco.direccionBanco)}`,
                ],
                [
                  new Txt('Address 2').end,
                  `: ${emptyPipe.transform(banco.altDireccionBanco)}`,
                ],
                [
                  new Txt('Country').end,
                  `: ${emptyPipe.transform(banco.nombrePaisBanco)}`,
                ],
                [
                  new Txt('State / Province').end,
                  `: ${emptyPipe.transform(banco.nombreEstadoBanco)}`,
                ],
                [
                  new Txt('City').end,
                  `: ${emptyPipe.transform(banco.ciudadBanco)}`,
                ],
              ])
                .widths([100, '*'])
                .layout('noBorders').end
            
           let AddBorderBick = new Table([[canvasRight5b, a]]).widths([4, 300]).layout('noBorders').end

            pdf.add(AddBorderBick);

            pdf.add(pdf.ln(2));

            let canvasRight5c = new Canvas([new Line([0, 105], [0, 0]).lineColor('#00284b').lineWidth(2).end]).end
            
            let b =  new Table([
                [
                  new Txt('Branch').end,
                  `: ${emptyPipe.transform(banco.branchBanco)}`,
                ],
                [
                  new Txt('Postal code').end,
                  `: ${emptyPipe.transform(banco.codigoPostalBanco)}`,
                ],
                [
                  new Txt('Address 1').end,
                  `: ${emptyPipe.transform(banco.direccionBranchBanco)}`,
                ],
                [
                  new Txt('Address 2').end,
                  `: ${emptyPipe.transform(banco.altDireccionBranchBanco)}`,
                ],
                [
                  new Txt('Country').end,
                  `: ${emptyPipe.transform(banco.nombrePaisBranch)}`,
                ],
                [
                  new Txt('State / Province').end,
                  `: ${emptyPipe.transform(banco.nombreEstadoBranch)}`,
                ],
                [
                  new Txt('City').end,
                  `: ${emptyPipe.transform(banco.ciudadBranchBanco)}`,
                ],
            ])
            .widths([100, '*'])
            .layout('noBorders').end
            
            let AddBorderBrach = new Table([[canvasRight5c, b]]).widths([4, 300]).layout('noBorders').end

            pdf.add(AddBorderBrach);

            pdf.add(pdf.ln(2));

            if (banco.intermediarios) {
              for (const [i, iterator] of banco.intermediarios.entries()) {

                pdf.add(new Txt(`Intermediary ${i + 1}`).fontSize(10).bold().end);

                pdf.add(pdf.ln(1));
                
                let inter=  new Table([
                    [
                      new Txt('Bank name').end,
                      `: ${emptyPipe.transform(iterator.nombreBancoInt)}`,
                    ],
                    [
                      new Txt('Account N°').end,
                      `: ${emptyPipe.transform(iterator.numeroCuentaInt)}`,
                    ],
                    [
                      new Txt('BIC/SWIFT code').end,
                      `: ${emptyPipe.transform(iterator.bicInt)}`,
                    ],
                    [
                      new Txt('Address 1').end,
                      `: ${emptyPipe.transform(iterator.direccionInt)}`,
                    ],
                    [
                      new Txt('Address 2').end,
                      `: ${emptyPipe.transform(iterator.altDireccionInt)}`,
                    ],
                    [
                      new Txt('Country').end,
                      `: ${emptyPipe.transform(iterator.nombrePaisInt)}`,
                    ],
                    [
                      new Txt('State / Province').end,
                      `: ${emptyPipe.transform(iterator.nombreEstadoInt)}`,
                    ],
                    [
                      new Txt('City').end,
                      `: ${emptyPipe.transform(iterator.ciudadInt)}`,
                    ],
                  ])
                    .widths([100, '*'])
                    .layout('noBorders').end
                let canvasRight5d = new Canvas([new Line([0, 105], [0, 0]).lineColor('#00284b').lineWidth(2).end]).end
                AddBorderinfoBottomBank = new Table([[canvasRight5d, inter]]).widths([4, 300]).layout('noBorders').end
                pdf.add(AddBorderinfoBottomBank);
                pdf.add(pdf.ln(1));
              }
            }
          }
        }


      } else {
        pdf.add(new Txt('No bank information').end);
      }

      pdf.add(pdf.ln(2));

      canvasRight1 = new Canvas([new Line([0, 20], [0, 0]).lineColor('#00284b').lineWidth(2).end]).end

      let tituloPolicy=new Txt('Agency Policy')
      .margin([0, 0, 0, 5])
      .fontSize(15)
      .color('#00284b')
      .bold().end

      pdf.add(
        new Table([
          [canvasRight1,tituloPolicy]
        ]).widths([5, '*']).layout('noBorders').end);

      pdf.add(pdf.ln(1));

      if (info.policys.length > 0) {
        for (const p of info.policys) {
          pdf.add(new Txt(p.nombrePolicy).color('#00284b').bold().end);
          pdf.add(pdf.ln(1));

          for (const c of p.clausulas) {
            pdf.add(new Txt(c.nombreClausula + ':').italics().end);
            pdf.add(pdf.ln(1));
            pdf.add(new Txt(c.descripcionClausula).end);
            pdf.add(pdf.ln(1));
          }
        }
      } else {
        pdf.add(new Txt('No policys').end);
        pdf.add(pdf.ln(1));
      }

      pdf.create().getDataUrl((data) => {
        resolve(data);
      });
    });
  }

  createFilePDASplit(empresa, info, banco,receiver) {
    // pdf.header('This is a header');
    return new Promise(async (resolve, reject) => {

     


      const codigoCurrency =  info.callData.currencyPDA?.codigoCurrencyB|| info.callData.currencyPDAviewer || 'No information'
 
      const pdf = new PdfMakeWrapper();
      const emptyPipe = new checkEmptyPipe();

      pdf.defaultStyle({
        fontSize: 8,
      });

      pdf.pageMargins([ 24, 24, 24, 60]);

      pdf.header(this.headerPDF);

      let footerPDF  = (currentPage:any,pagenumber: number, pagecount: number) => {
        return {
          margin: [24, 0, 24, 0],
          fontSize: 7,
          alignment: 'left',
          color: '#000',
          text: `MARACAIBO (MAIN OFFICE): Calle 76 Entre Avenidas 3Y y 3H, Edificio Villa Ota II, Piso 5, PH1, Sector La Lago–Maracaibo-Estado Zulia Teléfonos: +58 261 7924253 Fax: + 58 261 7921606 Código Postal: 4001/ E-mail: main@oceanicaint.com / accounting@oceanicaint.com
                 \n
                PUERTO CABELLO (BRANCH OFFICE): Calle Ricaute Cruce con Calle Puerto Cabello, Edif.1-57, Piso 1, Ofic 12, Puerto Cabello-Estado Carabobo,Teléfonos: + 58 2423620322/ Código Postal: 4001/ E-mail: opscabello@oceanicaint.com / admonpcb@oceanicaint.com`,
        };
      };

      pdf.footer(footerPDF);

      const logo = await new Img(
        `${this.dominioContainer + this.carpetaAvatarLogos + empresa.logoEmpresa
        }`
      )
        .fit([150, 50])
        .alignment('right')
        .build();



      

      info.fecha = !this.isEmptyOrNull(info.fecha) ? this.formatDateMDY(info.fecha):this.formatDateMDY(new Date());
      const textTitulo=  new Txt('INVOICE').fontSize(20).bold().end
      const textDate=  new Txt(`${info.fecha}`).fontSize(16).bold().end
 
      let canvasRightGruesa = new Canvas([new Line([0, 60], [0, 0]).lineColor('#00284b').lineWidth(15).end]).end
      let canvasRight3 = new Canvas([new Line([0, 47], [0, 0]).lineColor('#00284b').lineWidth(2).end]).end
      let canvasRight4 = new Canvas([new Line([0, 60], [0, 0]).lineColor('#00284b').lineWidth(2).end]).end

      let titulo = new Table([
        [canvasRightGruesa,new Stack([textTitulo, textDate]).end]

      ]).widths([10, 300]).layout('noBorders').end;

      pdf.add(
        new Table([[titulo, logo]]).layout('noBorders').widths(['50%', '50%'])
          .end
      );


      pdf.add(pdf.ln(1));


      const membrete = new Table([
        [new Txt('To').bold().end, `: ${receiver.company}`],
        [
          new Txt('Subjet').bold().end,
          `: ${info.callData.vessel} / ${info.callData.nombrePort||info.callData.port.nombrePuerto} / ${info.usuario ? info.usuario : 'Without Reference'}`,
        ],
        [new Txt('Version').bold().end, `: ${info.version ? info.version : '1'}`],
      ])
        .widths([40, '*'])
        .layout('noBorders').end

      pdf.add(
        new Table([[canvasRight3, membrete]]).widths([4, 300]).layout('noBorders').end
      );

      pdf.add(pdf.ln(2));

      let tituloPortCall = new Txt('Port of Call Details')
        .margin([0, 0, 0, 5])
        .fontSize(10)
        .bold().end;

      let infoPortCall = new Table([
        [new Txt('Vessel').bold().end, `: ${info.callData.vessel}`],
        [new Txt('Port').bold().end, `: ${info.callData.nombreZona || info.callData.zona.nombre} / ${info.callData.nombrePort||info.callData.port.nombrePuerto}`],
        [new Txt('ETA').bold().end, `: ${this.formatDateMDY(info.callData.eta)}`],
        [
          new Txt('Activity').bold().end,
          `: ${info.callData.nombreActivity || info.callData.activity.nombreActivity}`,
        ],
      ])
        .widths([40, '*'])
        .layout('noBorders').end;

      const AddBorderInfoPortCall = new Table([[canvasRight4, infoPortCall]]).widths([4, 300]).layout('noBorders').end

      let portCall = new Stack([tituloPortCall, AddBorderInfoPortCall]).end;


      let portStay = ': ';
      if (info.dias !=='0' || info.horas !=='0') {
          portStay = (info.dias || 0)  + ' Days ';
        
          portStay += (info.horas || 0) + ' Hours';
       
      } else {
        portStay = 'Add Port Stay';
      }

      let roes = '';

      if (info.roeFilter && info.roeFilter.length > 0) {
      
        info.roeFilter.forEach((x, i) => {
          if (i > 0) {
            roes += `, `;
          }
          roes += `${x.codigoCurrencyB} (${x.value} ${codigoCurrency})`;
        });
      } else {
        roes = 'N/A';
      }

      let tituloOurPDA = new Txt('Our PDA Basis')
        .margin([0, 0, 0, 5])
        .fontSize(10)
        .bold().end;
        
      let infoOurPDA = new Table([
        [new Txt('Days Estimate').bold().end, `: ${portStay}`],
        [
          new Txt('PDA Currency').bold().end,
          `: ${codigoCurrency}`,
        ],
        [new Txt("ROE'S").bold().end, `: ${roes}`],
        [new Txt("Cargo Split").bold().end, `: ${this.toFixedNumber(receiver.qty,3)} Mt (${this.toFixedNumber(receiver.porcentaje,3)}%)`],
      ])
        .widths([60, '*'])
        .layout('noBorders').end;

      canvasRight4 = new Canvas([new Line([0, 60], [0, 0]).lineColor('#00284b').lineWidth(2).end]).end

      const AddBorderInfoOurPDA = new Table([[canvasRight4, infoOurPDA]]).widths([4, 300]).layout('noBorders').end


      let OurPDA = new Stack([tituloOurPDA, AddBorderInfoOurPDA]).end;

      pdf.add(new Table([[portCall, OurPDA]]).widths(['*', '*']).layout('noBorders').end);

      pdf.add(pdf.ln(4));

      let cuentas =[];
      let headerTable=[];
      headerTable.push(new Txt('DESCRIPCION').bold().end);
      headerTable.push(new Cell(new Txt(`SUB-TOTAL ${codigoCurrency}.`).bold().alignment('right').end).end);
      
      const header = new Table([headerTable]).layout('noBorders').widths(['60%','40%']).end;

      cuentas.push(header);

      const columns = [];
      columns.push(new Txt(`Port Cost Mv. ${info.callData.vessel} / Basis on ${this.toFixedNumber(receiver.qty,3)} MT of cargo representing ${this.toFixedNumber(receiver.porcentaje,3)}%  of cargo total amount`).end);

      var valor = this.toFixedNumber(Number(this.getPorcentage(info.total,receiver.porcentaje)),2);
     
     
      
      columns.push(new Cell(new Txt(valor).alignment('right').end).end);
      
      const row = new Table([columns]).widths(['60%','40%']).layout('noBorders').end;


      cuentas.push(row);


      let total=[];


      let textTotal =new Txt('SUB-TOTAL').alignment('right').bold().end;
      let valorTotal = new Txt(`${valor}`).bold().alignment('right').end
      const rowTotal = new Table([[textTotal,valorTotal]]).widths(['60%','40%']).layout('noBorders').end;
      total.push(rowTotal);

      let textExent=new Txt('EXENT').alignment('right').bold().end;
      let valorExent = new Txt(`0.00`).bold().alignment('right').end
      const rowExent = new Table([[textExent,valorExent]]).widths(['60%','40%']).layout('noBorders').end;
      total.push(rowExent);
      
      let textTax=new Txt(`TAX ${codigoCurrency} - I.V.A 16%`).alignment('right').bold().end;
      let valorTax = new Txt(`0.00`).bold().alignment('right').end
      const rowTax= new Table([[textTax,valorTax]]).widths(['60%','40%']).layout('noBorders').end;
      total.push(rowTax);

      let textTotal2 =new Txt('TOTAL').alignment('right').bold().end;
      let valorTotal2 = new Txt(`${valor}`).bold().alignment('right').end
      const rowTotal2 = new Table([[textTotal2,valorTotal2]]).widths(['60%','40%']).layout('noBorders').end;
      total.push(rowTotal2);
      
      

      pdf.add(new Table([[new Stack(cuentas).end]]).widths(['*']).layout('noBorders').end);
      pdf.add(pdf.ln(12));

      pdf.add(new Canvas([new Line([0, 0], [545, 0]).lineColor('#000').end]).end);
      
      pdf.add(pdf.ln(1));

      let _total = new Table([[new Stack(total).end]]).layout('noBorders').widths(['*']);

      // if(info.cuentas.length<=32){
        
        _total.pageBreak('after')
      // }

      pdf.add(_total.end);

      pdf.add(pdf.ln(4));

      let canvasRight1 = new Canvas([new Line([0, 20], [0, 0]).lineColor('#00284b').lineWidth(2).end]).end

      let tituloPayment = new Txt('Payment Details')
      .margin([0, 0, 0, 5])
      .fontSize(15)
      .color('#00284b')
      .bold().end;

      let tituloPaymentWitBorder = new Table([
        [canvasRight1,tituloPayment]
      ]).widths([5, '*']).layout('noBorders').end;

      pdf.add(tituloPaymentWitBorder);

      pdf.add(pdf.ln(1));

      
        let infoTopBank= new Table([
          [
            new Txt('Payment').bold().end,
            `: ${valor} ${codigoCurrency}`,
          ],
          [
            new Txt('Advance Request').bold().end,
            `: ${ info.nombreAdvanceReq || info.advance?.advanceReq?.nombre || 'No information'}`,
          ],
          [
            new Txt('Advance Payment').bold().end,
            `: ${ this.getPorcentage(valor,info.advance.porcAdvance) } ${codigoCurrency}`,
          ],
          [
            new Txt('Advance porcentage').bold().end,
            `: ${info.advance.porcAdvance}%`,
          ],
        ])
          .widths([100, '*'])
          .layout('noBorders').end;
      
        canvasRight4= new Canvas([new Line([0, 60], [0, 0]).lineColor('#00284b').lineWidth(2).end]).end

        const AddBorderinfoTopBank = new Table([[canvasRight4, infoTopBank]]).widths([4, 300]).layout('noBorders').end


        pdf.add(
          AddBorderinfoTopBank
        );

      pdf.add(pdf.ln(2));

      let infoBankbottom;
      let AddBorderinfoBottomBank;

      if (banco) {
        if (banco.codigoCurrencyB === 'BTC') {
          let canvasRight2 = new Canvas([new Line([0, 40], [0, 0]).lineColor('#00284b').lineWidth(2).end]).end


          infoBankbottom=new Table([
            [
              new Txt('Account N°').end,
              `: ${emptyPipe.transform(banco.numeroCuentaBanco)}`,
            ],
            [
              new Txt('Email Address').end,
              `: ${emptyPipe.transform(banco.correoBanco)}`,
            ],
          ])
            .widths([80, '*'])
            .layout('noBorders').end;

            AddBorderinfoBottomBank = new Table([[canvasRight2, infoBankbottom]]).widths([4, 300]).layout('noBorders').end

            pdf.add(AddBorderinfoBottomBank);


        } else if(banco.codigoCurrencyB === 'Cash'){

          canvasRight1 = new Canvas([new Line([0, 20], [0, 0]).lineColor('#00284b').lineWidth(2).end]).end


          infoBankbottom=new Table([
            [
              new Txt('Method').end,
              ': Cash',
            ]
          ])
            .widths([50, '*'])
            .layout('noBorders').end;

            AddBorderinfoBottomBank = new Table([[canvasRight1, infoBankbottom]]).widths([4, 300]).layout('noBorders').end

            pdf.add(AddBorderinfoBottomBank);


        } else {
          
         let canvasRight5a = new Canvas([new Line([0, 75], [0, 0]).lineColor('#00284b').lineWidth(2).end]).end

          infoBankbottom= new Table([
              [
                new Txt('Bank name').end,
                `: ${emptyPipe.transform(banco.nombreBanco)}`,
              ],
              [
                new Txt('Account N°').end,
                `: ${emptyPipe.transform(banco.numeroCuentaBanco)}`,
              ],
              [
                new Txt('Beneficiary Name').end,
                `: ${emptyPipe.transform(banco.beneficiarioBanco)}`,
              ],
              [
                new Txt('Taxid').end,
                `: ${emptyPipe.transform(banco.taxIdBanco)}`,
              ],
              [
                new Txt('Email Address').end,
                `: ${emptyPipe.transform(banco.correoBanco)}`,
              ],
            ])
              .widths([100, '*'])
              .layout('noBorders').end
          
          AddBorderinfoBottomBank = new Table([[canvasRight5a, infoBankbottom]]).widths([4, 300]).layout('noBorders').end

          pdf.add(AddBorderinfoBottomBank);


          if (['USD', 'EUR'].includes(banco.codigoCurrencyB)) {
            
            pdf.add(pdf.ln(2));
            
           let canvasRight5b = new Canvas([new Line([0, 105], [0, 0]).lineColor('#00284b').lineWidth(2).end]).end

            let a =  new Table([
                [
                  new Txt('BIC / SWIFT code').end,
                  `: ${emptyPipe.transform(banco.bicBanco)}`,
                ],
                [
                  new Txt('IBAN / ABA routing').end,
                  `: ${emptyPipe.transform(banco.abaBanco)}`,
                ],
                [
                  new Txt('Address 1').end,
                  `: ${emptyPipe.transform(banco.direccionBanco)}`,
                ],
                [
                  new Txt('Address 2').end,
                  `: ${emptyPipe.transform(banco.altDireccionBanco)}`,
                ],
                [
                  new Txt('Country').end,
                  `: ${emptyPipe.transform(banco.nombrePaisBanco)}`,
                ],
                [
                  new Txt('State / Province').end,
                  `: ${emptyPipe.transform(banco.nombreEstadoBanco)}`,
                ],
                [
                  new Txt('City').end,
                  `: ${emptyPipe.transform(banco.ciudadBanco)}`,
                ],
              ])
                .widths([100, '*'])
                .layout('noBorders').end
            
           let AddBorderBick = new Table([[canvasRight5b, a]]).widths([4, 300]).layout('noBorders').end

            pdf.add(AddBorderBick);

            pdf.add(pdf.ln(2));

            let canvasRight5c = new Canvas([new Line([0, 105], [0, 0]).lineColor('#00284b').lineWidth(2).end]).end
            
            let b =  new Table([
                [
                  new Txt('Branch').end,
                  `: ${emptyPipe.transform(banco.branchBanco)}`,
                ],
                [
                  new Txt('Postal code').end,
                  `: ${emptyPipe.transform(banco.codigoPostalBanco)}`,
                ],
                [
                  new Txt('Address 1').end,
                  `: ${emptyPipe.transform(banco.direccionBranchBanco)}`,
                ],
                [
                  new Txt('Address 2').end,
                  `: ${emptyPipe.transform(banco.altDireccionBranchBanco)}`,
                ],
                [
                  new Txt('Country').end,
                  `: ${emptyPipe.transform(banco.nombrePaisBranch)}`,
                ],
                [
                  new Txt('State / Province').end,
                  `: ${emptyPipe.transform(banco.nombreEstadoBranch)}`,
                ],
                [
                  new Txt('City').end,
                  `: ${emptyPipe.transform(banco.ciudadBranchBanco)}`,
                ],
              ])
                .widths([100, '*'])
                .layout('noBorders').end
            
            let AddBorderBrach = new Table([[canvasRight5c, b]]).widths([4, 300]).layout('noBorders').end

            pdf.add(AddBorderBrach);

            pdf.add(pdf.ln(2));

            if (banco.intermediarios) {
              for (const [i, iterator] of banco.intermediarios.entries()) {

                pdf.add(new Txt(`Intermediary ${i + 1}`).fontSize(10).bold().end);

                pdf.add(pdf.ln(1));
                
                let inter=  new Table([
                    [
                      new Txt('Bank name').end,
                      `: ${emptyPipe.transform(iterator.nombreBancoInt)}`,
                    ],
                    [
                      new Txt('Account N°').end,
                      `: ${emptyPipe.transform(iterator.numeroCuentaInt)}`,
                    ],
                    [
                      new Txt('BIC/SWIFT code').end,
                      `: ${emptyPipe.transform(iterator.bicInt)}`,
                    ],
                    [
                      new Txt('Address 1').end,
                      `: ${emptyPipe.transform(iterator.direccionInt)}`,
                    ],
                    [
                      new Txt('Address 2').end,
                      `: ${emptyPipe.transform(iterator.altDireccionInt)}`,
                    ],
                    [
                      new Txt('Country').end,
                      `: ${emptyPipe.transform(iterator.nombrePaisInt)}`,
                    ],
                    [
                      new Txt('State / Province').end,
                      `: ${emptyPipe.transform(iterator.nombreEstadoInt)}`,
                    ],
                    [
                      new Txt('City').end,
                      `: ${emptyPipe.transform(iterator.ciudadInt)}`,
                    ],
                  ])
                    .widths([100, '*'])
                    .layout('noBorders').end
                let canvasRight5d = new Canvas([new Line([0, 105], [0, 0]).lineColor('#00284b').lineWidth(2).end]).end
                AddBorderinfoBottomBank = new Table([[canvasRight5d, inter]]).widths([4, 300]).layout('noBorders').end
                pdf.add(AddBorderinfoBottomBank);
                pdf.add(pdf.ln(1));
              }
            }
          }
        }


      } else {
        pdf.add(new Txt('No bank information').end);
      }

      pdf.add(pdf.ln(2));


      pdf.create().getDataUrl((data) => {
        resolve(data);
      });
    });
  }

  createFilePDASplitAgencyFees(empresa, info, banco,receiver) {
    // pdf.header('This is a header');
    return new Promise(async (resolve, reject) => {

      const codigoCurrency =  info.callData.currencyPDA?.codigoCurrencyB || info.callData.currencyPDAviewer || 'No information'
 
      const pdf = new PdfMakeWrapper();
      const emptyPipe = new checkEmptyPipe();

      pdf.defaultStyle({
        fontSize: 8,
      });

      pdf.pageMargins([ 24, 24, 24, 60]);

      pdf.header(this.headerPDF);

      let footerPDF  = (currentPage:any,pagenumber: number, pagecount: number) => {
        return {
          margin: [24, 0, 24, 0],
          fontSize: 7,
          alignment: 'left',
          color: '#000',
          text: `MARACAIBO (MAIN OFFICE): Calle 76 Entre Avenidas 3Y y 3H, Edificio Villa Ota II, Piso 5, PH1, Sector La Lago–Maracaibo-Estado Zulia Teléfonos: +58 261 7924253 Fax: + 58 261 7921606 Código Postal: 4001/ E-mail: main@oceanicaint.com / accounting@oceanicaint.com
                 \n
                PUERTO CABELLO (BRANCH OFFICE): Calle Ricaute Cruce con Calle Puerto Cabello, Edif.1-57, Piso 1, Ofic 12, Puerto Cabello-Estado Carabobo,Teléfonos: + 58 2423620322/ Código Postal: 4001/ E-mail: opscabello@oceanicaint.com / admonpcb@oceanicaint.com`,
        };
      };

      pdf.footer(footerPDF);

      const logo = await new Img(
        `${this.dominioContainer + this.carpetaAvatarLogos + empresa.logoEmpresa
        }`
      )
        .fit([150, 50])
        .alignment('right')
        .build();



      

      info.fecha = !this.isEmptyOrNull(info.fecha) ? this.formatDateMDY(info.fecha):this.formatDateMDY(new Date());

      const textTitulo=  new Txt('INVOICE').fontSize(20).bold().end
      const textDate=  new Txt(`${info.fecha}`).fontSize(16).bold().end
      

      let canvasRightGruesa = new Canvas([new Line([0, 60], [0, 0]).lineColor('#00284b').lineWidth(15).end]).end
      let canvasRight3 = new Canvas([new Line([0, 47], [0, 0]).lineColor('#00284b').lineWidth(2).end]).end
      let canvasRight4 = new Canvas([new Line([0, 60], [0, 0]).lineColor('#00284b').lineWidth(2).end]).end

      let titulo = new Table([
        [canvasRightGruesa,new Stack([textTitulo, textDate]).end]
      ]).widths([10, 300]).layout('noBorders').end;

      pdf.add(
        new Table([[titulo, logo]]).layout('noBorders').widths(['50%', '50%'])
          .end
      );


      pdf.add(pdf.ln(1));


      const membrete = new Table([
        [new Txt('To').bold().end, `: ${receiver.company}`],
        [
          new Txt('Subjet').bold().end,
          `: ${info.callData.vessel} / ${info.callData.nombrePort||info.callData.port.nombrePuerto} / ${info.usuario ? info.usuario : 'Without Reference'}`,
        ],
        [new Txt('Version').bold().end, `: ${info.version ? info.version : '1'}`],
      ])
        .widths([40, '*'])
        .layout('noBorders').end

      pdf.add(
        new Table([[canvasRight3, membrete]]).widths([4, 300]).layout('noBorders').end
      );

      pdf.add(pdf.ln(2));

      let tituloPortCall = new Txt('Port of Call Details')
        .margin([0, 0, 0, 5])
        .fontSize(10)
        .bold().end;

      let infoPortCall = new Table([
        [new Txt('Vessel').bold().end, `: ${info.callData.vessel}`],
        [new Txt('Port').bold().end, `: ${info.callData.nombreZona || info.callData.zona.nombre} / ${info.callData.nombrePort||info.callData.port.nombrePuerto}`],
        [new Txt('ETA').bold().end, `: ${ this.formatDateMDY(info.callData.eta)}`],
        [
          new Txt('Activity').bold().end,
          `: ${info.callData.nombreActivity || info.callData.activity.nombreActivity}`,
        ],
      ])
        .widths([40, '*'])
        .layout('noBorders').end;

      const AddBorderInfoPortCall = new Table([[canvasRight4, infoPortCall]]).widths([4, 300]).layout('noBorders').end

      let portCall = new Stack([tituloPortCall, AddBorderInfoPortCall]).end;


      let portStay = ': ';
      if (info.dias !=='0' || info.horas !=='0') {
          portStay = (info.dias || 0)  + ' Days ';
        
          portStay += (info.horas || 0) + ' Hours';
       
      } else {
        portStay = 'Add Port Stay';
      }

      let roes = '';

      if (info.roeFilter && info.roeFilter.length > 0) {
      
        info.roeFilter.forEach((x, i) => {
          if (i > 0) {
            roes += `, `;
          }
          roes += `${x.codigoCurrencyB} (${x.value} ${codigoCurrency})`;
        });
      } else {
        roes = 'N/A';
      }

      let tituloOurPDA = new Txt('Our PDA Basis')
        .margin([0, 0, 0, 5])
        .fontSize(10)
        .bold().end;
        
      let infoOurPDA = new Table([
        [new Txt('Days Estimate').bold().end, `: ${portStay}`],
        [
          new Txt('PDA Currency').bold().end,
          `: ${codigoCurrency}`,
        ],
        [new Txt("ROE'S").bold().end, `: ${roes}`],
        [new Txt("Cargo Split").bold().end, `: ${this.toFixedNumber(receiver.qty,3)} Mt (${this.toFixedNumber(receiver.porcentaje,3)}%)`],
      ])
        .widths([60, '*'])
        .layout('noBorders').end;

      canvasRight4 = new Canvas([new Line([0, 60], [0, 0]).lineColor('#00284b').lineWidth(2).end]).end

      const AddBorderInfoOurPDA = new Table([[canvasRight4, infoOurPDA]]).widths([4, 300]).layout('noBorders').end


      let OurPDA = new Stack([tituloOurPDA, AddBorderInfoOurPDA]).end;

      pdf.add(new Table([[portCall, OurPDA]]).widths(['*', '*']).layout('noBorders').end);

      pdf.add(pdf.ln(4));

      let cuentas =[];
      let headerTable=[];
      headerTable.push(new Txt('DESCRIPCION').bold().end);
      headerTable.push(new Cell(new Txt(`SUB-TOTAL ${codigoCurrency}.`).bold().alignment('right').end).end);
      
      const header = new Table([headerTable]).layout('noBorders').widths(['60%','40%']).end;

      cuentas.push(header);

      const columns = [];
      columns.push(new Txt(`Agency Fess`).end);

      var valor = receiver.agencyFees;
     
      columns.push(new Cell(new Txt(this.toFixedNumber(valor,2)).alignment('right').end).end);
      
      const row = new Table([columns]).widths(['60%','40%']).layout('noBorders').end;


      cuentas.push(row);


      let total=[];

      let textTotal =new Txt('SUB-TOTAL').alignment('right').bold().end;
      let valorTotal = new Txt(`${this.toFixedNumber(valor,2)}`).bold().alignment('right').end
      const rowTotal = new Table([[textTotal,valorTotal]]).widths(['60%','40%']).layout('noBorders').end;
      total.push(rowTotal);

      let textExent=new Txt('EXENT').alignment('right').bold().end;
      let valorExent = new Txt(`0.00`).bold().alignment('right').end
      const rowExent = new Table([[textExent,valorExent]]).widths(['60%','40%']).layout('noBorders').end;
      total.push(rowExent);
      
      let textTax=new Txt(`TAX ${codigoCurrency} - I.V.A ${receiver.porcTax!==''?receiver.porcTax:'0.00'}%`).alignment('right').bold().end;
      let agencyFeesTax =  (receiver.porcTax !== ''? ((valor* receiver.porcTax)/100) :0)
      let valorTax = new Txt(this.toFixedNumber(agencyFeesTax,2)).bold().alignment('right').end
      const rowTax= new Table([[textTax,valorTax]]).widths(['60%','40%']).layout('noBorders').end;
      total.push(rowTax);

      let textTotal2 =new Txt('TOTAL').alignment('right').bold().end;
      let valorTotal2 = new Txt(`${this.toFixedNumber(receiver.agencyFeesTotal,2)}`).bold().alignment('right').end
      const rowTotal2 = new Table([[textTotal2,valorTotal2]]).widths(['60%','40%']).layout('noBorders').end;
      total.push(rowTotal2);
      
      

      pdf.add(new Table([[new Stack(cuentas).end]]).widths(['*']).layout('noBorders').end);
      pdf.add(pdf.ln(12));

      pdf.add(new Canvas([new Line([0, 0], [545, 0]).lineColor('#000').end]).end);
      
      pdf.add(pdf.ln(1));

      let _total = new Table([[new Stack(total).end]]).layout('noBorders').widths(['*']);

      // if(info.cuentas.length<=32){
       
        _total.pageBreak('after')
      // }

      pdf.add(_total.end);

      pdf.add(pdf.ln(4));

      let canvasRight1 = new Canvas([new Line([0, 20], [0, 0]).lineColor('#00284b').lineWidth(2).end]).end

      let tituloPayment = new Txt('Payment Details')
      .margin([0, 0, 0, 5])
      .fontSize(15)
      .color('#00284b')
      .bold().end;

      let tituloPaymentWitBorder = new Table([
        [canvasRight1,tituloPayment]
      ]).widths([5, '*']).layout('noBorders').end;

      pdf.add(tituloPaymentWitBorder);

      pdf.add(pdf.ln(1));

      
        let infoTopBank= new Table([
          [
            new Txt('Payment').bold().end,
            `: ${this.toFixedNumber(valor,2)} ${codigoCurrency}`,
          ],
          [
            new Txt('Advance Request').bold().end,
            `: ${ info.nombreAdvanceReq  || info.advance?.advanceReq?.nombre  || 'No information'}`,
          ],
          [
            new Txt('Advance Payment').bold().end,
            `: ${ this.getPorcentage(valor,info.advance?.porcAdvance) } ${codigoCurrency}`,
          ],
          [
            new Txt('Advance porcentage').bold().end,
            `: ${info.advance.porcAdvance}%`,
          ],
        ])
          .widths([100, '*'])
          .layout('noBorders').end;
      
        canvasRight4= new Canvas([new Line([0, 60], [0, 0]).lineColor('#00284b').lineWidth(2).end]).end

        const AddBorderinfoTopBank = new Table([[canvasRight4, infoTopBank]]).widths([4, 300]).layout('noBorders').end


      pdf.add(
        AddBorderinfoTopBank
      );

      pdf.add(pdf.ln(2));

      let infoBankbottom;
      let AddBorderinfoBottomBank;

      if (banco) {
        if (banco.codigoCurrencyB === 'BTC') {
          let canvasRight2 = new Canvas([new Line([0, 40], [0, 0]).lineColor('#00284b').lineWidth(2).end]).end


          infoBankbottom=new Table([
            [
              new Txt('Account N°').end,
              `: ${emptyPipe.transform(banco.numeroCuentaBanco)}`,
            ],
            [
              new Txt('Email Address').end,
              `: ${emptyPipe.transform(banco.correoBanco)}`,
            ],
          ])
            .widths([80, '*'])
            .layout('noBorders').end;

            AddBorderinfoBottomBank = new Table([[canvasRight2, infoBankbottom]]).widths([4, 300]).layout('noBorders').end

            pdf.add(AddBorderinfoBottomBank);


        } else if(banco.codigoCurrencyB === 'Cash'){

          canvasRight1 = new Canvas([new Line([0, 20], [0, 0]).lineColor('#00284b').lineWidth(2).end]).end


          infoBankbottom=new Table([
            [
              new Txt('Method').end,
              ': Cash',
            ]
          ])
            .widths([50, '*'])
            .layout('noBorders').end;

            AddBorderinfoBottomBank = new Table([[canvasRight1, infoBankbottom]]).widths([4, 300]).layout('noBorders').end

            pdf.add(AddBorderinfoBottomBank);


        } else {
          
         let canvasRight5a = new Canvas([new Line([0, 75], [0, 0]).lineColor('#00284b').lineWidth(2).end]).end

          infoBankbottom= new Table([
              [
                new Txt('Bank name').end,
                `: ${emptyPipe.transform(banco.nombreBanco)}`,
              ],
              [
                new Txt('Account N°').end,
                `: ${emptyPipe.transform(banco.numeroCuentaBanco)}`,
              ],
              [
                new Txt('Beneficiary Name').end,
                `: ${emptyPipe.transform(banco.beneficiarioBanco)}`,
              ],
              [
                new Txt('Taxid').end,
                `: ${emptyPipe.transform(banco.taxIdBanco)}`,
              ],
              [
                new Txt('Email Address').end,
                `: ${emptyPipe.transform(banco.correoBanco)}`,
              ],
            ])
              .widths([100, '*'])
              .layout('noBorders').end
          
          AddBorderinfoBottomBank = new Table([[canvasRight5a, infoBankbottom]]).widths([4, 300]).layout('noBorders').end

          pdf.add(AddBorderinfoBottomBank);


          if (['USD', 'EUR'].includes(banco.codigoCurrencyB)) {
            
            pdf.add(pdf.ln(2));
            
           let canvasRight5b = new Canvas([new Line([0, 105], [0, 0]).lineColor('#00284b').lineWidth(2).end]).end

            let a =  new Table([
                [
                  new Txt('BIC / SWIFT code').end,
                  `: ${emptyPipe.transform(banco.bicBanco)}`,
                ],
                [
                  new Txt('IBAN / ABA routing').end,
                  `: ${emptyPipe.transform(banco.abaBanco)}`,
                ],
                [
                  new Txt('Address 1').end,
                  `: ${emptyPipe.transform(banco.direccionBanco)}`,
                ],
                [
                  new Txt('Address 2').end,
                  `: ${emptyPipe.transform(banco.altDireccionBanco)}`,
                ],
                [
                  new Txt('Country').end,
                  `: ${emptyPipe.transform(banco.nombrePaisBanco)}`,
                ],
                [
                  new Txt('State / Province').end,
                  `: ${emptyPipe.transform(banco.nombreEstadoBanco)}`,
                ],
                [
                  new Txt('City').end,
                  `: ${emptyPipe.transform(banco.ciudadBanco)}`,
                ],
              ])
                .widths([100, '*'])
                .layout('noBorders').end
            
           let AddBorderBick = new Table([[canvasRight5b, a]]).widths([4, 300]).layout('noBorders').end

            pdf.add(AddBorderBick);

            pdf.add(pdf.ln(2));

            let canvasRight5c = new Canvas([new Line([0, 105], [0, 0]).lineColor('#00284b').lineWidth(2).end]).end
            
            let b =  new Table([
                [
                  new Txt('Branch').end,
                  `: ${emptyPipe.transform(banco.branchBanco)}`,
                ],
                [
                  new Txt('Postal code').end,
                  `: ${emptyPipe.transform(banco.codigoPostalBanco)}`,
                ],
                [
                  new Txt('Address 1').end,
                  `: ${emptyPipe.transform(banco.direccionBranchBanco)}`,
                ],
                [
                  new Txt('Address 2').end,
                  `: ${emptyPipe.transform(banco.altDireccionBranchBanco)}`,
                ],
                [
                  new Txt('Country').end,
                  `: ${emptyPipe.transform(banco.nombrePaisBranch)}`,
                ],
                [
                  new Txt('State / Province').end,
                  `: ${emptyPipe.transform(banco.nombreEstadoBranch)}`,
                ],
                [
                  new Txt('City').end,
                  `: ${emptyPipe.transform(banco.ciudadBranchBanco)}`,
                ],
              ])
                .widths([100, '*'])
                .layout('noBorders').end
            
            let AddBorderBrach = new Table([[canvasRight5c, b]]).widths([4, 300]).layout('noBorders').end

            pdf.add(AddBorderBrach);

            pdf.add(pdf.ln(2));

            if (banco.intermediarios) {
              for (const [i, iterator] of banco.intermediarios.entries()) {

                pdf.add(new Txt(`Intermediary ${i + 1}`).fontSize(10).bold().end);

                pdf.add(pdf.ln(1));
                
                let inter=  new Table([
                    [
                      new Txt('Bank name').end,
                      `: ${emptyPipe.transform(iterator.nombreBancoInt)}`,
                    ],
                    [
                      new Txt('Account N°').end,
                      `: ${emptyPipe.transform(iterator.numeroCuentaInt)}`,
                    ],
                    [
                      new Txt('BIC/SWIFT code').end,
                      `: ${emptyPipe.transform(iterator.bicInt)}`,
                    ],
                    [
                      new Txt('Address 1').end,
                      `: ${emptyPipe.transform(iterator.direccionInt)}`,
                    ],
                    [
                      new Txt('Address 2').end,
                      `: ${emptyPipe.transform(iterator.altDireccionInt)}`,
                    ],
                    [
                      new Txt('Country').end,
                      `: ${emptyPipe.transform(iterator.nombrePaisInt)}`,
                    ],
                    [
                      new Txt('State / Province').end,
                      `: ${emptyPipe.transform(iterator.nombreEstadoInt)}`,
                    ],
                    [
                      new Txt('City').end,
                      `: ${emptyPipe.transform(iterator.ciudadInt)}`,
                    ],
                  ])
                    .widths([100, '*'])
                    .layout('noBorders').end
                let canvasRight5d = new Canvas([new Line([0, 105], [0, 0]).lineColor('#00284b').lineWidth(2).end]).end
                AddBorderinfoBottomBank = new Table([[canvasRight5d, inter]]).widths([4, 300]).layout('noBorders').end
                pdf.add(AddBorderinfoBottomBank);
                pdf.add(pdf.ln(1));
              }
            }
          }
        }


      } else {
        pdf.add(new Txt('No bank information').end);
      }

      pdf.add(pdf.ln(2));


      pdf.create().getDataUrl((data) => {
        resolve(data);
      });
    });
  }

  createFileAppointment(empresa, info) {
    // pdf.header('This is a header');
    return new Promise(async (resolve, reject) => {
      
      const pdf = new PdfMakeWrapper();
      const emptyPipe = new checkEmptyPipe();
      const checkFixed = new checkFixedPipe();

      let appo = info.appointment;

      pdf.defaultStyle({
        fontSize: 10,
      });

      pdf.header(this.headerPDF);
      pdf.footer(this.footerPDF);

      // const logo = await new Img(
      //   `${
      //     this.dominioContainer + this.carpetaAvatarLogos + empresa.logoEmpresa
      //   }`
      // )
      //   .fit([150, 50])
      //   .build();
      // pdf.add(logo);
      // pdf.add(pdf.ln(2));

      //Titulo
      pdf.add(
        new Txt('Port agency appointment letter').fontSize(20).bold().end
      );
      pdf.add(pdf.ln(2));
      //Fin

      //To and Subjet
      pdf.add(
        new Table([
          [new Txt('To').bold().end, `: ${appo.owner}`],
          [
            new Txt('Subjet').bold().end,
            `: ${appo.vessel} / ${appo.nombreport} / ${info.usuario ? info.usuario : 'Rithout Reference'
            }`,
          ],
        ])
          .widths([100, '*'])
          .layout('noBorders').end
      );


      pdf.add(pdf.ln(2));
      //Fin

      //Texto
      pdf.add(
        new Canvas([new Line([0, 0], [514, 0]).color('#00284b').end]).end
      );

      let activity = appo.nombreActivity;

      switch (activity.toLowerCase()) {
        case 'discharge':
        case 'loading':
          activity += ` ${checkFixed.transform(appo.CARGO, 2)} MT ${appo.cargoType
            }`;
          break;
      }

      let mensaje = `We, ${appo.owner}, hereby appoint you as ${activity} port agent to attend the port call of the forthcoming subject vessel at the port of ${appo.vessel} with eta ${appo.eta}. Below you will find the main details of the vessel and the cargo as well as some of our main requirements. Please do not hesitate to contact in case any additional information or clarification is needed.`;
      pdf.add(pdf.ln(2));
      pdf.add(new Txt(mensaje).end);

      pdf.add(pdf.ln(2));
      //Fin

      //Vessel Details
      pdf.add(
        new Txt('Vessel details')
          .margin([0, 0, 0, 5])
          .fontSize(15)
          .color('#00284b')
          .bold().end
      );
      pdf.add(
        new Canvas([new Line([0, 0], [514, 0]).color('#00284b').end]).end
      );
      pdf.add(pdf.ln(1));

      //vessel
      pdf.add(new Txt('Vessel').fontSize(10).bold().margin([0, 5]).end);
      pdf.add(
        new Table([
          [new Txt('Name').end, `: ${emptyPipe.transform(appo.vessel)}`],
          // [new Txt('Built').end, `: ${emptyPipe.transform(appo.built)}`],
          // [
          //   new Txt('Classification society').end,
          //   `: ${emptyPipe.transform(appo.classification)}`,
          // ],
        ])
          .widths([100, '*'])
          .layout('noBorders').end
      );
      //fin


      //main detail
      pdf.add(new Txt('Main details').fontSize(10).bold().margin([0, 5]).end);
      pdf.add(
        new Table([
          [
            new Txt('Type').end,
            `: ${emptyPipe.transform(appo.type)}`,
          ],
          [
            new Txt('DWT (MT)').end,
            `: ${checkFixed.transform(appo.dwt, 2)}`,
          ],
          [
            new Txt('LOA (MT)').fontSize(10).end,
            `: ${checkFixed.transform(appo.LOA, 2)}`,
          ],
          // [
          //   new Txt('Draft (MT)').end,
          //   `: ${checkFixed.transform(appo.draft, 2)}`,
          // ],
          [new Txt('GRT(MT)').end, `: ${checkFixed.transform(appo.GRT, 2)}`],
          [new Txt('NRT (MT)').end, `: ${checkFixed.transform(appo.NRT, 2)}`],
          // [
          //   new Txt('Panama GRT / NRT (MT)').end,
          //   `: ${checkFixed.transform(appo.panama, 2)}`,
          // ],
          // [
          //   new Txt('Panama NRT (MT)').end,
          //   `: ${checkFixed.transform(appo.panamaNRT, 2)}`,
          // ],
          // [
          //   new Txt('Suez GRT (MT)').end,
          //   `: ${checkFixed.transform(appo.suez, 2)}`,
          // ],
          // [
          //   new Txt('Suez NRT (MT)').end,
          //   `: ${checkFixed.transform(appo.suezNRT, 2)}`,
          // ],
          // [new Txt('LBP (MT)').end, `: ${checkFixed.transform(appo.lbp, 2)}`],
          // [
          //   new Txt('Molded depth (MT)').end,
          //   `: ${checkFixed.transform(appo.molded, 2)}`,
          // ],
          // [new Txt('Lloyds').end, `: ${emptyPipe.transform(appo.lloyds)}`],
          [new Txt('IMO N°').end, `: ${emptyPipe.transform(appo.imo)}`],
        ])
          .widths([100, '*'])
          .layout('noBorders').end
      );
      //fin

      //Communication
      pdf.add(new Txt('Communication').fontSize(10).bold().margin([0, 5]).end);
      pdf.add(
        new Table([
          [new Txt('Call sign').end, `: ${emptyPipe.transform(appo.call)}`],
          // [new Txt('Telex N° 1').end, `: ${emptyPipe.transform(appo.telex)}`],
          [
            new Txt('Satellite phone 1').end,
            `: ${emptyPipe.transform(appo.satelliteP)}`,
          ],
          // [
          //   new Txt('Satellite fax').end,
          //   `: ${emptyPipe.transform(appo.satelliteF)}`,
          // ],
          [
            new Txt('E-mail').end,
            `: ${emptyPipe.transform(appo.emailComuniString)}`,
          ],
        ])
          .widths([100, '*'])
          .layout('noBorders').end
      );
      //fin

      //CARGO / ACTIVITY DETAILS
      pdf.add(
        new Txt('Cargo / Activity details').fontSize(10).bold().margin([0, 5])
          .end
      );

      // let act=appo.nombreactivity;

      // switch (appo.nombreactivity) {
      //   case 'Loading':
      //   case 'Discharge':
      //     act+=` ${appo.CARGO}(m) ${appo.cargoType}`
      //     break;

      //   default:
      //     break;
      // }

      pdf.add(
        new Table([
          [new Txt('Activity').end, `: ${emptyPipe.transform(activity)}`],
          [
            new Txt('Charterer').end,
            `: ${emptyPipe.transform(appo.charterer)}`,
          ],
        ])
          .widths([100, '*'])
          .layout('noBorders').end
      );
      //fin
      pdf.add(pdf.ln(4));

      //Port call details
      pdf.add(
        new Txt('Port call details')
          .margin([0, 0, 0, 5])
          .fontSize(15)
          .color('#00284b')
          .bold().end
      );
      pdf.add(
        new Canvas([new Line([0, 0], [514, 0]).color('#00284b').end]).end
      );
      pdf.add(pdf.ln(1));

      
      pdf.add(
        new Table([
          [
            new Txt('Country').fontSize(10).end,
            `: ${emptyPipe.transform(appo.country)}`,
          ],
          [
            new Txt('Port').fontSize(10).end,
            `: ${emptyPipe.transform(appo.nombrePort)}`,
          ],
          [
            new Txt('ETA').fontSize(10).end,
            `: ${emptyPipe.transform(appo.eta)}`,
          ],
          [
            new Txt('Voyage N°').fontSize(10).end,
            `: ${emptyPipe.transform(appo.voyage)}`,
          ],
          [
            new Txt('Next port').fontSize(10).end,
            `: ${emptyPipe.transform(appo.nextPort)}`,
          ],
          [
            new Txt('Previous port').fontSize(10).end,
            `: ${emptyPipe.transform(appo.prevPort)}`,
          ],
        ])
          .widths([100, '*'])
          .layout('noBorders').end
      );
      pdf.add(pdf.ln(1));
      //fin

      //CHARTERER DETAILS
      pdf.add(
        new Txt('Charterer details')
          .margin([0, 0, 0, 5])
          .fontSize(15)
          .color('#00284b')
          .bold().end
      );
      pdf.add(
        new Canvas([new Line([0, 0], [514, 0]).color('#00284b').end]).end
      );
      pdf.add(pdf.ln(1));

      pdf.add(
        new Table([
          [
            new Txt('Name').fontSize(10).end,
            `: ${emptyPipe.transform(appo.charterer)}`,
          ],
          [
            new Txt('E-mail').fontSize(10).end,
            `: ${emptyPipe.transform(appo.datosCharters.CEmail)}`,
          ],
        ])
          .widths([100, '*'])
          .layout('noBorders').end
      );
      pdf.add(pdf.ln(1));
      //fin

      //Intruccions
      // pdf.add(
      //   new Txt('Your instructions')
      //     .margin([0, 0, 0, 5])
      //     .fontSize(15)
      //     .color('#00284b')
      //     .bold().end
      // );
      // pdf.add(
      //   new Canvas([new Line([0, 0], [514, 0]).color('#00284b').end]).end
      // );
      // pdf.add(pdf.ln(1));

      // let intruStack = [];
      // for (const iterator of appo.instructions) {
      //   const a = [];
   
      //   a.push(new Txt(iterator.tituloInstruccion).end);
      //   a.push(new Txt(iterator.textoInstruccion).end);
      //   const colum = new Table([a]).widths([100, '*']).layout('noBorders').end;
      //   intruStack.push(colum);
      // }
      // if (intruStack.length > 0) {
      //   pdf.add(new Stack(intruStack).end);
      // } else {
      //   pdf.add(new Txt('No instructions set').end);
      // }

      pdf.add(pdf.ln(1));
      //fin

      let filePDF: any;
      pdf.create().getDataUrl((data) => {
        resolve(data);
      });
    });
  }

  createFileSDA(info) {

    return new Promise(async (resolve, reject) => {
    const codigoCurrency = info.codigoCurrencyB;

    const pdf = new PdfMakeWrapper();
    const emptyPipe = new checkEmptyPipe();
    
    pdf.defaultStyle({
        fontSize: 8,
    });

    pdf.pageMargins([ 24, 24, 24, 24 ]);

    pdf.header(this.headerPDF);
    pdf.footer(this.footerPDF);

    

    let nombreEmpresa = info.nombreEmpresa || info.empresa.nombreEmpresa;
    
    const logo = await new Img(`${this.dominioContainer + this.carpetaAvatarLogos + info.logoEmpresa}`)
    .fit([150, 50]).alignment('right').build();

    let textTitulo=  new Txt('ADITIONAL SERVICES').fontSize(20).bold().end
    const textsub=  new Txt(`${info.titulo}`).fontSize(16).bold().end

    let canvasRightGruesa = new Canvas([new Line([0, 60], [0, 0]).lineColor('#00284b').lineWidth(15).end]).end
    let canvasRight3 = new Canvas([new Line([0, 47], [0, 0]).lineColor('#00284b').lineWidth(2).end]).end
    let canvasRight4 = new Canvas([new Line([0, 60], [0, 0]).lineColor('#00284b').lineWidth(2).end]).end

    let titulo = new Table([[canvasRightGruesa,new Stack([textTitulo, textsub]).end]]).widths([10, 300]).layout('noBorders').end;

    pdf.add(new Table([[titulo, logo]]).layout('noBorders').widths(['50%', '50%']).end);
    pdf.add(pdf.ln(1));

    let m = [
      [new Txt('To').bold().end, `: ${info.company}`],
      [new Txt('From').bold().end, `: ${nombreEmpresa}`]];

      if(info.idAppointment){
        m.push([
            new Txt('Subjet').bold().end,
            `: ${info.vessel} / ${ info.nombrePuerto} / ${info.usuario ? info.usuario : 'Without Reference'}`,
          ])
      }

    let membrete = new Table(m)
      .widths([40, '*'])
      .layout('noBorders').end

    pdf.add(
      new Table([[canvasRight3, membrete]]).widths([4, 300]).layout('noBorders').end
    );

    pdf.add(pdf.ln(1));

    if(info.idAppointment){
      let tituloPortCall = new Txt('Port of Call Details')
        .margin([0, 0, 0, 5])
        .fontSize(10)
        .bold().end;
       
      let infoPortCall = new Table([
        [new Txt('Port').bold().end, `: ${info.nombreZona ||info.callData.zona.nombre} / ${ info.nombrePuerto || info.callData.nombrePort||info.callData.port.nombrePuerto}`],
        [new Txt('Activity').bold().end, `: ${info.nombreActivity}`],
        [new Txt('ATA').bold().end, `: ${ info.ata.date? formatDate(info.ata.date,'MMM d, y','en-US') : 'No information'}`],
        [new Txt('ATD').bold().end, `: ${info.atd.date? formatDate(info.atd.date,'MMM d, y','en-US') : 'No information'}`],
      ])
        .widths([40, '*'])
        .layout('noBorders').end;
  
      const AddBorderInfoPortCall = new Table([[canvasRight4, infoPortCall]]).widths([4, 300]).layout('noBorders').end
  
      let portCall = new Stack([tituloPortCall, AddBorderInfoPortCall]).end;
  
      let portStay = 'No information';
  
  
  
      if(info.ata.date && info.atd.date){
        const  timeDiff = Math.abs((new Date(info.atd.date).getTime()) - (new Date(info.ata.date).getTime()));
        const days= Math.ceil(timeDiff / (1000 * 3600 * 24));
        portStay = `${(days)==0?1:days} Days`;
      }
  
  
      let tituloOurPDA = new Txt('Our FDA Basis')
        .margin([0, 0, 0, 5])
        .fontSize(10)
        .bold().end;
        
       let invoiceDate= new Date().toLocaleDateString('en-US');
  
      let infoOurPDA = new Table([
        [new Txt('Days at Port').bold().end, `: ${portStay}`],
        [
          new Txt('Invoice Date').bold().end,
          `: ${ invoiceDate ? (formatDate(invoiceDate,'MMM d, y','en-US')) : 'No information'}`,
        ],
        [new Txt("Due Date").bold().end, `: ${info.overdueDate?formatDate(info.overdueDate,'MMM d, y','en-US'): 'No information'}`],
        [new Txt("FDA Currency").bold().end, `: ${info.codigoCurrencyB}`],
      ])
      .widths([60, '*'])
      .layout('noBorders').end;
  
      canvasRight3 = new Canvas([new Line([0, 47], [0, 0]).lineColor('#00284b').lineWidth(2).end]).end
  
      const AddBorderInfoOurPDA = new Table([[canvasRight3, infoOurPDA]]).widths([4, 300]).layout('noBorders').end
  
  
      let OurPDA = new Stack([tituloOurPDA, AddBorderInfoOurPDA]).end;
  
      pdf.add(
        new Table([[portCall, OurPDA]]).widths(['*', '*']).layout('noBorders')
          .end
      );
  
      pdf.add(pdf.ln(1));
    }

    const ALL = this.createRowCuentasSDA(info.currency,info.cuentas);

   
    let cuentas =[];

    let headerTable=[];
    headerTable.push(new Txt('CODE').bold().end);
    headerTable.push(new Txt('PORT CHARGES').bold().end);
    headerTable.push(new Txt('CURRENCY EXCHANGE').bold().end);
    headerTable.push(new Txt('AMOUNT EXCHANGE').bold().end);
    headerTable.push(new Txt('ROE EXCHANGE').bold().end);
    headerTable.push(new Cell(new Txt(`AMOUNT (${codigoCurrency})`).bold().alignment('right').end).fillColor('#ebf8ff').end);
    
    const header = new Table([headerTable]).layout({
      hLineColor:()=>'#efefef',
      vLineColor:()=>'#efefef',
      hLineWidth:()=>0.1,
      vLineWidth:()=>0.1
    }).widths(['5%','30%', '17%','18%','15%','15%']).end;

    cuentas.push(header);
    cuentas.push(ALL.cuentas);

      
    pdf.add(
        new Table([
            [new Stack(cuentas).end]
        ]).widths(['*']).layout({
          hLineWidth:()=>0.1,
          vLineWidth:()=>0.1,
          hLineColor:()=>'#efefef',
          vLineColor:()=>'#efefef',
          paddingLeft:()=>0,
          paddingRight:()=>0,
          paddingTop:()=>0,
          paddingBottom:()=>0}).end
    );

    let canvasRightGruesa2 = new Canvas([new Line([10, 5], [0, 5]).lineColor('#00284b').lineWidth(12).end]).end
    let textTotal =new Txt(`TOTAL FINAL DA`).alignment('right').color('#00284b').fontSize(10).bold().end;

    let textTotalWhitBorder = new Table([
      [canvasRightGruesa2,textTotal]
    ]).widths([10, '*']).layout('noBorders').end;

    let rowTotal = [];

    rowTotal.push(textTotalWhitBorder);
    rowTotal.push(new Txt(`${this.toFixedNumber(ALL.total,2)}`).color('#00284b').fontSize(14).bold().alignment('right').end);
      
    let _total = new Table([rowTotal]).layout({
      fillColor:()=>'#ebf8ff',
      hLineColor:()=>'#efefef',
      vLineColor:()=>'#efefef',
      hLineWidth:()=>0.1,
      vLineWidth:()=>0.1
    }).widths(['85%', '15%']);

    pdf.add(_total.end);

    _total.pageBreak('after')

    
    //2da Pagina

    // textTitulo =  new Txt('FINAL DISBURSEMENT ACCOUNT COMMENTS').fontSize(20).bold().end

    // canvasRightGruesa = new Canvas([new Line([0, 60], [0, 0]).lineColor('#00284b').lineWidth(15).end]).end
    // canvasRight3 = new Canvas([new Line([0, 47], [0, 0]).lineColor('#00284b').lineWidth(2).end]).end
    // canvasRight4 = new Canvas([new Line([0, 60], [0, 0]).lineColor('#00284b').lineWidth(2).end]).end

    // titulo = new Table([[canvasRightGruesa,textTitulo]]).widths([10, 300]).layout('noBorders').end;

    // pdf.add(new Table([[titulo, logo]]).layout('noBorders').widths(['50%', '50%']).end);
    // pdf.add(pdf.ln(1));

    // membrete = new Table([
    //   [new Txt('To').bold().end, `: ${info.multiReceiver?info.receivers.map(x=>x.company).join(' / '):info.x_responsable}`],
    //   [new Txt('From').bold().end, `: ${nombreEmpresa}`],
    //   [
    //     new Txt('Subjet').bold().end,
    //     `: ${info.vessel} / ${ info.nombrePuerto} / ${info.usuario ? info.usuario : 'Without Reference'}`,
    //   ],
    // ])
    //   .widths([40, '*'])
    //   .layout('noBorders').end

    // pdf.add(
    //   new Table([[canvasRight3, membrete]]).widths([4, 300]).layout('noBorders').end
    // );

    // pdf.add(pdf.ln(1));

    // const ALLComments = this.createRowCuentasComments(info.allCuentas,info.codigoCurrencyB, info.receiverSelect);

    // cuentas =[];

    // let headerComments=[];
    // headerComments.push(new Txt('CODE').bold().alignment('center').end);
    // headerComments.push(new Txt('PORT CHARGES').alignment('center').bold().end);
    // headerComments.push(new Txt('PDA').bold().alignment('center').end);
    // headerComments.push(new Txt('FDA').bold().alignment('center').end);
    // headerComments.push(new Txt('DIFFERENCES').alignment('center').bold().end);
    // let  widths= ['5%','25%', '10%','10%','13%','37%']

    // if( hasItemAdjustment){
    //   widths =['5%','25%', '8%','8%','8%','8%','38%'];
    //   headerComments.push(new Txt('DISCOUNT').alignment('center').bold().end);
    // }

    

    // headerComments.push(new Txt('COMMENTS').bold().alignment('left').end);
    
    // const headerComents = new Table([headerComments]).layout({
    //   hLineColor:()=>'#efefef',
    //   vLineColor:()=>'#efefef',
    //   hLineWidth:()=>0.1,
    //   vLineWidth:()=>0.1
    // }).widths(widths).end;

    // cuentas.push(headerComents);
    // cuentas.push(ALLComments);

    
    pdf.add(
        new Table([
            [new Stack(cuentas).end]
        ]).widths(['*']).layout({
          hLineWidth:()=>0.1,
          vLineWidth:()=>0.1,
          hLineColor:()=>'#efefef',
          vLineColor:()=>'#efefef',
          paddingLeft:()=>0,
          paddingRight:()=>0,
          paddingTop:()=>0,
          paddingBottom:()=>0}).end
    );

    pdf.create().getDataUrl((data) => {
      resolve(data);
    });

    });
  }

  createFileFDA(info) {

    return new Promise(async (resolve, reject) => {
    const codigoCurrency = info.codigoCurrencyB;

    const pdf = new PdfMakeWrapper();
    const emptyPipe = new checkEmptyPipe();
    
    pdf.defaultStyle({
        fontSize: 8,
    });

    pdf.pageMargins([ 24, 24, 24, 24 ]);

    pdf.header(this.headerPDF);
    pdf.footer(this.footerPDF);

    const logo = await new Img(`${this.dominioContainer + this.carpetaAvatarLogos + info.logoEmpresa}`)
    .fit([150, 50]).alignment('right').build();

    let nombreEmpresa = info.nombreEmpresa || info.empresa.nombreEmpresa;
    


    let textTitulo=  new Txt('FINAL DISBURSEMENT ACCOUNT').fontSize(20).bold().end

    let canvasRightGruesa = new Canvas([new Line([0, 60], [0, 0]).lineColor('#00284b').lineWidth(15).end]).end
    let canvasRight3 = new Canvas([new Line([0, 47], [0, 0]).lineColor('#00284b').lineWidth(2).end]).end
    let canvasRight4 = new Canvas([new Line([0, 60], [0, 0]).lineColor('#00284b').lineWidth(2).end]).end

    let titulo = new Table([[canvasRightGruesa,textTitulo]]).widths([10, 300]).layout('noBorders').end;

    pdf.add(new Table([[titulo, logo]]).layout('noBorders').widths(['50%', '50%']).end);
    pdf.add(pdf.ln(1));

    let membrete = new Table([
      [new Txt('To').bold().end, `: ${ info.receiverSelect?info.receiverSelect.company :info.x_responsable}`],
      [new Txt('From').bold().end, `: ${nombreEmpresa}`],
      [
        new Txt('Subjet').bold().end,
        `: ${info.vessel} / ${ info.nombrePuerto} / ${info.usuario ? info.usuario : 'Without Reference'}`,
      ],
    ])
      .widths([40, '*'])
      .layout('noBorders').end

    pdf.add(
      new Table([[canvasRight3, membrete]]).widths([4, 300]).layout('noBorders').end
    );

    pdf.add(pdf.ln(1));

    let tituloPortCall = new Txt('Port of Call Details')
      .margin([0, 0, 0, 5])
      .fontSize(10)
      .bold().end;
     
    let infoPortCall = new Table([
      [new Txt('Port').bold().end, `: ${info.nombreZona ||info.callData.zona.nombre} / ${ info.nombrePuerto || info.callData.nombrePort||info.callData.port.nombrePuerto}`],
      [new Txt('Activity').bold().end, `: ${info.nombreActivity}`],
      [new Txt('ATA').bold().end, `: ${ info.ata.date? formatDate(info.ata.date,'MMM d, y','en-US') : 'No information'}`],
      [new Txt('ATD').bold().end, `: ${info.atd.date? formatDate(info.atd.date,'MMM d, y','en-US') : 'No information'}`],
    ])
      .widths([40, '*'])
      .layout('noBorders').end;

    const AddBorderInfoPortCall = new Table([[canvasRight4, infoPortCall]]).widths([4, 300]).layout('noBorders').end

    let portCall = new Stack([tituloPortCall, AddBorderInfoPortCall]).end;

    let portStay = 'No information';
    // if (info.daysPS !=='0'|| info.hoursPS !=='0') {
    //     portStay = info.daysPS + ' Days ';
    //     portStay += info.hoursPS + ' Hours';

    // } else {
    //   portStay = 'Add Port Stay';
    // }

    if(info.ata.date && info.atd.date){
      const  timeDiff = Math.abs((new Date(info.atd.date).getTime()) - (new Date(info.ata.date).getTime()));
      const days= Math.ceil(timeDiff / (1000 * 3600 * 24));
      portStay = `${(days)==0?1:days} Days`;
    }


    let tituloOurPDA = new Txt('Our FDA Basis')
      .margin([0, 0, 0, 5])
      .fontSize(10)
      .bold().end;
      
     let invoiceDate= new Date().toLocaleDateString('en-US');

    let infoOurPDA = new Table([
      [new Txt('Days at Port').bold().end, `: ${portStay}`],
      [
        new Txt('Invoice Date').bold().end,
        `: ${ invoiceDate ? (formatDate(invoiceDate,'MMM d, y','en-US')) : 'No information'}`,
      ],
      [new Txt("Due Date").bold().end, `: ${info.overdueDate?formatDate(info.overdueDate,'MMM d, y','en-US'): 'No information'}`],
      [new Txt("FDA Currency").bold().end, `: ${info.codigoCurrencyB}`],
    ])
    .widths([60, '*'])
    .layout('noBorders').end;

    canvasRight3 = new Canvas([new Line([0, 47], [0, 0]).lineColor('#00284b').lineWidth(2).end]).end

    const AddBorderInfoOurPDA = new Table([[canvasRight3, infoOurPDA]]).widths([4, 300]).layout('noBorders').end


    let OurPDA = new Stack([tituloOurPDA, AddBorderInfoOurPDA]).end;

    pdf.add(
      new Table([[portCall, OurPDA]]).widths(['*', '*']).layout('noBorders')
        .end
    );

    pdf.add(pdf.ln(1));

    const ALL = this.createRowCuentasFDA(info.codigoCurrencyB,info.allCuentas, info.receiverSelect);

   
    let cuentas =[];

    let headerTable=[];
    headerTable.push(new Txt('CODE').bold().end);
    headerTable.push(new Txt('PORT CHARGES').bold().end);
    headerTable.push(new Txt('CURRENCY EXCHANGE').bold().end);
    headerTable.push(new Txt('AMOUNT EXCHANGE').bold().end);
    headerTable.push(new Txt('ROE EXCHANGE').bold().end);
    headerTable.push(new Cell(new Txt(`AMOUNT (${codigoCurrency})`).bold().alignment('right').end).fillColor('#ebf8ff').end);
    
    const header = new Table([headerTable]).layout({
      hLineColor:()=>'#efefef',
      vLineColor:()=>'#efefef',
      hLineWidth:()=>0.1,
      vLineWidth:()=>0.1
    }).widths(['5%','30%', '17%','18%','15%','15%']).end;

    cuentas.push(header);
    cuentas.push(ALL.cuentas);

      
    pdf.add(
        new Table([
            [new Stack(cuentas).end]
        ]).widths(['*']).layout({
          hLineWidth:()=>0.1,
          vLineWidth:()=>0.1,
          hLineColor:()=>'#efefef',
          vLineColor:()=>'#efefef',
          paddingLeft:()=>0,
          paddingRight:()=>0,
          paddingTop:()=>0,
          paddingBottom:()=>0}).end
    );

    let canvasRightGruesa2 = new Canvas([new Line([10, 5], [0, 5]).lineColor('#00284b').lineWidth(12).end]).end
    let textTotal =new Txt(`TOTAL FINAL DA`).alignment('right').color('#00284b').fontSize(10).bold().end;

    let textTotalWhitBorder = new Table([
      [canvasRightGruesa2,textTotal]
    ]).widths([10, '*']).layout('noBorders').end;

    let rowTotal = [];

    rowTotal.push(textTotalWhitBorder);
    rowTotal.push(new Txt(`${this.toFixedNumber(ALL.total,2)}`).color('#00284b').fontSize(14).bold().alignment('right').end);
      
    let _total = new Table([rowTotal]).layout({
      fillColor:()=>'#ebf8ff',
      hLineColor:()=>'#efefef',
      vLineColor:()=>'#efefef',
      hLineWidth:()=>0.1,
      vLineWidth:()=>0.1
    }).widths(['85%', '15%']);

    pdf.add(_total.end);

    canvasRightGruesa2 = new Canvas([new Line([10, 5], [0, 5]).lineColor('#00284b').lineWidth(12).end]).end
    let textRemitance =new Txt(`REMITANCE`).alignment('right').color('#00284b').fontSize(10).bold().end;

    let textRemitanceWhitBorder = new Table([
      [canvasRightGruesa2,textRemitance]
    ]).widths([10, '*']).layout('noBorders').end;

    // const Remitance = info.totalAdvanceFda;

    const Remitance = this.toFixedNumber( info.receiverSelect && info.receiverSelect.idLocal!=0 ? info.receiverSelect.remitance : Number(info.totalPagosInFDA) - Number(info.totalPagosOutFDA),this.fixADM);

    let rowRemitance = [];
    
    rowRemitance.push(textRemitanceWhitBorder);
    rowRemitance.push(new Txt(`${Remitance}`).color('#00284b').fontSize(14).bold().alignment('right').end);

    
    let _Remitance = new Table([rowRemitance]).layout({
      fillColor:()=>'#ebf8ff',
      hLineColor:()=>'#efefef',
      vLineColor:()=>'#efefef',
      hLineWidth:()=>0.1,
      vLineWidth:()=>0.1
    }).widths(['85%', '15%']);

    pdf.add(_Remitance.end);

    let hasItemAdjustment= false;
    //Suma de Ajustments
    if(info.statusInvoices===4){

      let sumAdjustment:any = Number(info.adjustment);

      sumAdjustment += info.allCuentas.reduce((acumulador, actual) => {
        
        hasItemAdjustment=true;
        return acumulador +   Number(actual.adjustment); 
      }, 0);

      // sumAdjustment += info.allCuentas.reduce((acumulador, actual) => acumulador + (!this.isEmptyOrNull(actual.adjustment)? (Number(actual.valorFda) - Number(actual.adjustment)):0), 0);


      sumAdjustment = this.toFixedNumber(sumAdjustment,2);
      let rowAdjustment = [];
      
      canvasRightGruesa2 = new Canvas([new Line([10, 5], [0, 5]).lineColor('#00284b').lineWidth(12).end]).end
      let textAdjustment =new Txt(`DISCOUNT`).alignment('right').color('#00284b').fontSize(10).bold().end;

      let textAdjustmentWhitBorder = new Table([
        [canvasRightGruesa2,textAdjustment]
      ]).widths([10, '*']).layout('noBorders').end;

      rowAdjustment.push(textAdjustmentWhitBorder);
      rowAdjustment.push(new Txt(`${sumAdjustment}`).color('#00284b').fontSize(14).bold().alignment('right').end);

      
      let _Adjustment = new Table([rowAdjustment]).layout({
        fillColor:()=>'#ebf8ff',
        hLineColor:()=>'#efefef',
        vLineColor:()=>'#efefef',
        hLineWidth:()=>0.1,
        vLineWidth:()=>0.1
      }).widths(['85%', '15%']);

      pdf.add(_Adjustment.end);
    }

    canvasRightGruesa2 = new Canvas([new Line([10, 5], [0, 5]).lineColor('#00284b').lineWidth(12).end]).end
    let textBalance =new Txt(`BALANCE`).alignment('right').color('#00284b').fontSize(10).bold().end;

    let textBalanceWhitBorder = new Table([
      [canvasRightGruesa2,textBalance]
    ]).widths([10, '*']).layout('noBorders').end;

    
    // const Balance = (Number(totalFda) - Number(Remitance)).toFixed(this.fixADM);

    const Balance = info.receiverSelect && info.receiverSelect.idLocal!=0  ?this.toFixedNumber( Number(ALL.total) - Number(info.receiverSelect.remitance),2): info.totalBalanceFda;
    let rowBalance = [];
    
    rowBalance.push(textBalanceWhitBorder);
    rowBalance.push(new Txt(`${Balance}`).color('#00284b').fontSize(14).bold().alignment('right').end);

    
    let _Balance= new Table([rowBalance]).layout({
      fillColor:()=>'#ebf8ff',
      hLineColor:()=>'#efefef',
      vLineColor:()=>'#efefef',
      hLineWidth:()=>0.1,
      vLineWidth:()=>0.1
    }).widths(['85%', '15%']);

    pdf.add(_Balance.end);

    _Balance.pageBreak('after')

    
    // 2da Pagina

    textTitulo =  new Txt('FINAL DISBURSEMENT ACCOUNT COMMENTS').fontSize(20).bold().end

    canvasRightGruesa = new Canvas([new Line([0, 60], [0, 0]).lineColor('#00284b').lineWidth(15).end]).end
    canvasRight3 = new Canvas([new Line([0, 47], [0, 0]).lineColor('#00284b').lineWidth(2).end]).end
    canvasRight4 = new Canvas([new Line([0, 60], [0, 0]).lineColor('#00284b').lineWidth(2).end]).end

    titulo = new Table([[canvasRightGruesa,textTitulo]]).widths([10, 300]).layout('noBorders').end;

    pdf.add(new Table([[titulo, logo]]).layout('noBorders').widths(['50%', '50%']).end);
    pdf.add(pdf.ln(1));

    membrete = new Table([
      [new Txt('To').bold().end, `: ${info.multiReceiver?info.receivers.map(x=>x.company).join(' / '):info.x_responsable}`],
      [new Txt('From').bold().end, `: ${nombreEmpresa}`],
      [
        new Txt('Subjet').bold().end,
        `: ${info.vessel} / ${ info.nombrePuerto} / ${info.usuario ? info.usuario : 'Without Reference'}`,
      ],
    ])
      .widths([40, '*'])
      .layout('noBorders').end

    pdf.add(
      new Table([[canvasRight3, membrete]]).widths([4, 300]).layout('noBorders').end
    );

    pdf.add(pdf.ln(1));

    const ALLComments = this.createRowCuentasComments(info.allCuentas,info.codigoCurrencyB, info.receiverSelect);

    cuentas =[];

    let headerComments=[];
    headerComments.push(new Txt('CODE').bold().alignment('center').end);
    headerComments.push(new Txt('PORT CHARGES').alignment('center').bold().end);
    headerComments.push(new Txt('PDA').bold().alignment('center').end);
    headerComments.push(new Txt('FDA').bold().alignment('center').end);
    headerComments.push(new Txt('DIFFERENCES').alignment('center').bold().end);
    let  widths= ['5%','25%', '10%','10%','13%','37%']
    // if( hasItemAdjustment){
    //   widths =['5%','25%', '8%','8%','8%','8%','38%'];
    //   headerComments.push(new Txt('DISCOUNT').alignment('center').bold().end);
    // }

    

    headerComments.push(new Txt('COMMENTS').bold().alignment('left').end);
    
    const headerComents = new Table([headerComments]).layout({
      hLineColor:()=>'#efefef',
      vLineColor:()=>'#efefef',
      hLineWidth:()=>0.1,
      vLineWidth:()=>0.1
    }).widths(widths).end;

    cuentas.push(headerComents);
    cuentas.push(ALLComments);

    
    pdf.add(
        new Table([
            [new Stack(cuentas).end]
        ]).widths(['*']).layout({
          hLineWidth:()=>0.1,
          vLineWidth:()=>0.1,
          hLineColor:()=>'#efefef',
          vLineColor:()=>'#efefef',
          paddingLeft:()=>0,
          paddingRight:()=>0,
          paddingTop:()=>0,
          paddingBottom:()=>0}).end
    );

    pdf.create().getDataUrl((data) => {
      resolve(data);
    });

    });
  }

  createFileProgress(info) {
    return new Promise(async (resolve, reject) => {

    const pdf = new PdfMakeWrapper();
    const emptyPipe = new checkEmptyPipe();
    
    
    pdf.defaultStyle({
        fontSize: 9,
    });

    pdf.pageMargins([ 24, 24, 24, 24 ]);

    this.headerPDF = new Columns([
      await new Img('./assets/img/headerFiles2.png').width(600).height(35).build()
    ]).end;
  
    pdf.header(this.headerPDF);
    pdf.footer(this.footerPDF);

    let logoEmpresa = info.logoEmpresa ||  info.empresa.logoEmpresa;

    const logo = await new Img(`${this.dominioContainer + this.carpetaAvatarLogos + logoEmpresa}`)
    .fit([150, 50]).alignment('right').build();

    let vessel=  new Txt(`Mv. ${info.vessel}`).lineHeight(2).decoration('underline').fontSize(22).bold().end
    let titulo=  new Txt('CARGO OPERATION REPORT').margin([0,-10,0,0]).fontSize(16).bold().end

    let topLeftText= new Stack([vessel, titulo]).margin([0,-20]).end



    // let topLeft = new Table([[canvasRightGruesa,topLeftText]]).widths([10, 300]).layout('noBorders').end;

    // pdf.add(new Table([[topLeftText, logo]]).layout('noBorders').widths(['50%', '50%']).end);
    pdf.add(logo);
    pdf.add(topLeftText);
    pdf.add(pdf.ln(4));

    /* Fin */

    

    let tituloPortSumary = new Txt('Port Summary')
    .margin([0, 0, 0, 5])
    .fontSize(12)
    .bold().end;

    let portSumaryText = new Table([
      [new Txt('Port Region').bold().end, `: ${info.nombreZona}, ${info.nombrePaisPuerto}`],
      [new Txt('Berth N°').bold().end, `: ${info.nombrePuerto}, ${info.berth}`],
      [new Txt('Load Port').bold().end, `: ${info.prevPort}`],
      [new Txt('Cargo').bold().end, `: ${ this.toFixedNumber(info.CARGO,3)}MT ${this.getBLCargosUniques(info.portHub.BL).map(x=>x.name).join('/')}`],
    ])
      .widths([70, '*'])
      .layout({
        hLineColor:()=>'#fff',
        vLineColor:()=>'#00405c',
        vLineWidth: 
        function (i, node) {
          return (i === 0 ) ? 1.5 : 0;
        },
      }).end;

      let portSumary = new Stack([tituloPortSumary,portSumaryText]).end;



    // /* Fin */

    let tituloPortStay = new Txt('Port Stay Overview')
    .margin([0, 0, 0, 5])
    .fontSize(12)
    .bold().end;

    let statusTypeDate = new VesselStatusTypeDatePipe()

    let tituloEta= statusTypeDate.transform(info.portHub.vesselStatus.ata,'tipo','ETA:','Arrived:');
    let tituloEtb= statusTypeDate.transform(info.portHub.vesselStatus.atb,'tipo','ETB:','Berthed:');
    let tituloEtos= statusTypeDate.transform(info.portHub.vesselStatus.ats,'tipo','ETOS:','Ops Started:');
    let tituloEtc= statusTypeDate.transform(info.portHub.vesselStatus.atc,'tipo','ETC:','Completed:');
    let tituloEtd= statusTypeDate.transform(info.portHub.vesselStatus.atd,'tipo','ETD:','Departure:');

    let timeAta= info.portHub.vesselStatus.ata !==''?info.portHub.vesselStatus.ata.horaMilitar? formatDate(info.portHub.vesselStatus.ata.time24,'HH:mm','en-US'): info.portHub.vesselStatus.ata.time12.code:'';
    let timeAtb= info.portHub.vesselStatus.atb !==''?info.portHub.vesselStatus.atb.horaMilitar? formatDate(info.portHub.vesselStatus.atb.time24,'HH:mm','en-US'): info.portHub.vesselStatus.atb.time12.code:'';
    let timeAtos= info.portHub.vesselStatus.ats !==''?info.portHub.vesselStatus.ats.horaMilitar? formatDate(info.portHub.vesselStatus.ats.time24,'HH:mm','en-US'): info.portHub.vesselStatus.ats.time12.code:'';
    let timeAtc= info.portHub.vesselStatus.atc !==''?info.portHub.vesselStatus.atc.horaMilitar? formatDate(info.portHub.vesselStatus.atc.time24,'HH:mm','en-US'): info.portHub.vesselStatus.atc.time12.code:'';
    let timeAtd= info.portHub.vesselStatus.atd !==''?info.portHub.vesselStatus.atd.horaMilitar? formatDate(info.portHub.vesselStatus.atd.time24,'HH:mm','en-US'): info.portHub.vesselStatus.atd.time12.code:'';


    let statusViewDate = new VesselStatusViewDatePipe();
    let infoPortStay = new Table([
      [new Txt(tituloEta).bold().end, `: ${ formatDate(statusViewDate.transform(info.eta,info.portHub.vesselStatus.ata.date),'MMM d, y','en-US') + ' ' + timeAta  || 'No information'}`],
      [new Txt(tituloEtb).bold().end, `: ${formatDate(statusViewDate.transform(info.etb,info.portHub.vesselStatus.atb.date) ,'MMM d, y','en-US') + ' ' + timeAtb  || 'No information'}`],
      [new Txt(tituloEtos).bold().end, `: ${formatDate(statusViewDate.transform(info.ets,info.portHub.vesselStatus.ats.date),'MMM d, y','en-US') + ' ' + timeAtos || 'No information'}`],
      [new Txt('Nor Tendered').bold().end, `: ${ info.portHub.vesselStatus.norTendered !==''? info.portHub.vesselStatus.norTendered :   'No information'}`],
      [new Txt('Nor Accepted').bold().end, `: ${info.portHub.vesselStatus.norAccepted !==''? info.portHub.vesselStatus.norAccepted :   'No information'}`],
    ])
      .widths([70, '*'])
      .layout({
        hLineColor:()=>'#fff',
        vLineColor:()=>'#00405c',
        vLineWidth: 
        function (i, node) {
          return (i === 0 ) ? 1.5 : 0;
        }
      }).end

    let portStay = new Stack([tituloPortStay,infoPortStay]).end;

    pdf.add(
      new Table([[portSumary,portStay]]).widths(['*', '*']).layout('noBorders')
        .end
    );

    /*Fin*/
    
    pdf.add(pdf.ln(4));
    
    pdf.add(
      new Txt(`Productivity Report - ${formatDate(info.report.dateReport,'MMM d, y','en-US')} / ${formatDate(info.report.timeReport,'HH:mm','en-US')}hrs.`)
        .margin([0, 0, 0, 5])
        .fontSize(16)
        .color('#000')
        .bold().end
    );
    pdf.add(
      new Canvas([new Line([0, 0], [545, 0]).color('#003f5c').end]).end
    );

    pdf.add(pdf.ln(2));


    let remain= Number(info.CARGO) - Number(info.report.qty);

    let leyendaTopTotal = new Stack([
      new Table([[new Canvas([new Ellipse([3, 5], 5).color('#003f5c').end]).end,new Txt('Total Discharge').bold().end]]).widths(['auto', '*']).layout('noBorders').end,
      new Txt(`${this.toFixedNumber(info.report.qty,3)}`).margin([4,0,0,0]).fontSize(16).bold().end
    ]).end;

    let leyendaBottomTotal = new Stack([
      new Table([[new Canvas([new Ellipse([3, 5], 5).color('#beecfc').end]).end,new Txt('Remain to go').bold().end]]).widths(['auto', '*']).layout('noBorders').end,
      new Txt(`${this.toFixedNumber(remain,3)}`).margin([4,0,0,0]).fontSize(16).bold().end
    ]).margin([0,6]).end

    let leyendaTopRate = new Stack([
      new Table([[new Canvas([new Ellipse([3, 5], 5).color('#003f5c').end]).end,new Txt('Total Discharge').bold().end]]).widths(['auto', '*']).layout('noBorders').end,
      new Txt(`${this.toFixedNumber(info.report.qty,3)}`).margin([4,0,0,0]).fontSize(16).bold().end
    ]).margin([0,0]).end;

    let leyendaBottomRate = new Stack([
      new Table([[new Canvas([new Ellipse([3, 5], 5).color('#beecfc').end]).end,new Txt('Remain to go').bold().end]]).widths(['auto', '*']).layout('noBorders').end,
      new Txt(`${this.toFixedNumber(remain,3)}`).margin([4,0,0,0]).fontSize(16).bold().end
    ]).margin([0,6]).end

    let leyendaDeparture = new Stack([
      new Table([[new Canvas([new Ellipse([3, 5], 5).color('#003f5c').end]).end,new Txt(`Estimate`).bold().end]]).widths(['auto', '*']).layout('noBorders').end,
      new Txt(`${formatDate(statusViewDate.transform(info.etc,info.portHub.vesselStatus.atc.date),'MMM d, y','en-US')}`).fontSize(16).bold().end,
      new Txt(`${timeAtc + ' ETC'}`).fontSize(16).bold().end,
      new Txt(`${formatDate(statusViewDate.transform(info.etd,info.portHub.vesselStatus.atd.date),'MMM d, y','en-US')}`).margin([0,20,0,0]).fontSize(16).bold().end,
      new Txt(`${timeAtd + ' ETD'}`).fontSize(16).bold().end,
    ]).end;


    let a = new Table([
      [leyendaTopTotal,leyendaTopRate],
      [await new Img(info.totalDischargePie).fit([80,80]).build(), await new Img(info.RateChart).margin([0,20]).fit([80,80]).build()],
      [leyendaBottomTotal,leyendaBottomRate],

    ]).layout('noBorders').widths(['*', '*'])
    .end

    pdf.add(
      new Table([
        [a,leyendaDeparture]
      ]).layout('noBorders').widths(['*', '150'])
      .end
    );

    pdf.add(pdf.ln(2));

    pdf.add(
      new Txt(`Brackdown by Cargo Type`)
        .margin([0, 0, 0, 5])
        .fontSize(16)
        .color('#000')
        .bold().end
    );

    pdf.add(
      new Canvas([new Line([0, 0], [545, 0]).color('#003f5c').end]).end
    );


    pdf.add(pdf.ln(2));

    var nuevoArray = new Array(Math.trunc(info.dischargedCargos.length / 3)+1);
    // Bucle que recorre todas las posiciones del primer array
    for(var i=0; i < nuevoArray.length; i++) {

      nuevoArray[i]=new Array(3);
      
      // Bucle que recorre el array que está en la posición i
      for(var j=0; j < nuevoArray[i].length; j++) {
          

          // Pinta todas las posiciones del array
          const posicionInList = (i*3) + j;
          
          if(info.dischargedCargos[posicionInList]){

            let leyendaTop = new Stack([
              new Table([[new Canvas([new Ellipse([3, 5], 5).color('#003f5c').end]).end,new Txt('Total Discharge').bold().end]]).widths(['auto', '*']).layout('noBorders').end,
              new Txt(`${this.toFixedNumber(info.dischargedCargos[posicionInList].discharged,3)}`).margin([4,0,0,5]).fontSize(16).bold().end
            ]).margin([0,0]).end;

            let leyendaBottom = new Stack([
              new Table([[new Canvas([new Ellipse([3, 5], 5).color('#beecfc').end]).end,new Txt('Remain to go').bold().end]]).widths(['auto', '*']).layout('noBorders').end,
              new Txt(`${this.toFixedNumber(info.dischargedCargos[posicionInList].qty - info.dischargedCargos[posicionInList].discharged ,3)}`).margin([4,7,0,0]).fontSize(16).bold().end
            ]).margin([0,6]).end
            
            
            nuevoArray[i][j]=new Stack([
              leyendaTop,
              await new Img(info.dischargedCargos[posicionInList]?.chart).fit([80,80]).build(),
              leyendaBottom
            ]).end
          }else{
            nuevoArray[i][j]=new Txt('').end;
          }
      }
    }

    let b = new Table(nuevoArray).layout('noBorders').widths(['*', '*','*'])
    .end
    pdf.add(b);


    // let tableBL = this.createRowBls(info.portHub.BL);

    // pdf.add(tableBL);




    pdf.create().download();

 

    
    // pdf.create().getDataUrl((data) => {
    //   resolve(data);
    // });

    });
  }

  // mapTableBodies(innerTableCell) {
  //   const findInlineHeight = this.findInlineHeight(
  //     innerTableCell,
  //     maxWidth,
  //     usedWidth
  //   );
  
  //   usedWidth = findInlineHeight.width;
  //   return findInlineHeight.height;
  // }
  
  //  findInlineHeight(cell, maxWidth, usedWidth = 0) {
  //     let calcLines = (inlines) => {
  //         if (!inlines)
  //             return {
  //                 height: 0,
  //                 width: 0,
  //             };
  //         let currentMaxHeight = 0;
  //         let lastHadLineEnd = false;
  //         for (const currentNode of inlines) {
  //             usedWidth += currentNode.width;
  //             if (usedWidth > maxWidth || lastHadLineEnd) {
  //               currentMaxHeight += currentNode.height;
  //               usedWidth = currentNode.width;
  //             } else {
  //               currentMaxHeight = Math.max(currentNode.height, currentMaxHeight);
  //             }
  //             lastHadLineEnd = !!currentNode.lineEnd;
  //         }
  //         return {
  //             height: currentMaxHeight,
  //             width: usedWidth,
  //         };
  //     }
  //     if (cell._offsets) {
  //       usedWidth += cell._offsets.total;
  //     }
  //     if (cell._inlines && cell._inlines.length) {
  //         return calcLines(cell._inlines);
  //     }  else if (cell.stack && cell.stack[0]) {
  //         return cell.stack.map(item => {
  //             return findInlineHeight(item, maxWidth);
  //         }).reduce((prev, next) => {
  //             return {
  //             height: prev.height + next.height,
  //             width: Math.max(prev.width + next.width)
  //             };
  //         });
  //     } else if (cell.table) {
  //       let currentMaxHeight = 0;
  //       for (const currentTableBodies of cell.table.body) {
  //         const innerTableHeights = currentTableBodies.map(mapTableBodies);
  //         currentMaxHeight = Math.max(...innerTableHeights, currentMaxHeight);
  //       }
  //       return {
  //         height: currentMaxHeight,
  //         width: usedWidth,
  //       };
  //     } else if (cell._height) {
  //       usedWidth += cell._width;
  //       return {
  //         height: cell._height,
  //         width: usedWidth,
  //       };
  //     }
  
  //     return {
  //       height: null,
  //       width: usedWidth,
  //     };
  // }
  
  // function applyVerticalAlignment(node, rowIndex, align, manualHeight = 0) { // New default argument
  //     const allCellHeights = node.table.body[rowIndex].map(
  //       (innerNode, columnIndex) => {
  //         if (innerNode._span) return null
  //     const calcWidth = [...Array(innerNode.colSpan || 1).keys()].reduce((acc, i) => {
  //       return acc + node.table.widths[columnIndex + i]._calcWidth
  //     }, 0)
  //     const mFindInlineHeight = findInlineHeight(innerNode, calcWidth, 0, rowIndex, columnIndex)  
  //         return mFindInlineHeight.height;
  //       }
  //     );
  //     const maxRowHeight = manualHeight ? manualHeight[rowIndex] : Math.max(...allCellHeights); // handle manual height
  //     node.table.body[rowIndex].forEach((cell, ci) => {
  //       if (allCellHeights[ci] && maxRowHeight > allCellHeights[ci]) {
  //         let topMargin;
          
  //         let cellAlign = align;
  //         if (Array.isArray(align)) {
  //             cellAlign = align[ci];
  //         }
          
  //         if (cellAlign === 'bottom') {
  //           topMargin = maxRowHeight - allCellHeights[ci];
  //         } else if (cellAlign === 'center') {
  //           topMargin = (maxRowHeight - allCellHeights[ci]) / 2;
  //         }
          
  //         if (topMargin) {
  //             if (cell._margin) {
  //               cell._margin[1] = topMargin;
  //             } else {
  //               cell._margin = [0, topMargin, 0, 0];
  //             }
  //         }
  //       }
  //     });
  // }

  createFileSOF(info) {
    return new Promise(async (resolve, reject) => {

    const pdf = new PdfMakeWrapper();

    //Configuring custom fonts
    PdfMakeWrapper.setFonts(pdfFonts, {
      'mono': {
        normal: 'RobotoMono-Regular.ttf',
        bold: 'RobotoMono-Medium.ttf',
        italics: 'RobotoMono-italic.ttf',
        bolditalics: 'RobotoMono-BoldItalic.ttf'
      },
      'Roboto': {
        normal: 'Roboto-Regular.ttf',
        bold: 'Roboto-Medium.ttf',
        italics: 'Roboto-Italic.ttf',
        bolditalics: 'Roboto-BoldItalic.ttf'
      },
      
    });


    // PdfMakeWrapper.useFont('mono');

    pdf.defaultStyle({
        fontSize: 8,
    });

    pdf.pageSize('letter');

    pdf.pageMargins([ 24, 120, 24, 75 ]);

    let that = this;

    
      let logoEmpresa = info.logoEmpresa ||  info.empresa.logoEmpresa;

      const logo = await new Img(`${that.dominioContainer + that.carpetaAvatarLogos + logoEmpresa}`)
      .fit([150, 50]).alignment('right').build();
  
      let vessel=  new Txt(`Mv. ${info.vessel}`).lineHeight(2).decoration('underline').fontSize(20).bold().end
      let titulo=  new Txt('Statement of Facts').margin([0,-10,0,0]).fontSize(15).bold().end
      let voy=  new Txt(`voy: ${info.voyage} / ${info.agentVoyage}`).end
  
      let topLeftText= new Stack([vessel, titulo,voy]).end

      let headerPDF = function(currentPage, pageCount) {  
              
        let canvasRightGruesa = new Canvas([new Line([0, 70], [0, 0]).lineColor('#00284b').lineWidth(10).end]).end

  
      let topLeft = new Table([[canvasRightGruesa,topLeftText]]).widths([10, 300]).layout('noBorders').end;

        if (currentPage != pageCount){
          return new Table([[topLeft, logo],[new Txt(currentPage!==1?'CONTINUATION':'').margin([10,0,0,0]).alignment('left').end, new Txt(`Page: ${currentPage}`).alignment('right').end ]]).layout('noBorders').widths(['50%', '50%']).margin([24,24,24,0]).end;
        }else{
         return new Table([[topLeft, logo],[new Txt(currentPage!==1?'CONTINUATION':'').margin([10,0,0,0]).alignment('left').end,new Txt(`Page: ${currentPage}`).alignment('right').end]]).layout('noBorders').widths(['50%', '50%']).margin([24,24,24,0]).end;
        }
      };


      pdf.header(headerPDF);

      // return new Table([[topLeft, logo]]).layout('noBorders').widths(['50%', '50%']).end;

    
   let footerPDF = function(currentPage, pageCount) {  
            
    // if (currentPage != pageCount || pageCount===1){
    //   let sigMaster1Title=  new Txt(`${info.signatureSOF[0].title}`).margin([0,0,0,10]).fontSize(9).bold().end
    //   let lineH = new Canvas([new Line([0, 0], [150, 0]).lineColor('#00284b').end]).end
    //   let sigMaster1Comment=  new Txt(`${info.signatureSOF[0].comment}`).margin([0,10,0,0]).fontSize(9).end
    //   let sigMaster1= new Stack([sigMaster1Title,lineH, sigMaster1Comment]).margin([24,0,0,0]).end
  
    //   let sigMaster2Title=  new Txt(`${info.signatureSOF[1].title}`).alignment('right').margin([0,0,0,10]).fontSize(9).bold().end
    //   let lineH2 = new Canvas([new Line([0, 0], [150, 0]).lineColor('#00284b').end]).end
    //   let sigMaster2Comment=  new Txt(`${info.signatureSOF[1].comment}`).alignment('right').margin([0,10,0,0]).fontSize(9).end
    //   let sigMaster2= new Stack([sigMaster2Title,lineH2, sigMaster2Comment]).margin([0,0,24,0]).alignment('right').end
  
    //   return new Table([[sigMaster1, sigMaster2]]).layout('noBorders').widths(['50%', '50%']).end;
    // }

    let signatures = info.signatureSOF;

    let rowSignature:any[]=[];
    let tablaSig:any[] =[];

    for (let [i,sig] of signatures.entries()) {


      let align:any= "left";
      switch (rowSignature.length) {
        case 1:
          align= "center";
          break;
        case 2:
          align= "right";
          break;
      }
      

      let sigMaster1Title=  new Txt(`${sig.title}`).margin([0,0,0,10]).alignment(align).fontSize(9).bold().end
      let lineH = new Canvas([new Line([0, 0], [150, 0]).lineColor('#00284b').end]).end
      let sigMaster1Comment=  new Txt(`${sig.comment}`).alignment(align).margin([0,10,0,0]).fontSize(9).end
      let sigMaster1= new Stack([sigMaster1Title,lineH, sigMaster1Comment]).alignment(align).margin([rowSignature.length===0?24:0,0,rowSignature.length===2?24:0,0]).end
  

      if((i+1)%3===0){

        rowSignature.push(sigMaster1);
        tablaSig.push(rowSignature);
        rowSignature=[];
      }else if((i+1)===signatures.length){

        rowSignature.push(sigMaster1);

        for (let index = 0;  rowSignature.length < 3; index++) {
          rowSignature.push('');
        }
        

        tablaSig.push(rowSignature)
      }else{

        rowSignature.push(sigMaster1);
      }
    }

   
    return new Table(tablaSig).layout('noBorders').widths(['*','*','*']).end;

    };


    pdf.footer(footerPDF);


    let canvasRight3 = new Canvas([new Line([0, 50], [0, 0]).lineColor('#00284b').lineWidth(2).end]).end


    pdf.add(pdf.ln(1));

    /* Fin */

    let tituloPortCall = new Txt('PORT CALL DETAILS')
    .margin([0, 0, 0, 12])
    .fontSize(9)
    .bold().end;


    
    let infoPortCall = new Table([
      ['Vessel Flag', `: ${info.nombreFlag}`],
      ['Owner', `: ${info.owner}`],
      ['Loading Port', `: ${info.prevPort}`],
      ['Disch Port', `: ${info.nextPort}`]
    ])
    .widths([70, '*'])
    .layout('noBorders').end

    const infoPortCallWhitBorder = new Table([[canvasRight3, infoPortCall]]).widths([4, '*']).layout('noBorders').end
    let portCall = new Stack([tituloPortCall,infoPortCallWhitBorder]).end;



    /*Fin PortCall*/

    let tituloPortCall2 = new Txt('.').color('#efefef')
    .margin([0, 0, 0, 12])
    .fontSize(10)
    .bold().end;

    let timeAta= info.portHub.vesselStatus.ata !==''?info.portHub.vesselStatus.ata.horaMilitar? formatDate(info.portHub.vesselStatus.ata.time24,'HH:mm','en-US'): info.portHub.vesselStatus.ata.time12:'';
    let timeAts= info.portHub.vesselStatus.ats !==''?info.portHub.vesselStatus.ats.horaMilitar? formatDate(info.portHub.vesselStatus.ats.time24,'HH:mm','en-US'): info.portHub.vesselStatus.ats.time12:'';

    
    let infoPortCall2 = new Table([
      ['Arrived', `: ${ this.isEmptyOrNull(info.portHub.vesselStatus.ata) ||  info.portHub.vesselStatus.ata?.tipo==='e'?'': `${formatDate(info.portHub.vesselStatus.ata.date,'MMM d, y','en-US')} ${timeAta}`  }`],
      ['Nor tendered', `: ${this.isEmptyOrNull(info.portHub.vesselStatus.norTendered)?'No information':info.portHub.vesselStatus.norTendered}`],
      ['Accepted', `: ${this.isEmptyOrNull(info.portHub.vesselStatus.norAccepted)?'No information':info.portHub.vesselStatus.norAccepted}`],
      ['Commenced Ops', `: ${ this.isEmptyOrNull(info.portHub.vesselStatus.ats) ||  info.portHub.vesselStatus.ats?.tipo==='e'?'No information': `${formatDate(info.portHub.vesselStatus.ats.date,'MMM d, y','en-US')} ${timeAts}`  }`],
    ])
    .widths([70, '*'])
    .layout('noBorders').end

    canvasRight3 = new Canvas([new Line([0, 50], [0, 0]).lineColor('#00284b').lineWidth(2).end]).end
    const infoPortCall2WhitBorder= new Table([[canvasRight3, infoPortCall2]]).widths([4, '*']).layout('noBorders').end
    let portCall2 = new Stack([tituloPortCall2,infoPortCall2WhitBorder]).end;

    pdf.add(
      new Table([[portCall,portCall2]]).widths(['50%', '50%'])
      .layout({
        fillColor:() => '#efefef',
        hLineColor: () => '#efefef',
        vLineColor: () => '#efefef',
        paddingBottom:()=>10,
        paddingTop:()=>10,
        paddingLeft:()=>10,
        paddingRight:()=>10,
      }).end
    );

    /*Fin*/
    pdf.add(pdf.ln(1));

    if(info.portHub.BL.length>0){
      
    


    let cargos = this.getBLCargosUniques(info.portHub.BL);

    for (let cargo of cargos) {
      let inHolds =[];
      let inBls =[];



      for (let hold of info.portHub.cargoStorages) {
        let i = hold.cargos.findIndex((x:any)=>  x.cargo.toLowerCase().trim() === cargo.name.toLowerCase().trim());
        if(i!==-1){
          
          if(!inHolds.includes(hold.hold)){
            inHolds.push(hold.hold)
          }
        }
      }




      cargo.inHolds= inHolds;
    }


    
    let tituloCargodetails = new Txt('CARGO DETAILS')
    .margin([0, 0, 0, 1])
    .fontSize(9)
    .bold().end;

    let tableCargo=[];
    let header=[];
    header.push(new Txt(`Quantity`).italics().end);
    header.push(new Txt(`Commodity`).italics().end);
    header.push(new Txt(`Holds N°`).italics().alignment('center').end);
    header.push(new Txt(`Bl's N°`).italics().alignment('center').end);


    tableCargo.push(header);

    for (const x of cargos) {

      // iterator.valorFda= Number(iterator.valorFda).toFixed(this.fixADM)
      const a = [];
      a.push(new Txt(`${this.toFixedNumber(x.qty,3)} mts`).bold().end);
      a.push(new Txt(x.name).bold().end);
      a.push(new Txt(x.inHolds.join(',')).bold().alignment('center').end);
      a.push(new Txt(x.Bls.join(',')).bold().alignment('center').end);
  

      tableCargo.push(a);


    }


    const colum = new Table(tableCargo).widths(['*','*','*','*']).margin([0,10,0,0]).layout({
      hLineColor:()=>'#efefef',
      vLineColor:()=>'#efefef',
      hLineWidth:()=>0.1,
      vLineWidth:()=>0.1,
      paddingBottom:()=>4
    }).end;

    

    pdf.add(
      new Table([[new Stack([tituloCargodetails,colum]).end]]).widths(['*'])
      .layout({
        fillColor:() => '#efefef',
        hLineColor: () => '#efefef',
        vLineColor: () => '#efefef',
        paddingBottom:()=>10,
        paddingTop:()=>10,
        paddingLeft:()=>10,
        paddingRight:()=>10,
      }).end);
    


    }

    pdf.add(pdf.ln(3));

    let sofEvents = info.itemsSof;

    let listFechasUnicas:any=[...new Set(sofEvents.map(x=>formatDate(x.date,'MMM d, y','en-US')))];

    listFechasUnicas.sort((a:any,b:any)=>{
      let dateA =new Date (a);
      let dateB =new Date (b);

      if(dateA>dateB){
        return 1
      }else{
        return -1
      }
    })

    for (const fecha of listFechasUnicas) {
      let macro = [];

      let fe=new Date(fecha);

      let events = sofEvents.filter(x=>formatDate(x.date,'MMM d, y','en-US')===fecha);

      events.sort((a:any,b:any)=>{
      let salida=1;

      let dateA =new Date (a.date);
      let dateB =new Date (b.date);
 
 
      if(dateA<dateB){
          salida= -1
      }else if(dateA>dateB){
          salida= 1
      }else{
        
        let timeA:any;
        let timeB:any;

        if(!a.horaMilitar){
          if(a.time==="AM"){
            timeA= formatDate('01/01/2020 11:59:59','HH:mm:ss','en-US')
          }else{
            timeA= formatDate('01/01/2020 23:59:59','HH:mm:ss','en-US')
          }
        }else{
          timeA = formatDate(a.time,'HH:mm:ss','en-US')
        }

        if(!b.horaMilitar){

          if(b.time==="AM"){
            timeB= formatDate('01/01/2020 11:59:59','HH:mm:ss','en-US')
          }else{
            timeB= formatDate('01/01/2020 23:59:59','HH:mm:ss','en-US')
          }
        }else{
          timeB = formatDate(b.time,'HH:mm:ss','en-US')
        }

        if(timeA < timeB){
          salida=-1
        }else{
          salida=1
        }

      }

       return salida;
      })

      let header = new Table([[new Stack(
                              [
                                fe.toLocaleDateString('en-US', {  weekday: 'long' }),
                                new Txt(fecha).lineHeight(2).decoration('underline').bold().end
                              ]).alignment('center').end,'']]).bold().widths(['65%','10%']).layout('noBorders').end;

     macro.push([header,'','','']);

      // const header = new Table([headerTable]).layout({
      //   hLineColor:()=>'#000',
      //   vLineColor:()=>'#000',
      //   hLineWidth:()=>0.0,
      //   vLineWidth:()=>0.0
      // }).widths(['15%','5%','70%','10%']).end;
 
      

      let startUpto = new Table([['From','-','Up to']]).bold().widths(['45%','10%','45%']).layout('noBorders').end;
      // const header2 = new Table([[startUpto,'','',new Txt('Lost Time').alignment('right').bold().end]]).layout({
      //   hLineColor:()=>'#ffffff',
      //   vLineColor:()=>'#ffffff',
      //   hLineWidth:()=>0.1,
      //   vLineWidth:()=>0.1
      // }).widths(['15%','5%','70%','10%']).end;
 
     
      // macro.push(header);
      macro.push([startUpto,'','',new Txt('Lost Time').alignment('right').bold().end]);

      
      for (const event of events) {

        let lostTime:any = '';

        let hours:any='';
        let minutesLostTime:any='';
        
        if(!this.isEmptyOrNull(event.start) && !this.isEmptyOrNull(event.end)){

         


          var diff = event.end.getTime() - event.start.getTime();


          lostTime = diff/(3600000); //horas

         
          var hour=Math.floor(lostTime);
          hours=("0"+ hour).slice(-2);
          var minute = (lostTime-hour)*60; // convertimos los "decimales - Horas" a minutos

          var minutes=("0"+ Math.floor(minute)).slice(-2);
          minutesLostTime= hours + ":" +minutes; 


        }

        lostTime = new Txt(`${!this.isEmptyOrNull(hours) && ['no operation','stopped'].includes(event.event?.toLowerCase())?minutesLostTime:''}`).alignment('right').end;
        let from = new Txt(`${event.start?formatDate(event.start,'HH:mm','en-US'):''}`).end;
        let upTo = new Txt(`${event.end?formatDate(event.end,'HH:mm','en-US'):''}`).end;

        let startUpto = new Table([[from,'-',upTo]]).bold().widths(['45%','10%','45%']).layout('noBorders').end;

        let event_ = new Txt(`${event.comment?event.comment:''}`).end;
        let icon = new SVG('<svg xmlns="http://www.w3.org/2000/svg" height="10px" viewBox="0 0 192 512"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M0 384.7V127.3c0-17.8 21.5-26.7 34.1-14.1l128.7 128.7c7.8 7.8 7.8 20.5 0 28.3L34.1 398.8C21.5 411.4 0 402.5 0 384.7z"/></svg>').alignment("center").end;
        macro.push([startUpto,icon,event_,lostTime]);
      }





      pdf.add(
        new Table(macro).headerRows(1).layout('noBorders').widths(['15%','5%','70%','10%']).end
      );
    }

    pdf.add(pdf.ln(2));

    let remarks = info.remarkSOF;

    if(remarks.length>0){

      pdf.add(new Txt('Remark')
        .margin([0, 0, 0, 5])
        .fontSize(10)
        .bold().end);

        pdf.add(pdf.ln(1));

        for (const p of remarks) {
          pdf.add(new Txt(p.title).color('#00284b').bold().end);
          pdf.add(new Txt(p.comment).characterSpacing(-0.5).font('mono').end);
          pdf.add(pdf.ln(2));
        }
    }

    // let signatures = info.signatureSOF;

    // let rowSignature:any[]=[];
    // let tablaSig:any[] =[];

    // for (let [i,sig] of signatures.entries()) {


    //   let align:any= "left";
    //   switch (rowSignature.length) {
    //     case 1:
    //       align= "center";
    //       break;
    //       case 2:
    //         align= "right";
    //   }
      

    //   let sigMaster1Title=  new Txt(`${sig.title}`).margin([0,20,0,10]).alignment(align).fontSize(9).bold().end
    //   let lineH = new Canvas([new Line([0, 0], [150, 0]).lineColor('#00284b').end]).end
    //   let sigMaster1Comment=  new Txt(`${sig.comment}`).alignment(align).margin([0,10,0,0]).fontSize(9).end
    //   let sigMaster1= new Stack([sigMaster1Title,lineH, sigMaster1Comment]).alignment(align).margin([rowSignature.length===0?24:0,0,rowSignature.length===2?24:0,0]).end
  



    //   if((i+1)%3===0){

    //     rowSignature.push(sigMaster1);
    //     tablaSig.push(rowSignature);
    //     rowSignature=[];
    //   }else if((i+1)===signatures.length){

    //     rowSignature.push(sigMaster1);

    //     for (let index = 0;  rowSignature.length < 3; index++) {
    //       rowSignature.push('');
    //     }
        

    //     tablaSig.push(rowSignature)
    //   }else{

    //     rowSignature.push(sigMaster1);
    //   }
    // }

   
    // pdf.add(new Table(tablaSig).layout('noBorders').widths(['*','*','*']).end);

    resolve(pdf.create());
    
    // pdf.create().getDataUrl((data) => {
    //   resolve(data);
    // });

    });
  }

  createFilePortEvent(info) {
    return new Promise(async (resolve, reject) => {

    const pdf = new PdfMakeWrapper();
    const emptyPipe = new checkEmptyPipe();
    

    
    pdf.defaultStyle({
        fontSize: 8,
    });

    pdf.pageMargins([ 24, 24, 24, 24 ]);

    pdf.header(this.headerPDF);
    pdf.footer(this.footerPDF);

    let logoEmpresa = info.logoEmpresa ||  info.empresa.logoEmpresa;

    const logo = await new Img(`${this.dominioContainer + this.carpetaAvatarLogos + logoEmpresa}`)
    .fit([150, 50]).alignment('right').build();

    let vessel=  new Txt(`Mv. ${info.vessel}`).lineHeight(2).decoration('underline').fontSize(20).bold().end
    let titulo=  new Txt('DAILY & DEPARTURE REPORT').margin([0,-10,0,0]).fontSize(15).bold().end
    let date=  new Txt(`Date: ${ formatDate(info.report.date,'MMM d, y','en-US')}`).fontSize(8).end

    let topLeftText= new Stack([vessel, titulo, date]).end

    let canvasRightGruesa = new Canvas([new Line([0, 65], [0, 0]).lineColor('#00284b').lineWidth(15).end]).end
    let canvasRight3 = new Canvas([new Line([0, 47], [0, 0]).lineColor('#00284b').lineWidth(2).end]).end
    let canvasRight4 = new Canvas([new Line([0, 60], [0, 0]).lineColor('#00284b').lineWidth(2).end]).end

    let topLeft = new Table([[canvasRightGruesa,topLeftText]]).widths([10, 300]).layout('noBorders').end;

    pdf.add(new Table([[topLeft, logo]]).layout('noBorders').widths(['50%', '50%']).end);
    pdf.add(pdf.ln(2));

    /* Fin */

    let portSumaryText = new Table([
      [new Txt('Cargo').bold().end, `: ${ this.toFixedNumber(info.CARGO,3)}MT ${this.getBLCargosUniques(info.portHub.BL).map(x=>x.name).join('/')}`],
      [new Txt('Loa Port').bold().end, `: ${info.prevPort}`],
      [
        new Txt('Dish Port').bold().end,
        `: ${info.nextPort}`,
      ],
    ])
      .widths([40, '*'])
      .layout('noBorders').end

    pdf.add(
      new Table([[canvasRight3, portSumaryText]]).widths([4, 300]).layout('noBorders').end
    );

    pdf.add(pdf.ln(1));

    /* Fin */


    let tituloHold = new Txt(info.valueActivity===0?'Preliminary Stowage':'Cargo Storage')
      .margin([0, 0, 0, 5])
      .fontSize(10)
      .bold().end;
      

    let infoHolds = [];
      let countRowHold=1;
 
    for (let [i,x] of info.portHub.cargoStorages.entries()) {

      let cargos=[];
      for (let cargo of x.cargos) {
        cargos.push(`${this.toFixedNumber(cargo.qty,)} MT ${cargo.cargo}`);
        countRowHold++;
      }
      infoHolds.push(new Table([[new Txt(`HOLD N°${x.hold}: `).bold().end, new Stack(cargos).end]]).widths([40, '*']).layout('noBorders').end)
    }
    canvasRight3 = new Canvas([new Line([0, Number(countRowHold+'5')], [0, 0]).lineColor('#00284b').lineWidth(2).end]).end


    const AddBorderInfoOurPDA = new Table([[canvasRight3, infoHolds]]).widths([4, 300]).layout('noBorders').end
    let cargoStorage = new Stack([tituloHold,AddBorderInfoOurPDA]).end;

    /*Fin Hold*/

    let tituloPortStay = new Txt('Port Stay Overview')
    .margin([0, 0, 0, 5])
    .fontSize(10)
    .bold().end;

    let statusTypeDate = new VesselStatusTypeDatePipe()

    let tituloEta= statusTypeDate.transform(info.portHub.vesselStatus.ata,'tipo','ETA:','Arrived:');
    let tituloEtb= statusTypeDate.transform(info.portHub.vesselStatus.atb,'tipo','ETB:','Berthed:');
    let tituloEtos= statusTypeDate.transform(info.portHub.vesselStatus.ats,'tipo','ETOS:','Ops Started:');
    let tituloEtc= statusTypeDate.transform(info.portHub.vesselStatus.atc,'tipo','ETC:','Completed:');
    let tituloEtd= statusTypeDate.transform(info.portHub.vesselStatus.atd,'tipo','ETD:','Departure:');

    let timeAta= info.portHub.vesselStatus.ata !==''?info.portHub.vesselStatus.ata.horaMilitar? formatDate(info.portHub.vesselStatus.ata.time24,'HH:mm','en-US'): info.portHub.vesselStatus.ata.time12:'';
    let timeAtb= info.portHub.vesselStatus.atb !==''?info.portHub.vesselStatus.atb.horaMilitar? formatDate(info.portHub.vesselStatus.atb.time24,'HH:mm','en-US'): info.portHub.vesselStatus.atb.time12:'';
    let timeAtos= info.portHub.vesselStatus.ats !==''?info.portHub.vesselStatus.ats.horaMilitar? formatDate(info.portHub.vesselStatus.ats.time24,'HH:mm','en-US'): info.portHub.vesselStatus.ats.time12:'';
    let timeAtc= info.portHub.vesselStatus.atc !==''?info.portHub.vesselStatus.atc.horaMilitar? formatDate(info.portHub.vesselStatus.atc.time24,'HH:mm','en-US'): info.portHub.vesselStatus.atc.time12:'';
    let timeAtd= info.portHub.vesselStatus.atd !==''?info.portHub.vesselStatus.atd.horaMilitar? formatDate(info.portHub.vesselStatus.atd.time24,'HH:mm','en-US'): info.portHub.vesselStatus.atd.time12:'';


    let statusViewDate = new VesselStatusViewDatePipe();
    let infoPortStay = new Table([
      [new Txt(tituloEta).bold().end, `: ${ formatDate(statusViewDate.transform(info.eta,info.portHub.vesselStatus.ata.date),'MMM d, y','en-US') + ' ' + timeAta  || 'No information'}`],
      [new Txt(tituloEtb).bold().end, `: ${formatDate(statusViewDate.transform(info.etb,info.portHub.vesselStatus.atb.date) ,'MMM d, y','en-US') + ' ' + timeAtb  || 'No information'}`],
      [new Txt(tituloEtos).bold().end, `: ${formatDate(statusViewDate.transform(info.ets,info.portHub.vesselStatus.ats.date),'MMM d, y','en-US') + ' ' + timeAtos || 'No information'}`],
      [new Txt(tituloEtc).bold().end, `: ${formatDate(statusViewDate.transform(info.etc,info.portHub.vesselStatus.atc.date) ,'MMM d, y','en-US') + ' ' + timeAtc  || 'No information'}`],
      [new Txt(tituloEtd).bold().end, `: ${formatDate(statusViewDate.transform(info.etd,info.portHub.vesselStatus.atd.date),'MMM d, y','en-US')  + ' ' + timeAtd  || 'No information'}`],
    ])
      .widths([70, '*'])
      .layout('noBorders').end

    canvasRight3 = new Canvas([new Line([0, 75], [0, 0]).lineColor('#00284b').lineWidth(2).end]).end
    const AddBorderInPortStay= new Table([[canvasRight3, infoPortStay]]).widths([4, 300]).layout('noBorders').end
    let portStay = new Stack([tituloPortStay,AddBorderInPortStay]).end;

    pdf.add(
      new Table([[portStay,cargoStorage]]).widths(['*', '*']).layout('noBorders')
        .end
    );

    /*Fin*/
    pdf.add(pdf.ln(2));

    pdf.add(new Txt('Arrival / Departure Details').bold().end);

    pdf.add(pdf.ln(2));

    let portEvents = JSON.parse(info.report.jsonPortEvents);


    let listFechasUnicas:any=[...new Set(portEvents.map(x=>formatDate(x.date,'MMM d, y','en-US')))];


    listFechasUnicas.sort((a:any,b:any)=>{
      let dateA =new Date (a);
      let dateB =new Date (b);

      if(dateA>dateB){
        return 1
      }else{
        return -1
      }
    })


    let macro = [];
    for (const fecha of listFechasUnicas) {
      let fe=new Date(fecha);
      

      let events = portEvents.filter(x=>formatDate(x.date,'MMM d, y','en-US')===fecha);

      events.sort((a:any,b:any)=>{
        let salida=1;

      let dateA =new Date (a.date);
      let dateB =new Date (b.date);
 
 
      if(dateA<dateB){
          salida= -1
      }else if(dateA>dateB){
          salida= 1
      }else{
        
        let timeA:any;
        let timeB:any;

        if(!a.horaMilitar){
          if(a.time==="AM"){
            timeA= formatDate('01/01/2020 11:59:59','HH:mm:ss','en-US')
          }else{
            timeA= formatDate('01/01/2020 23:59:59','HH:mm:ss','en-US')
          }
        }else{
          timeA = formatDate(a.time,'HH:mm:ss','en-US')
        }

        if(!b.horaMilitar){

          if(b.time==="AM"){
            timeB= formatDate('01/01/2020 11:59:59','HH:mm:ss','en-US')
          }else{
            timeB= formatDate('01/01/2020 23:59:59','HH:mm:ss','en-US')
          }
        }else{
          timeB = formatDate(b.time,'HH:mm:ss','en-US')
        }

        if(timeA < timeB){
          salida=-1
        }else{
          salida=1
        }

      }

       return salida;
      })

      let headerTable=[];

      headerTable.push(new Stack([fe.toLocaleDateString('en-US', {  weekday: 'long' }),
                                  new Txt(fecha).lineHeight(2).decoration('underline').bold().end
                                 ]).alignment('center').end)
      
      const header = new Table([headerTable]).layout({
        hLineColor:()=>'#ffffff',
        vLineColor:()=>'#ffffff',
        hLineWidth:()=>0.1,
        vLineWidth:()=>0.1
      }).widths(['10%','5%','85%']).end;
 
      macro.push(header);



      for (const event of events) {

        const a = [];
        a.push(new Txt(event.horaMilitar?formatDate(event.time,'HH:mm','en-US'):event.time).alignment("center").bold().end);

        if(event.relatedTo&&event.relatedTo!==''){

          a.push(new SVG('<svg xmlns="http://www.w3.org/2000/svg" height="10px" viewBox="0 0 512 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z"/></svg>').alignment("center").end);
        }else{
          a.push(new SVG('<svg xmlns="http://www.w3.org/2000/svg" height="10px" viewBox="0 0 320 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z"/></svg>').alignment("center").end);

        }
        
        a.push(new Txt(`${event.event.relatedTo!=="add"?event.event.comment:''} ${event.comment||''}`).end);

        const colum = new Table([a]).widths(['10%','5%','85%']).layout({
          hLineColor:()=>'#ffffff',
          vLineColor:()=>'#ffffff',
          hLineWidth:()=>0.1,
          vLineWidth:()=>0.1,
        }).end;

        macro.push(colum);
      }

      macro.push(new Table([['','',''],['','',''],['','','']]).widths(['10%','5%','85%']).layout({
        hLineColor:()=>'#ffffff',
        vLineColor:()=>'#ffffff',
        hLineWidth:()=>0.1,
        vLineWidth:()=>0.1,
      }).end);


    }
    
    pdf.add(
      new Table([
          [new Stack(macro).end]
      ]).widths(['*']).layout({
        hLineWidth:()=>0.1,
        vLineWidth:()=>0.1,
        hLineColor:()=>'#ffffff',
        vLineColor:()=>'#ffffff',
        paddingLeft:()=>0,
        paddingRight:()=>0,
        paddingTop:()=>0,
        paddingBottom:()=>0}).end
    );

    pdf.add(pdf.ln(2));

    let remarks = JSON.parse(info.report.remark);

    if(remarks.length>1){

      pdf.add(new Txt('Remark')
        .margin([0, 0, 0, 5])
        .fontSize(10)
        .bold().end);

        pdf.add(pdf.ln(1));

        for (const p of remarks) {
          pdf.add(new Txt(p.title).color('#00284b').bold().end);
          pdf.add(new Txt(p.comment).italics().end);
          pdf.add(pdf.ln(2));
        }
    }

    resolve(pdf.create());
    
    // pdf.create().getDataUrl((data) => {
    //   resolve(data);
    // });

    });
  }

  createFileReportInvoice(info) {
    return new Promise(async (resolve, reject) => {

    const pdf = new PdfMakeWrapper();
    const emptyPipe = new checkEmptyPipe();
    
    pdf.defaultStyle({
        fontSize: 8,
    });

    pdf.pageMargins([ 24, 24, 24, 24 ]);

    pdf.pageOrientation('landscape');
    pdf.header(this.headerPDF);
    pdf.footer(this.footerPDF);

    const logo = await new Img(`${this.dominioContainer + this.carpetaAvatarLogos + info.logoEmpresa}`)
    .fit([150, 50]).alignment('right').build();

    let textTitulo=  new Txt('FINAL DISBURSEMENTS STATEMENT OF ACCOUNT').fontSize(20).bold().end

    let canvasRightGruesa = new Canvas([new Line([0, 60], [0, 0]).lineColor('#00284b').lineWidth(15).end]).end

    let titulo = new Table([[canvasRightGruesa,textTitulo]]).widths([10, 400]).layout('noBorders').end;

    pdf.add(new Table([[titulo, logo]]).layout('noBorders').widths(['50%', '50%']).end);
    pdf.add(pdf.ln(1));




    //const listInvoices = this.createRowReportInvoices(info.invoices);

    let tableInvoice =[];

    let headerTable=[];
    headerTable.push(new Txt('CUSTOMER').bold().end);
    headerTable.push(new Txt('VESSEL').bold().end);
    headerTable.push(new Txt('OUR VOYAGE').bold().end);
    headerTable.push(new Txt('SAILED DATE').bold().end);
    headerTable.push(new Txt('DA').bold().end);
    headerTable.push(new Txt('ADVANCE RECEIVED').bold().end);
    headerTable.push(new Txt('BALANCE').bold().end);
    headerTable.push(new Txt('INVOICE DATE').bold().end);
    headerTable.push(new Txt('OVERDUE DATE').bold().end);
    headerTable.push(new Txt('COLLECTION DATE').bold().end);


    const header = new Table([headerTable]).layout({
      hLineColor:()=>'#efefef',
      vLineColor:()=>'#efefef',
      hLineWidth:()=>0.1,
      vLineWidth:()=>0.1
    }).widths(['10%','10%','10%','10%','10%','10%','10%','10%','10%','10%']).end;

    tableInvoice.push(header);
    // tableInvoice.push(listInvoices);

      
    pdf.add(
        new Table([
            [new Stack(tableInvoice).end]
        ]).widths(['*']).layout({
          hLineWidth:()=>0.1,
          vLineWidth:()=>0.1,
          hLineColor:()=>'#efefef',
          vLineColor:()=>'#efefef',
          paddingLeft:()=>0,
          paddingRight:()=>0,
          paddingTop:()=>0,
          paddingBottom:()=>0}).end
    );

    pdf.add(pdf.ln(1));

    pdf.create().getDataUrl((data) => {
      resolve(data);
    });

    });
  }

  createFileReportFda(info) {
    return new Promise(async (resolve, reject) => {

    const pdf = new PdfMakeWrapper();
    const emptyPipe = new checkEmptyPipe();
    
    pdf.defaultStyle({
        fontSize: 8,
    });

    pdf.pageMargins([ 24, 24, 24, 24 ]);

    pdf.pageOrientation('landscape');
    pdf.header(this.headerPDF);
    pdf.footer(this.footerPDF);

    console.log(info.logoEmpresa);


    const logo = await new Img(`${this.dominioContainer + this.carpetaAvatarLogos}/${info.logoEmpresa}`)
    .fit([150, 50]).alignment('right').build();

    let textTitulo=  new Txt('FINAL DISBURSEMENTS STATEMENT OF ACCOUNT').fontSize(20).bold().end

    let canvasRightGruesa = new Canvas([new Line([0, 60], [0, 0]).lineColor('#00284b').lineWidth(15).end]).end

    let titulo = new Table([[canvasRightGruesa,textTitulo]]).widths([10, 400]).layout('noBorders').end;

    pdf.add(new Table([[titulo, logo]]).layout('noBorders').widths(['50%', '50%']).end);
    pdf.add(pdf.ln(1));



    let totalGlobales:any={};

    if(info.tipo==='gn'){

      info.billings.forEach(x => {

        const listInvoices = this.createRowReportFda(x,info.invoices.filter(y=>y.x_responsable===x.company),totalGlobales);

        if(listInvoices.length>0){
          
          let tableInvoice =[];
      
          let headerTable=[];
          headerTable.push(new Txt('CUSTOMER').bold().end);
          headerTable.push(new Txt('VESSEL').bold().end);
          headerTable.push(new Txt('OUR VOYAGE').bold().end);
          headerTable.push(new Txt('SAILED DATE').bold().end);
          headerTable.push(new Txt('DA AMOUNT').bold().end);
          headerTable.push(new Txt('ADVANCE RECEIVED').bold().end);
          headerTable.push(new Txt('BALANCE').bold().end);
          headerTable.push(new Txt('INVOICE DATE').bold().end);
          headerTable.push(new Txt('OVERDUE DATE').bold().end);
          headerTable.push(new Txt('COLLECTION DATE').bold().end);
      
      
          pdf.add(new Txt(x.company).fontSize(12).bold().end);
          pdf.add(pdf.ln(1));
  
          const header = new Table([headerTable]).layout({
            hLineColor:()=>'#efefef',
            vLineColor:()=>'#efefef',
            hLineWidth:()=>0.1,
            vLineWidth:()=>0.1
          }).widths(['10%','10%','8%','10%','12%','10%','10%','10%','10%','10%']).end;
      
      
          tableInvoice.push(header);
      
          tableInvoice.push(listInvoices);
  
          pdf.add(
            new Table([
                [new Stack(tableInvoice).end]
            ]).widths(['*']).layout({
              hLineWidth:()=>0.1,
              vLineWidth:()=>0.1,
              hLineColor:()=>'#efefef',
              vLineColor:()=>'#efefef',
              paddingLeft:()=>0,
              paddingRight:()=>0,
              paddingTop:()=>0,
              paddingBottom:()=>0}).end
          );
  
  
          pdf.add(pdf.ln(1));
          if(x.totalCustomerUSD!==0){
            pdf.add(new Txt(`Total USD: ${ this.toFixedNumber(x.totalCustomerUSD,2)}`).alignment("right").bold().end);
          }
  
          if(x.totalCustomerEUR!==0){
            pdf.add(new Txt(`Total EUR: ${this.toFixedNumber(x.totalCustomerEUR,2)}`).alignment("right").bold().end);
          }
          
  
          pdf.add(pdf.ln(2));
         
        }
      });

    }else{
      let customer:any={}
      const listInvoices = this.createRowReportFda(customer,info.invoices,totalGlobales);

      if(listInvoices.length>0){
      
        let tableInvoice =[];
    
        let headerTable=[];
        headerTable.push(new Txt('CUSTOMER').bold().end);
        headerTable.push(new Txt('VESSEL').bold().end);
        headerTable.push(new Txt('OUR VOYAGE').bold().end);
        headerTable.push(new Txt('SAILED DATE').bold().end);
        headerTable.push(new Txt('DA AMOUNT').bold().end);
        headerTable.push(new Txt('ADVANCE RECEIVED').bold().end);
        headerTable.push(new Txt('BALANCE').bold().end);
        headerTable.push(new Txt('INVOICE DATE').bold().end);
        headerTable.push(new Txt('OVERDUE DATE').bold().end);
        headerTable.push(new Txt('COLLECTION DATE').bold().end);
    
        
    
        // pdf.add(new Txt(info.billings).fontSize(12).bold().end);
        pdf.add(pdf.ln(1));

        const header = new Table([headerTable]).layout({
          hLineColor:()=>'#efefef',
          vLineColor:()=>'#efefef',
          hLineWidth:()=>0.1,
          vLineWidth:()=>0.1
        }).widths(['10%','10%','8%','10%','12%','10%','10%','10%','10%','10%']).end;

        tableInvoice.push(header);
        tableInvoice.push(listInvoices);

        pdf.add(
          new Table([
              [new Stack(tableInvoice).end]
          ]).widths(['*']).layout({
            hLineWidth:()=>0.1,
            vLineWidth:()=>0.1,
            hLineColor:()=>'#efefef',
            vLineColor:()=>'#efefef',
            paddingLeft:()=>0,
            paddingRight:()=>0,
            paddingTop:()=>0,
            paddingBottom:()=>0}).end
        );

        pdf.add(pdf.ln(1));

        if(customer.totalCustomerUSD!==0){
          pdf.add(new Txt(`Total USD: ${this.toFixedNumber(customer.totalCustomerUSD,2)}`).alignment("right").bold().end);
        }

        if(customer.totalCustomerEUR!==0){
          pdf.add(new Txt(`Total EUR: ${this.toFixedNumber(customer.totalCustomerEUR,2)}`).alignment("right").bold().end);
        }
      }else{
        pdf.add(new Txt(`No Records`).fontSize(20).bold().end);
      }

        pdf.add(pdf.ln(2));
    }

    if(totalGlobales.cobrarUSD !== 0){
      pdf.add(new Txt(`Accounts receivable: ${ this.toFixedNumber(totalGlobales.cobrarUSD,2)} USD`).alignment("left").bold().end);
    }

    if(totalGlobales.pagarUSD !== 0){
      pdf.add(new Txt(`Accounts payable: ${ this.toFixedNumber(totalGlobales.pagarUSD,2)} USD`).alignment("left").bold().end);
    }

    if(totalGlobales.cobrarEUR !== 0){
      pdf.add(new Txt(`Accounts receivable: ${ this.toFixedNumber(totalGlobales.cobrarEUR,2)} EUR`).alignment("left").bold().end);
    }

    if(totalGlobales.pagarEUR !== 0){
      pdf.add(new Txt(`Accounts payable: ${ this.toFixedNumber(totalGlobales.pagarEUR,2)} EUR`).alignment("left").bold().end);
    }

    pdf.add(pdf.ln(1));

    pdf.create().getDataUrl((data) => {
      resolve(data);
    });

    });
  }

  createFileReportBord(info) {
    return new Promise(async (resolve, reject) => {

    const pdf = new PdfMakeWrapper();
    const emptyPipe = new checkEmptyPipe();
    
    pdf.defaultStyle({
        fontSize: 8,
    });

    pdf.pageMargins([ 24, 24, 24, 24 ]);

    pdf.pageOrientation('landscape');
    pdf.header(this.headerPDF);
    pdf.footer(this.footerPDF);

    const logo = await new Img(`${this.dominioContainer + this.carpetaAvatarLogos + info.logoEmpresa}`)
    .fit([150, 50]).alignment('right').build();

    let textTitulo=  new Txt('PORT OF CALL').fontSize(20).bold().end

    let canvasRightGruesa = new Canvas([new Line([0, 60], [0, 0]).lineColor('#00284b').lineWidth(15).end]).end

    let titulo = new Table([[canvasRightGruesa,textTitulo]]).widths([10, 400]).layout('noBorders').end;

    pdf.add(new Table([[titulo, logo]]).layout('noBorders').widths(['50%', '50%']).end);
    pdf.add(pdf.ln(1));




    const listInvoices = this.createRowReportBoard(info);

    let tableInvoice =[];

    let headerTable=[];
    headerTable.push(new Txt('VESSEL').bold().end);
    headerTable.push(new Txt('APPOINTING PARTY').bold().end);
    headerTable.push(new Txt('PORT ITINERARY').bold().end);
    headerTable.push(new Txt('ETA / ATA').bold().end);
    headerTable.push(new Txt('ACTIVITY').bold().end);
    headerTable.push(new Txt('CARGO PROGRESS').bold().end);
    headerTable.push(new Txt('TO GO').bold().end);
    headerTable.push(new Txt('ETD / ATD').bold().end);

    const header = new Table([headerTable]).layout({
      hLineColor:()=>'#efefef',
      vLineColor:()=>'#efefef',
      hLineWidth:()=>0.1,
      vLineWidth:()=>0.1
    }).widths(['18%','17%', '15%','10%','10%','10%','10%','10%']).end;

    tableInvoice.push(header);
    tableInvoice.push(listInvoices);

      
    pdf.add(
        new Table([
            [new Stack(tableInvoice).end]
        ]).widths(['*']).layout({
          hLineWidth:()=>0.1,
          vLineWidth:()=>0.1,
          hLineColor:()=>'#efefef',
          vLineColor:()=>'#efefef',
          paddingLeft:()=>0,
          paddingRight:()=>0,
          paddingTop:()=>0,
          paddingBottom:()=>0}).end
    );

    pdf.add(pdf.ln(1));

    pdf.create().getDataUrl((data) => {
      resolve(data);
    });

    });
  }

  sumValorFda(cuentas){


    let total=0;
   

    cuentas.forEach(x => {
      if(x.valorFda){
        total+=Number(x.valorFda);
      }
    });

    return this.myRound(total,this.fixADM)
  }

  getOffset(el) {
    let _x = 0;
    let _y = 0;

    var coutn = 1;

    while (coutn< 4 && el && !isNaN(el.offsetLeft) && !isNaN(el.offsetTop)) {

      _x += el.offsetLeft - el.scrollLeft;
      _y += el.offsetTop - el.scrollTop;
      el = el.offsetParent;
      coutn++;
    }


    return { top: _y, left: _x };
  }

  getRamdon(legth: number) {
    return Math.random().toString(36).substring(legth);
  }

  getBLCargosUniques(list){
    let that = this;

    return this.getUniqueObjet(
      list.reduce(function(filtered, option) {

       

        if(filtered.length!==0){

          const exist = filtered.findIndex(x=>x.name.toLowerCase().replace(/\s+/g, '')===option.cargo.toLowerCase().replace(/\s+/g, ''))

          if(option.accion!==3){

            if ((exist ===-1)) {

              filtered.push({name:option.cargo.replace(/'\s+'/g,' '), qty:Number(option.qty),Bls: that.isEmptyOrNull(option.bl)?[]: [option.bl]});
            }else{
              if(!that.isEmptyOrNull(option.bl)){

                filtered[exist].Bls.push(option.bl)
                filtered[exist].qty +=Number(option.qty)
              }
            }
          }

        }else{

          if(option.accion!==3){

            filtered.push({name:option.cargo.replace(/'\s+'/g,' '),qty:Number(option.qty),Bls:that.isEmptyOrNull(option.bl)?[]: [option.bl]});
          }
        }

        return filtered;
      }, [])
    );
  }

  urltoBase64(url: string){
    return new Promise((resolve, _) => {
      this.http.get(url, { responseType: 'blob' }).subscribe((x)=>{
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.readAsDataURL(x);
      })
    });
  }

  getUrltoBlob(url: string): Observable<any> {

     
    
    return this.http
      .get(url, { responseType: 'blob' })
      .pipe(
        map(x => {
          const urlToBlob = window.URL.createObjectURL(x) // get a URL for the blob
          
          return this.sanitizer.bypassSecurityTrustResourceUrl(urlToBlob); // tell Anuglar to trust this value
        }),
      );
  }

  // mergePDF(list:any){

  //   return new Promise(async (resolve, _) => {
  //     let merger = new PDFMerger(); 

  //   for(const file of list) {
  //     await merger.add(file)
  //   }

  //   const mergedPdf = await merger.saveAsBuffer();
  //   const blob = new Blob([mergedPdf]); // JavaScript Blob
  //   let pdf:any="";
  //   var reader = new window.FileReader();
  //   reader.readAsDataURL(blob);

  //   reader.onloadend = function () {
  //       pdf = reader.result;
  //       resolve(pdf);
  //   }

  //   });

  // }

  mergePDF2(fda,list:any){

    return new Promise(async (resolve, _) => {
      const pdfDoc = await PDFDocument.create();

      const secondPdfBytes = await fetch(fda).then(res => res.arrayBuffer());

      const secondDoc = await PDFDocument.load(secondPdfBytes);

      const secondPage = await pdfDoc.copyPages(secondDoc, secondDoc.getPageIndices());

      secondPage.forEach((page) => pdfDoc.addPage(page));


      for(let [i,file] of list.entries()) {
       
        const secondPdfBytes = await fetch(file).then(res => res.arrayBuffer());

        const secondDoc = await PDFDocument.load(secondPdfBytes);

        const secondPage = await pdfDoc.copyPages(secondDoc, secondDoc.getPageIndices());

        secondPage.forEach((page) => pdfDoc.addPage(page));
       
      }

      const pdfBytes = await pdfDoc.save();

      let file = new Blob([pdfBytes], { type: 'application/pdf'});

      resolve(URL.createObjectURL(file));

      });

  }

  blobToBase64(blob) {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  }

  msgFormularioIncompleto() {
    return Swal.fire({
      icon: 'error',
      title: 'Action Required!',
      text: 'Incomplete Form - Some required fields are missing. Please check',
      confirmButtonText: 'accept',
    }).then((result) => {

    });
  }

  checkMsgError(err: any, fuctionn?: any,titulo?:any) {
    // const mensaje =
    //   err.status === 0
    //     ? 'Problems of red'
    //     : err.error.Message
    //     ? err.error.Message
    //     : err.error;
    let mensaje: any;
    if (typeof err === 'string') {
      mensaje = err;
    } else {
      mensaje = err.error ? err.error : 'Problems of red';
    }
    return Swal.fire({
      icon: 'error',
      title: titulo || 'Actions Required',
      text: mensaje,
      confirmButtonText: 'accept',
    }).then((result) => {
      if (fuctionn) {
        fuctionn();
      }
    });
  }

  getUniqueObjet(a:any){
    let objString:any=[];
    let objArray :any;
    a.forEach((xx:any) => {
      objString.push(JSON.stringify(xx))
    });

    let InfoUnica= [...new Set(objString)] //obtenemos sin repetir
      objArray = InfoUnica.map((el:any) => JSON.parse(el));
    

    return objArray;
  }



  ordenarNewComent(array) {
    return array.sort((a, b) => {
      if (a.newComentario && !b.newComentario) {
        return -1;
      }

      if (!a.newComentario && b.newComentario) {
        return 1;
      }

      return 0;
    });
  }

  ordenarArray(array, nombrePropiedad = '') {
    if (nombrePropiedad !== '') {
      return array.sort((a, b) => {
        if (a[nombrePropiedad] && b[nombrePropiedad]) {
          const regex = /^([a-z]*)(\d*)/i;

          const _a = a[nombrePropiedad].match(regex);
          const _b = b[nombrePropiedad].match(regex);
          // if the alphabetic part of a is less than that of b => -1
          if (_a[1] < _b[1]) {
            return -1;
          }
          // if the alphabetic part of a is greater than that of b => 1
          if (_a[1] > _b[1]) {
            return 1;
          }

          // if the alphabetic parts are equal, check the number parts
          // tslint:disable-next-line: radix
          const _n = parseInt(_a[2]) - parseInt(_b[2]);

          if (_n === 0) {
            // if the number parts are equal start a recursive test on the rest
            // return this.ordenarCuentas(
            //   a.substr(_a[0].length),
            //   b.substr(_b[0].length)
            // );
          }
          // else, just sort using the numbers parts
          return _n;
        } else {
          return 1;
        }
      });
    } else {
      return array.sort((a, b) => {
        if (a > b) {
          return 1;
        }
        if (a < b) {
          return -1;
        }
        // a must be equal to b
        return 0;
      });
    }
  }

  arraymove(arr, fromIndex, toIndex) {
    var element = arr[fromIndex];
    arr.splice(fromIndex, 1);
    arr.splice(toIndex, 0, element);
}


  validateDate(control: FormControl): { [s: string]: boolean } {
    if (control.value !== '') {
      const a = control.value;
      const format = new RegExp(
        '(0[1-9]|1[0-2])/(0[1-9]|[12]\\d|3[01])/([12]\\d{3})',
        'g'
      );
      const bb = [...a.matchAll(format)];
      if (bb.length > 0) {
        const b = bb[0];
        const anio = Number(b[3]);
        const dia = Number(b[2]);
        const mes = Number(b[1]);

        if (dia === 31 && (mes === 4 || mes === 6 || mes === 9 || mes === 11)) {
          return {
            invalid: true,
          }; // 31st of a month with 30 days
        } else if (dia >= 30 && mes === 2) {
          return {
            invalid: true,
          }; // February 30th or 31st
        } else if (
          mes === 2 &&
          dia === 29 &&
          !(anio % 4 === 0 && (anio % 100 !== 0 || anio % 400 === 0))
        ) {
          return {
            invalid: true,
          }; // February 29th outside a leap year
        } else {
          return null; // Valid date
        }
      } else {
        return {
          invalid: true,
        };
      }
    } else {
      return {
        required: true,
      };
    }
  }

  validateDateNoLesser(control: FormControl):{ [s: string]: boolean }{

    const today= new Date;

    const date = new Date(control.value);
    
    date.setDate(date.getDate() + 32);
    if(today > date){
      return {
        noLesser: true,
      };
    }else{
      return null; 
    }
    
  }

  validarEmail(control: FormControl): { [s: string]: boolean } {
   
      let invalid = false;
      let patternEmail = '^[^@]+@[^@]+.[a-zA-Z]{2,}$';

      if(control.value){
        for (const iterator of control.value) {
          if (!RegExp(patternEmail).test(iterator)) {
            invalid = true;
          }
        }


        if (invalid) {
          return {
            pattern: true,
          };
        } else {
          return null; 
        }
      }
    
  }

  // async getOfTableArqueo(url: string, categoria: string, valor: number) {
  //   let a = {

  //   };
  //   await this.xlsToJson(url)
  //     .then((data: any) => {
  //       // resolve(data);

  //       for (const [i, element] of data.entries()) {
  //         if (
  //           (valor >= Number(element[0]) && valor <= Number(element[1])) ||
  //           i === data.length - 1
  //         ) {
  //           switch (categoria) {
  //             case 'minima':
  //               a = element[2].toString().trim() + element[5].toString().trim();
  //               break;
  //             case 'media':
  //               a = element[3].trim() + element[5].trim();
  //               break;
  //             case 'maxima':
  //               a = element[4].trim() + element[5].trim();
  //               break;
  //           }
  //           break;
  //         }
  //       }
  //     })
  //     .catch((error) => {

  //     });

  //   return a;
  // }






constructor(private http: HttpClient,private sanitizer: DomSanitizer=null) {

  if (sessionStorage.getItem("api")) {
    this.dominioApi = sessionStorage.getItem("api");
  }else{
    sessionStorage.setItem('api',this.dominioApi);
  }

  
  if(['josue','Ale-Daspot'].includes(sessionStorage.getItem('user')?.toLocaleLowerCase())){
                
    this.superAdmin=true;
  }else{
    this.superAdmin=false;
  }

 }
}
